@charset "UTF-8";
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
.ml-color--red-50 {
  background-color: #FFEBEE;
  color: #666; }

.ml-color-text--red-50 {
  color: #FFEBEE; }

.ml-color-border--red-50 {
  border-color: #FFEBEE; }

.ml-color--red-100 {
  background-color: #FFCDD2;
  color: #666; }

.ml-color-text--red-100 {
  color: #FFCDD2; }

.ml-color-border--red-100 {
  border-color: #FFCDD2; }

.ml-color--red-200 {
  background-color: #EF9A9A;
  color: #666; }

.ml-color-text--red-200 {
  color: #EF9A9A; }

.ml-color-border--red-200 {
  border-color: #EF9A9A; }

.ml-color--red-300 {
  background-color: #E57373;
  color: #ffffff; }

.ml-color-text--red-300 {
  color: #E57373; }

.ml-color-border--red-300 {
  border-color: #E57373; }

.ml-color--red-400 {
  background-color: #EF5350;
  color: #ffffff; }

.ml-color-text--red-400 {
  color: #EF5350; }

.ml-color-border--red-400 {
  border-color: #EF5350; }

.ml-color--red {
  background-color: #F44336;
  color: #ffffff; }

.ml-color-text--red {
  color: #F44336; }

.ml-color-border--red {
  border-color: #F44336; }

.ml-color--red-600 {
  background-color: #E53935;
  color: #ffffff; }

.ml-color-text--red-600 {
  color: #E53935; }

.ml-color-border--red-600 {
  border-color: #E53935; }

.ml-color--red-700 {
  background-color: #D32F2F;
  color: #ffffff; }

.ml-color-text--red-700 {
  color: #D32F2F; }

.ml-color-border--red-700 {
  border-color: #D32F2F; }

.ml-color--red-800 {
  background-color: #C62828;
  color: #ffffff; }

.ml-color-text--red-800 {
  color: #C62828; }

.ml-color-border--red-800 {
  border-color: #C62828; }

.ml-color--red-900 {
  background-color: #B71C1C;
  color: #ffffff; }

.ml-color-text--red-900 {
  color: #B71C1C; }

.ml-color-border--red-900 {
  border-color: #B71C1C; }

.ml-color--red-A100 {
  background-color: #FF8A80;
  color: #ffffff; }

.ml-color-text--red-A100 {
  color: #FF8A80; }

.ml-color-border--red-A100 {
  border-color: #FF8A80; }

.ml-color--red-A200 {
  background-color: #FF5252;
  color: #ffffff; }

.ml-color-text--red-A200 {
  color: #FF5252; }

.ml-color-border--red-A200 {
  border-color: #FF5252; }

.ml-color--red-A300 {
  background-color: #FF1744;
  color: #ffffff; }

.ml-color-text--red-A300 {
  color: #FF1744; }

.ml-color-border--red-A300 {
  border-color: #FF1744; }

.ml-color--red-A400 {
  background-color: #D50000;
  color: #ffffff; }

.ml-color-text--red-A400 {
  color: #D50000; }

.ml-color-border--red-A400 {
  border-color: #D50000; }

.ml-color--pink-50 {
  background-color: #fce4ec;
  color: #666; }

.ml-color-text--pink-50 {
  color: #fce4ec; }

.ml-color-border--pink-50 {
  border-color: #fce4ec; }

.ml-color--pink-100 {
  background-color: #f8bbd0;
  color: #666; }

.ml-color-text--pink-100 {
  color: #f8bbd0; }

.ml-color-border--pink-100 {
  border-color: #f8bbd0; }

.ml-color--pink-200 {
  background-color: #f48fb1;
  color: #ffffff; }

.ml-color-text--pink-200 {
  color: #f48fb1; }

.ml-color-border--pink-200 {
  border-color: #f48fb1; }

.ml-color--pink-300 {
  background-color: #f06292;
  color: #ffffff; }

.ml-color-text--pink-300 {
  color: #f06292; }

.ml-color-border--pink-300 {
  border-color: #f06292; }

.ml-color--pink-400 {
  background-color: #ec407a;
  color: #ffffff; }

.ml-color-text--pink-400 {
  color: #ec407a; }

.ml-color-border--pink-400 {
  border-color: #ec407a; }

.ml-color--pink {
  background-color: #e91e63;
  color: #ffffff; }

.ml-color-text--pink {
  color: #e91e63; }

.ml-color-border--pink {
  border-color: #e91e63; }

.ml-color--pink-600 {
  background-color: #d81b60;
  color: #ffffff; }

.ml-color-text--pink-600 {
  color: #d81b60; }

.ml-color-border--pink-600 {
  border-color: #d81b60; }

.ml-color--pink-700 {
  background-color: #c2185b;
  color: #ffffff; }

.ml-color-text--pink-700 {
  color: #c2185b; }

.ml-color-border--pink-700 {
  border-color: #c2185b; }

.ml-color--pink-800 {
  background-color: #ad1457;
  color: #ffffff; }

.ml-color-text--pink-800 {
  color: #ad1457; }

.ml-color-border--pink-800 {
  border-color: #ad1457; }

.ml-color--pink-900 {
  background-color: #880e4f;
  color: #ffffff; }

.ml-color-text--pink-900 {
  color: #880e4f; }

.ml-color-border--pink-900 {
  border-color: #880e4f; }

.ml-color--pink-A100 {
  background-color: #ff80ab;
  color: #ffffff; }

.ml-color-text--pink-A100 {
  color: #ff80ab; }

.ml-color-border--pink-A100 {
  border-color: #ff80ab; }

.ml-color--pink-A200 {
  background-color: #ff4081;
  color: #ffffff; }

.ml-color-text--pink-A200 {
  color: #ff4081; }

.ml-color-border--pink-A200 {
  border-color: #ff4081; }

.ml-color--pink-A300 {
  background-color: #f50057;
  color: #ffffff; }

.ml-color-text--pink-A300 {
  color: #f50057; }

.ml-color-border--pink-A300 {
  border-color: #f50057; }

.ml-color--pink-A400 {
  background-color: #c51162;
  color: #ffffff; }

.ml-color-text--pink-A400 {
  color: #c51162; }

.ml-color-border--pink-A400 {
  border-color: #c51162; }

.ml-color--purple-50 {
  background-color: #f3e5f5;
  color: #666; }

.ml-color-text--purple-50 {
  color: #f3e5f5; }

.ml-color-border--purple-50 {
  border-color: #f3e5f5; }

.ml-color--purple-100 {
  background-color: #e1bee7;
  color: #666; }

.ml-color-text--purple-100 {
  color: #e1bee7; }

.ml-color-border--purple-100 {
  border-color: #e1bee7; }

.ml-color--purple-200 {
  background-color: #ce93d8;
  color: #ffffff; }

.ml-color-text--purple-200 {
  color: #ce93d8; }

.ml-color-border--purple-200 {
  border-color: #ce93d8; }

.ml-color--purple-300 {
  background-color: #ba68c8;
  color: #ffffff; }

.ml-color-text--purple-300 {
  color: #ba68c8; }

.ml-color-border--purple-300 {
  border-color: #ba68c8; }

.ml-color--purple-400 {
  background-color: #ab47bc;
  color: #ffffff; }

.ml-color-text--purple-400 {
  color: #ab47bc; }

.ml-color-border--purple-400 {
  border-color: #ab47bc; }

.ml-color--purple {
  background-color: #9c27b0;
  color: #ffffff; }

.ml-color-text--purple {
  color: #9c27b0; }

.ml-color-border--purple {
  border-color: #9c27b0; }

.ml-color--purple-600 {
  background-color: #8e24aa;
  color: #ffffff; }

.ml-color-text--purple-600 {
  color: #8e24aa; }

.ml-color-border--purple-600 {
  border-color: #8e24aa; }

.ml-color--purple-700 {
  background-color: #7b1fa2;
  color: #ffffff; }

.ml-color-text--purple-700 {
  color: #7b1fa2; }

.ml-color-border--purple-700 {
  border-color: #7b1fa2; }

.ml-color--purple-800 {
  background-color: #6a1b9a;
  color: #ffffff; }

.ml-color-text--purple-800 {
  color: #6a1b9a; }

.ml-color-border--purple-800 {
  border-color: #6a1b9a; }

.ml-color--purple-900 {
  background-color: #4a148c;
  color: #ffffff; }

.ml-color-text--purple-900 {
  color: #4a148c; }

.ml-color-border--purple-900 {
  border-color: #4a148c; }

.ml-color--purple-A100 {
  background-color: #ea80fc;
  color: #ffffff; }

.ml-color-text--purple-A100 {
  color: #ea80fc; }

.ml-color-border--purple-A100 {
  border-color: #ea80fc; }

.ml-color--purple-A200 {
  background-color: #e040fb;
  color: #ffffff; }

.ml-color-text--purple-A200 {
  color: #e040fb; }

.ml-color-border--purple-A200 {
  border-color: #e040fb; }

.ml-color--purple-A300 {
  background-color: #d500f9;
  color: #ffffff; }

.ml-color-text--purple-A300 {
  color: #d500f9; }

.ml-color-border--purple-A300 {
  border-color: #d500f9; }

.ml-color--purple-A400 {
  background-color: #aa00ff;
  color: #ffffff; }

.ml-color-text--purple-A400 {
  color: #aa00ff; }

.ml-color-border--purple-A400 {
  border-color: #aa00ff; }

.ml-color--deep-purple-50 {
  background-color: #ede7f6;
  color: #666; }

.ml-color-text--deep-purple-50 {
  color: #ede7f6; }

.ml-color-border--deep-purple-50 {
  border-color: #ede7f6; }

.ml-color--deep-purple-100 {
  background-color: #d1c4e9;
  color: #666; }

.ml-color-text--deep-purple-100 {
  color: #d1c4e9; }

.ml-color-border--deep-purple-100 {
  border-color: #d1c4e9; }

.ml-color--deep-purple-200 {
  background-color: #b39ddb;
  color: #ffffff; }

.ml-color-text--deep-purple-200 {
  color: #b39ddb; }

.ml-color-border--deep-purple-200 {
  border-color: #b39ddb; }

.ml-color--deep-purple-300 {
  background-color: #9575cd;
  color: #ffffff; }

.ml-color-text--deep-purple-300 {
  color: #9575cd; }

.ml-color-border--deep-purple-300 {
  border-color: #9575cd; }

.ml-color--deep-purple-400 {
  background-color: #7e57c2;
  color: #ffffff; }

.ml-color-text--deep-purple-400 {
  color: #7e57c2; }

.ml-color-border--deep-purple-400 {
  border-color: #7e57c2; }

.ml-color--deep-purple {
  background-color: #673ab7;
  color: #ffffff; }

.ml-color-text--deep-purple {
  color: #673ab7; }

.ml-color-border--deep-purple {
  border-color: #673ab7; }

.ml-color--deep-purple-600 {
  background-color: #5e35b1;
  color: #ffffff; }

.ml-color-text--deep-purple-600 {
  color: #5e35b1; }

.ml-color-border--deep-purple-600 {
  border-color: #5e35b1; }

.ml-color--deep-purple-700 {
  background-color: #512da8;
  color: #ffffff; }

.ml-color-text--deep-purple-700 {
  color: #512da8; }

.ml-color-border--deep-purple-700 {
  border-color: #512da8; }

.ml-color--deep-purple-800 {
  background-color: #4527a0;
  color: #ffffff; }

.ml-color-text--deep-purple-800 {
  color: #4527a0; }

.ml-color-border--deep-purple-800 {
  border-color: #4527a0; }

.ml-color--deep-purple-900 {
  background-color: #311b92;
  color: #ffffff; }

.ml-color-text--deep-purple-900 {
  color: #311b92; }

.ml-color-border--deep-purple-900 {
  border-color: #311b92; }

.ml-color--deep-purple-A100 {
  background-color: #b388ff;
  color: #ffffff; }

.ml-color-text--deep-purple-A100 {
  color: #b388ff; }

.ml-color-border--deep-purple-A100 {
  border-color: #b388ff; }

.ml-color--deep-purple-A200 {
  background-color: #7c4dff;
  color: #ffffff; }

.ml-color-text--deep-purple-A200 {
  color: #7c4dff; }

.ml-color-border--deep-purple-A200 {
  border-color: #7c4dff; }

.ml-color--deep-purple-A300 {
  background-color: #651fff;
  color: #ffffff; }

.ml-color-text--deep-purple-A300 {
  color: #651fff; }

.ml-color-border--deep-purple-A300 {
  border-color: #651fff; }

.ml-color--deep-purple-A400 {
  background-color: #6200ea;
  color: #ffffff; }

.ml-color-text--deep-purple-A400 {
  color: #6200ea; }

.ml-color-border--deep-purple-A400 {
  border-color: #6200ea; }

.ml-color--indigo-50 {
  background-color: #e8eaf6;
  color: #666; }

.ml-color-text--indigo-50 {
  color: #e8eaf6; }

.ml-color-border--indigo-50 {
  border-color: #e8eaf6; }

.ml-color--indigo-100 {
  background-color: #c5cae9;
  color: #666; }

.ml-color-text--indigo-100 {
  color: #c5cae9; }

.ml-color-border--indigo-100 {
  border-color: #c5cae9; }

.ml-color--indigo-200 {
  background-color: #9fa8da;
  color: #ffffff; }

.ml-color-text--indigo-200 {
  color: #9fa8da; }

.ml-color-border--indigo-200 {
  border-color: #9fa8da; }

.ml-color--indigo-300 {
  background-color: #7986cb;
  color: #ffffff; }

.ml-color-text--indigo-300 {
  color: #7986cb; }

.ml-color-border--indigo-300 {
  border-color: #7986cb; }

.ml-color--indigo-400 {
  background-color: #5c6bc0;
  color: #ffffff; }

.ml-color-text--indigo-400 {
  color: #5c6bc0; }

.ml-color-border--indigo-400 {
  border-color: #5c6bc0; }

.ml-color--indigo {
  background-color: #3f51b5;
  color: #ffffff; }

.ml-color-text--indigo {
  color: #3f51b5; }

.ml-color-border--indigo {
  border-color: #3f51b5; }

.ml-color--indigo-600 {
  background-color: #3949ab;
  color: #ffffff; }

.ml-color-text--indigo-600 {
  color: #3949ab; }

.ml-color-border--indigo-600 {
  border-color: #3949ab; }

.ml-color--indigo-700 {
  background-color: #303f9f;
  color: #ffffff; }

.ml-color-text--indigo-700 {
  color: #303f9f; }

.ml-color-border--indigo-700 {
  border-color: #303f9f; }

.ml-color--indigo-800 {
  background-color: #283593;
  color: #ffffff; }

.ml-color-text--indigo-800 {
  color: #283593; }

.ml-color-border--indigo-800 {
  border-color: #283593; }

.ml-color--indigo-900 {
  background-color: #1a237e;
  color: #ffffff; }

.ml-color-text--indigo-900 {
  color: #1a237e; }

.ml-color-border--indigo-900 {
  border-color: #1a237e; }

.ml-color--indigo-A100 {
  background-color: #8c9eff;
  color: #666; }

.ml-color-text--indigo-A100 {
  color: #8c9eff; }

.ml-color-border--indigo-A100 {
  border-color: #8c9eff; }

.ml-color--indigo-A200 {
  background-color: #536dfe;
  color: #ffffff; }

.ml-color-text--indigo-A200 {
  color: #536dfe; }

.ml-color-border--indigo-A200 {
  border-color: #536dfe; }

.ml-color--indigo-A300 {
  background-color: #3d5afe;
  color: #ffffff; }

.ml-color-text--indigo-A300 {
  color: #3d5afe; }

.ml-color-border--indigo-A300 {
  border-color: #3d5afe; }

.ml-color--indigo-A400 {
  background-color: #304ffe;
  color: #ffffff; }

.ml-color-text--indigo-A400 {
  color: #304ffe; }

.ml-color-border--indigo-A400 {
  border-color: #304ffe; }

.ml-color--blue-50 {
  background-color: #E3F2FD;
  color: #666; }

.ml-color-text--blue-50 {
  color: #E3F2FD; }

.ml-color-border--blue-50 {
  border-color: #E3F2FD; }

.ml-color--blue-100 {
  background-color: #BBDEFB;
  color: #666; }

.ml-color-text--blue-100 {
  color: #BBDEFB; }

.ml-color-border--blue-100 {
  border-color: #BBDEFB; }

.ml-color--blue-200 {
  background-color: #90CAF9;
  color: #666; }

.ml-color-text--blue-200 {
  color: #90CAF9; }

.ml-color-border--blue-200 {
  border-color: #90CAF9; }

.ml-color--blue-300 {
  background-color: #64B5F6;
  color: #ffffff; }

.ml-color-text--blue-300 {
  color: #64B5F6; }

.ml-color-border--blue-300 {
  border-color: #64B5F6; }

.ml-color--blue-400 {
  background-color: #42A5F5;
  color: #ffffff; }

.ml-color-text--blue-400 {
  color: #42A5F5; }

.ml-color-border--blue-400 {
  border-color: #42A5F5; }

.ml-color--blue {
  background-color: #2196F3;
  color: #ffffff; }

.ml-color-text--blue {
  color: #2196F3; }

.ml-color-border--blue {
  border-color: #2196F3; }

.ml-color--blue-600 {
  background-color: #1E88E5;
  color: #ffffff; }

.ml-color-text--blue-600 {
  color: #1E88E5; }

.ml-color-border--blue-600 {
  border-color: #1E88E5; }

.ml-color--blue-700 {
  background-color: #1976D2;
  color: #ffffff; }

.ml-color-text--blue-700 {
  color: #1976D2; }

.ml-color-border--blue-700 {
  border-color: #1976D2; }

.ml-color--blue-800 {
  background-color: #1565C0;
  color: #ffffff; }

.ml-color-text--blue-800 {
  color: #1565C0; }

.ml-color-border--blue-800 {
  border-color: #1565C0; }

.ml-color--blue-900 {
  background-color: #0D47A1;
  color: #ffffff; }

.ml-color-text--blue-900 {
  color: #0D47A1; }

.ml-color-border--blue-900 {
  border-color: #0D47A1; }

.ml-color--blue-A100 {
  background-color: #82B1FF;
  color: #ffffff; }

.ml-color-text--blue-A100 {
  color: #82B1FF; }

.ml-color-border--blue-A100 {
  border-color: #82B1FF; }

.ml-color--blue-A200 {
  background-color: #448AFF;
  color: #ffffff; }

.ml-color-text--blue-A200 {
  color: #448AFF; }

.ml-color-border--blue-A200 {
  border-color: #448AFF; }

.ml-color--blue-A300 {
  background-color: #2979FF;
  color: #ffffff; }

.ml-color-text--blue-A300 {
  color: #2979FF; }

.ml-color-border--blue-A300 {
  border-color: #2979FF; }

.ml-color--blue-A400 {
  background-color: #2962FF;
  color: #ffffff; }

.ml-color-text--blue-A400 {
  color: #2962FF; }

.ml-color-border--blue-A400 {
  border-color: #2962FF; }

.ml-color--light-blue-50 {
  background-color: #e1f5fe;
  color: #666; }

.ml-color-text--light-blue-50 {
  color: #e1f5fe; }

.ml-color-border--light-blue-50 {
  border-color: #e1f5fe; }

.ml-color--light-blue-100 {
  background-color: #b3e5fc;
  color: #666; }

.ml-color-text--light-blue-100 {
  color: #b3e5fc; }

.ml-color-border--light-blue-100 {
  border-color: #b3e5fc; }

.ml-color--light-blue-200 {
  background-color: #81d4fa;
  color: #ffffff; }

.ml-color-text--light-blue-200 {
  color: #81d4fa; }

.ml-color-border--light-blue-200 {
  border-color: #81d4fa; }

.ml-color--light-blue-300 {
  background-color: #4fc3f7;
  color: #ffffff; }

.ml-color-text--light-blue-300 {
  color: #4fc3f7; }

.ml-color-border--light-blue-300 {
  border-color: #4fc3f7; }

.ml-color--light-blue-400 {
  background-color: #29b6f6;
  color: #ffffff; }

.ml-color-text--light-blue-400 {
  color: #29b6f6; }

.ml-color-border--light-blue-400 {
  border-color: #29b6f6; }

.ml-color--light-blue {
  background-color: #03a9f4;
  color: #ffffff; }

.ml-color-text--light-blue {
  color: #03a9f4; }

.ml-color-border--light-blue {
  border-color: #03a9f4; }

.ml-color--light-blue-600 {
  background-color: #039be5;
  color: #ffffff; }

.ml-color-text--light-blue-600 {
  color: #039be5; }

.ml-color-border--light-blue-600 {
  border-color: #039be5; }

.ml-color--light-blue-700 {
  background-color: #0288d1;
  color: #ffffff; }

.ml-color-text--light-blue-700 {
  color: #0288d1; }

.ml-color-border--light-blue-700 {
  border-color: #0288d1; }

.ml-color--light-blue-800 {
  background-color: #0277bd;
  color: #ffffff; }

.ml-color-text--light-blue-800 {
  color: #0277bd; }

.ml-color-border--light-blue-800 {
  border-color: #0277bd; }

.ml-color--light-blue-900 {
  background-color: #01579b;
  color: #ffffff; }

.ml-color-text--light-blue-900 {
  color: #01579b; }

.ml-color-border--light-blue-900 {
  border-color: #01579b; }

.ml-color--light-blue-A100 {
  background-color: #80d8ff;
  color: #ffffff; }

.ml-color-text--light-blue-A100 {
  color: #80d8ff; }

.ml-color-border--light-blue-A100 {
  border-color: #80d8ff; }

.ml-color--light-blue-A200 {
  background-color: #40c4ff;
  color: #ffffff; }

.ml-color-text--light-blue-A200 {
  color: #40c4ff; }

.ml-color-border--light-blue-A200 {
  border-color: #40c4ff; }

.ml-color--light-blue-A300 {
  background-color: #00b0ff;
  color: #ffffff; }

.ml-color-text--light-blue-A300 {
  color: #00b0ff; }

.ml-color-border--light-blue-A300 {
  border-color: #00b0ff; }

.ml-color--light-blue-A400 {
  background-color: #0091ea;
  color: #ffffff; }

.ml-color-text--light-blue-A400 {
  color: #0091ea; }

.ml-color-border--light-blue-A400 {
  border-color: #0091ea; }

.ml-color--cyan-50 {
  background-color: #e0f7fa;
  color: #666; }

.ml-color-text--cyan-50 {
  color: #e0f7fa; }

.ml-color-border--cyan-50 {
  border-color: #e0f7fa; }

.ml-color--cyan-100 {
  background-color: #b2ebf2;
  color: #666; }

.ml-color-text--cyan-100 {
  color: #b2ebf2; }

.ml-color-border--cyan-100 {
  border-color: #b2ebf2; }

.ml-color--cyan-200 {
  background-color: #80deea;
  color: #ffffff; }

.ml-color-text--cyan-200 {
  color: #80deea; }

.ml-color-border--cyan-200 {
  border-color: #80deea; }

.ml-color--cyan-300 {
  background-color: #4dd0e1;
  color: #ffffff; }

.ml-color-text--cyan-300 {
  color: #4dd0e1; }

.ml-color-border--cyan-300 {
  border-color: #4dd0e1; }

.ml-color--cyan-400 {
  background-color: #26c6da;
  color: #ffffff; }

.ml-color-text--cyan-400 {
  color: #26c6da; }

.ml-color-border--cyan-400 {
  border-color: #26c6da; }

.ml-color--cyan {
  background-color: #00bcd4;
  color: #ffffff; }

.ml-color-text--cyan {
  color: #00bcd4; }

.ml-color-border--cyan {
  border-color: #00bcd4; }

.ml-color--cyan-600 {
  background-color: #00acc1;
  color: #ffffff; }

.ml-color-text--cyan-600 {
  color: #00acc1; }

.ml-color-border--cyan-600 {
  border-color: #00acc1; }

.ml-color--cyan-700 {
  background-color: #0097a7;
  color: #ffffff; }

.ml-color-text--cyan-700 {
  color: #0097a7; }

.ml-color-border--cyan-700 {
  border-color: #0097a7; }

.ml-color--cyan-800 {
  background-color: #00838f;
  color: #ffffff; }

.ml-color-text--cyan-800 {
  color: #00838f; }

.ml-color-border--cyan-800 {
  border-color: #00838f; }

.ml-color--cyan-900 {
  background-color: #006064;
  color: #ffffff; }

.ml-color-text--cyan-900 {
  color: #006064; }

.ml-color-border--cyan-900 {
  border-color: #006064; }

.ml-color--cyan-A100 {
  background-color: #84ffff;
  color: #ffffff; }

.ml-color-text--cyan-A100 {
  color: #84ffff; }

.ml-color-border--cyan-A100 {
  border-color: #84ffff; }

.ml-color--cyan-A200 {
  background-color: #18ffff;
  color: #ffffff; }

.ml-color-text--cyan-A200 {
  color: #18ffff; }

.ml-color-border--cyan-A200 {
  border-color: #18ffff; }

.ml-color--cyan-A300 {
  background-color: #00e5ff;
  color: #ffffff; }

.ml-color-text--cyan-A300 {
  color: #00e5ff; }

.ml-color-border--cyan-A300 {
  border-color: #00e5ff; }

.ml-color--cyan-A400 {
  background-color: #00b8d4;
  color: #ffffff; }

.ml-color-text--cyan-A400 {
  color: #00b8d4; }

.ml-color-border--cyan-A400 {
  border-color: #00b8d4; }

.ml-color--teal-50 {
  background-color: #e0f2f1;
  color: #666; }

.ml-color-text--teal-50 {
  color: #e0f2f1; }

.ml-color-border--teal-50 {
  border-color: #e0f2f1; }

.ml-color--teal-100 {
  background-color: #b2dfdb;
  color: #666; }

.ml-color-text--teal-100 {
  color: #b2dfdb; }

.ml-color-border--teal-100 {
  border-color: #b2dfdb; }

.ml-color--teal-200 {
  background-color: #80cbc4;
  color: #ffffff; }

.ml-color-text--teal-200 {
  color: #80cbc4; }

.ml-color-border--teal-200 {
  border-color: #80cbc4; }

.ml-color--teal-300 {
  background-color: #4db6ac;
  color: #ffffff; }

.ml-color-text--teal-300 {
  color: #4db6ac; }

.ml-color-border--teal-300 {
  border-color: #4db6ac; }

.ml-color--teal-400 {
  background-color: #26a69a;
  color: #ffffff; }

.ml-color-text--teal-400 {
  color: #26a69a; }

.ml-color-border--teal-400 {
  border-color: #26a69a; }

.ml-color--teal {
  background-color: #009688;
  color: #ffffff; }

.ml-color-text--teal {
  color: #009688; }

.ml-color-border--teal {
  border-color: #009688; }

.ml-color--teal-600 {
  background-color: #00897b;
  color: #ffffff; }

.ml-color-text--teal-600 {
  color: #00897b; }

.ml-color-border--teal-600 {
  border-color: #00897b; }

.ml-color--teal-700 {
  background-color: #00796b;
  color: #ffffff; }

.ml-color-text--teal-700 {
  color: #00796b; }

.ml-color-border--teal-700 {
  border-color: #00796b; }

.ml-color--teal-800 {
  background-color: #00695c;
  color: #ffffff; }

.ml-color-text--teal-800 {
  color: #00695c; }

.ml-color-border--teal-800 {
  border-color: #00695c; }

.ml-color--teal-900 {
  background-color: #004d40;
  color: #ffffff; }

.ml-color-text--teal-900 {
  color: #004d40; }

.ml-color-border--teal-900 {
  border-color: #004d40; }

.ml-color--teal-A100 {
  background-color: #a7ffeb;
  color: #666; }

.ml-color-text--teal-A100 {
  color: #a7ffeb; }

.ml-color-border--teal-A100 {
  border-color: #a7ffeb; }

.ml-color--teal-A200 {
  background-color: #64ffda;
  color: #ffffff; }

.ml-color-text--teal-A200 {
  color: #64ffda; }

.ml-color-border--teal-A200 {
  border-color: #64ffda; }

.ml-color--teal-A300 {
  background-color: #1de9b6;
  color: #ffffff; }

.ml-color-text--teal-A300 {
  color: #1de9b6; }

.ml-color-border--teal-A300 {
  border-color: #1de9b6; }

.ml-color--teal-A400 {
  background-color: #00bfa5;
  color: #ffffff; }

.ml-color-text--teal-A400 {
  color: #00bfa5; }

.ml-color-border--teal-A400 {
  border-color: #00bfa5; }

.ml-color--green-50 {
  background-color: #E8F5E9;
  color: #666; }

.ml-color-text--green-50 {
  color: #E8F5E9; }

.ml-color-border--green-50 {
  border-color: #E8F5E9; }

.ml-color--green-100 {
  background-color: #C8E6C9;
  color: #666; }

.ml-color-text--green-100 {
  color: #C8E6C9; }

.ml-color-border--green-100 {
  border-color: #C8E6C9; }

.ml-color--green-200 {
  background-color: #A5D6A7;
  color: #ffffff; }

.ml-color-text--green-200 {
  color: #A5D6A7; }

.ml-color-border--green-200 {
  border-color: #A5D6A7; }

.ml-color--green-300 {
  background-color: #81C784;
  color: #ffffff; }

.ml-color-text--green-300 {
  color: #81C784; }

.ml-color-border--green-300 {
  border-color: #81C784; }

.ml-color--green-400 {
  background-color: #66BB6A;
  color: #ffffff; }

.ml-color-text--green-400 {
  color: #66BB6A; }

.ml-color-border--green-400 {
  border-color: #66BB6A; }

.ml-color--green {
  background-color: #4CAF50;
  color: #ffffff; }

.ml-color-text--green {
  color: #4CAF50; }

.ml-color-border--green {
  border-color: #4CAF50; }

.ml-color--green-600 {
  background-color: #43A047;
  color: #ffffff; }

.ml-color-text--green-600 {
  color: #43A047; }

.ml-color-border--green-600 {
  border-color: #43A047; }

.ml-color--green-700 {
  background-color: #388E3C;
  color: #ffffff; }

.ml-color-text--green-700 {
  color: #388E3C; }

.ml-color-border--green-700 {
  border-color: #388E3C; }

.ml-color--green-800 {
  background-color: #2E7D32;
  color: #ffffff; }

.ml-color-text--green-800 {
  color: #2E7D32; }

.ml-color-border--green-800 {
  border-color: #2E7D32; }

.ml-color--green-900 {
  background-color: #1B5E20;
  color: #ffffff; }

.ml-color-text--green-900 {
  color: #1B5E20; }

.ml-color-border--green-900 {
  border-color: #1B5E20; }

.ml-color--green-A100 {
  background-color: #B9F6CA;
  color: #666; }

.ml-color-text--green-A100 {
  color: #B9F6CA; }

.ml-color-border--green-A100 {
  border-color: #B9F6CA; }

.ml-color--green-A200 {
  background-color: #69F0AE;
  color: #ffffff; }

.ml-color-text--green-A200 {
  color: #69F0AE; }

.ml-color-border--green-A200 {
  border-color: #69F0AE; }

.ml-color--green-A300 {
  background-color: #00E676;
  color: #ffffff; }

.ml-color-text--green-A300 {
  color: #00E676; }

.ml-color-border--green-A300 {
  border-color: #00E676; }

.ml-color--green-A400 {
  background-color: #1ED068;
  color: #ffffff; }

.ml-color-text--green-A400 {
  color: #1ED068; }

.ml-color-border--green-A400 {
  border-color: #1ED068; }

.ml-color--light-green-50 {
  background-color: #f1f8e9;
  color: #666; }

.ml-color-text--light-green-50 {
  color: #f1f8e9; }

.ml-color-border--light-green-50 {
  border-color: #f1f8e9; }

.ml-color--light-green-100 {
  background-color: #dcedc8;
  color: #666; }

.ml-color-text--light-green-100 {
  color: #dcedc8; }

.ml-color-border--light-green-100 {
  border-color: #dcedc8; }

.ml-color--light-green-200 {
  background-color: #c5e1a5;
  color: #ffffff; }

.ml-color-text--light-green-200 {
  color: #c5e1a5; }

.ml-color-border--light-green-200 {
  border-color: #c5e1a5; }

.ml-color--light-green-300 {
  background-color: #aed581;
  color: #ffffff; }

.ml-color-text--light-green-300 {
  color: #aed581; }

.ml-color-border--light-green-300 {
  border-color: #aed581; }

.ml-color--light-green-400 {
  background-color: #9ccc65;
  color: #ffffff; }

.ml-color-text--light-green-400 {
  color: #9ccc65; }

.ml-color-border--light-green-400 {
  border-color: #9ccc65; }

.ml-color--light-green {
  background-color: #8bc34a;
  color: #ffffff; }

.ml-color-text--light-green {
  color: #8bc34a; }

.ml-color-border--light-green {
  border-color: #8bc34a; }

.ml-color--light-green-600 {
  background-color: #7cb342;
  color: #ffffff; }

.ml-color-text--light-green-600 {
  color: #7cb342; }

.ml-color-border--light-green-600 {
  border-color: #7cb342; }

.ml-color--light-green-700 {
  background-color: #689f38;
  color: #ffffff; }

.ml-color-text--light-green-700 {
  color: #689f38; }

.ml-color-border--light-green-700 {
  border-color: #689f38; }

.ml-color--light-green-800 {
  background-color: #558b2f;
  color: #ffffff; }

.ml-color-text--light-green-800 {
  color: #558b2f; }

.ml-color-border--light-green-800 {
  border-color: #558b2f; }

.ml-color--light-green-900 {
  background-color: #33691e;
  color: #ffffff; }

.ml-color-text--light-green-900 {
  color: #33691e; }

.ml-color-border--light-green-900 {
  border-color: #33691e; }

.ml-color--light-green-A100 {
  background-color: #ccff90;
  color: #666; }

.ml-color-text--light-green-A100 {
  color: #ccff90; }

.ml-color-border--light-green-A100 {
  border-color: #ccff90; }

.ml-color--light-green-A200 {
  background-color: #b2ff59;
  color: #ffffff; }

.ml-color-text--light-green-A200 {
  color: #b2ff59; }

.ml-color-border--light-green-A200 {
  border-color: #b2ff59; }

.ml-color--light-green-A300 {
  background-color: #76ff03;
  color: #ffffff; }

.ml-color-text--light-green-A300 {
  color: #76ff03; }

.ml-color-border--light-green-A300 {
  border-color: #76ff03; }

.ml-color--light-green-A400 {
  background-color: #64dd17;
  color: #ffffff; }

.ml-color-text--light-green-A400 {
  color: #64dd17; }

.ml-color-border--light-green-A400 {
  border-color: #64dd17; }

.ml-color--lime-50 {
  background-color: #f9fbe7;
  color: #666; }

.ml-color-text--lime-50 {
  color: #f9fbe7; }

.ml-color-border--lime-50 {
  border-color: #f9fbe7; }

.ml-color--lime-100 {
  background-color: #f0f4c3;
  color: #666; }

.ml-color-text--lime-100 {
  color: #f0f4c3; }

.ml-color-border--lime-100 {
  border-color: #f0f4c3; }

.ml-color--lime-200 {
  background-color: #e6ee9c;
  color: #666; }

.ml-color-text--lime-200 {
  color: #e6ee9c; }

.ml-color-border--lime-200 {
  border-color: #e6ee9c; }

.ml-color--lime-300 {
  background-color: #dce775;
  color: #ffffff; }

.ml-color-text--lime-300 {
  color: #dce775; }

.ml-color-border--lime-300 {
  border-color: #dce775; }

.ml-color--lime-400 {
  background-color: #d4e157;
  color: #ffffff; }

.ml-color-text--lime-400 {
  color: #d4e157; }

.ml-color-border--lime-400 {
  border-color: #d4e157; }

.ml-color--lime {
  background-color: #cddc39;
  color: #ffffff; }

.ml-color-text--lime {
  color: #cddc39; }

.ml-color-border--lime {
  border-color: #cddc39; }

.ml-color--lime-600 {
  background-color: #c0ca33;
  color: #ffffff; }

.ml-color-text--lime-600 {
  color: #c0ca33; }

.ml-color-border--lime-600 {
  border-color: #c0ca33; }

.ml-color--lime-700 {
  background-color: #afb42b;
  color: #ffffff; }

.ml-color-text--lime-700 {
  color: #afb42b; }

.ml-color-border--lime-700 {
  border-color: #afb42b; }

.ml-color--lime-800 {
  background-color: #9e9d24;
  color: #ffffff; }

.ml-color-text--lime-800 {
  color: #9e9d24; }

.ml-color-border--lime-800 {
  border-color: #9e9d24; }

.ml-color--lime-900 {
  background-color: #827717;
  color: #ffffff; }

.ml-color-text--lime-900 {
  color: #827717; }

.ml-color-border--lime-900 {
  border-color: #827717; }

.ml-color--lime-A100 {
  background-color: #f4ff81;
  color: #ffffff; }

.ml-color-text--lime-A100 {
  color: #f4ff81; }

.ml-color-border--lime-A100 {
  border-color: #f4ff81; }

.ml-color--lime-A200 {
  background-color: #eeff41;
  color: #ffffff; }

.ml-color-text--lime-A200 {
  color: #eeff41; }

.ml-color-border--lime-A200 {
  border-color: #eeff41; }

.ml-color--lime-A300 {
  background-color: #c6ff00;
  color: #ffffff; }

.ml-color-text--lime-A300 {
  color: #c6ff00; }

.ml-color-border--lime-A300 {
  border-color: #c6ff00; }

.ml-color--lime-A400 {
  background-color: #aeea00;
  color: #ffffff; }

.ml-color-text--lime-A400 {
  color: #aeea00; }

.ml-color-border--lime-A400 {
  border-color: #aeea00; }

.ml-color--yellow-50 {
  background-color: #fffde7;
  color: #666; }

.ml-color-text--yellow-50 {
  color: #fffde7; }

.ml-color-border--yellow-50 {
  border-color: #fffde7; }

.ml-color--yellow-100 {
  background-color: #fff9c4;
  color: #666; }

.ml-color-text--yellow-100 {
  color: #fff9c4; }

.ml-color-border--yellow-100 {
  border-color: #fff9c4; }

.ml-color--yellow-200 {
  background-color: #fff59d;
  color: #666; }

.ml-color-text--yellow-200 {
  color: #fff59d; }

.ml-color-border--yellow-200 {
  border-color: #fff59d; }

.ml-color--yellow-300 {
  background-color: #fff176;
  color: #ffffff; }

.ml-color-text--yellow-300 {
  color: #fff176; }

.ml-color-border--yellow-300 {
  border-color: #fff176; }

.ml-color--yellow-400 {
  background-color: #ffee58;
  color: #ffffff; }

.ml-color-text--yellow-400 {
  color: #ffee58; }

.ml-color-border--yellow-400 {
  border-color: #ffee58; }

.ml-color--yellow {
  background-color: #ffeb3b;
  color: #ffffff; }

.ml-color-text--yellow {
  color: #ffeb3b; }

.ml-color-border--yellow {
  border-color: #ffeb3b; }

.ml-color--yellow-600 {
  background-color: #fdd835;
  color: #ffffff; }

.ml-color-text--yellow-600 {
  color: #fdd835; }

.ml-color-border--yellow-600 {
  border-color: #fdd835; }

.ml-color--yellow-700 {
  background-color: #fbc02d;
  color: #ffffff; }

.ml-color-text--yellow-700 {
  color: #fbc02d; }

.ml-color-border--yellow-700 {
  border-color: #fbc02d; }

.ml-color--yellow-800 {
  background-color: #f9a825;
  color: #ffffff; }

.ml-color-text--yellow-800 {
  color: #f9a825; }

.ml-color-border--yellow-800 {
  border-color: #f9a825; }

.ml-color--yellow-900 {
  background-color: #f57f17;
  color: #ffffff; }

.ml-color-text--yellow-900 {
  color: #f57f17; }

.ml-color-border--yellow-900 {
  border-color: #f57f17; }

.ml-color--yellow-A100 {
  background-color: #ffff8d;
  color: #666; }

.ml-color-text--yellow-A100 {
  color: #ffff8d; }

.ml-color-border--yellow-A100 {
  border-color: #ffff8d; }

.ml-color--yellow-A200 {
  background-color: #ffff00;
  color: #ffffff; }

.ml-color-text--yellow-A200 {
  color: #ffff00; }

.ml-color-border--yellow-A200 {
  border-color: #ffff00; }

.ml-color--yellow-A300 {
  background-color: #ffea00;
  color: #ffffff; }

.ml-color-text--yellow-A300 {
  color: #ffea00; }

.ml-color-border--yellow-A300 {
  border-color: #ffea00; }

.ml-color--yellow-A400 {
  background-color: #ffd600;
  color: #ffffff; }

.ml-color-text--yellow-A400 {
  color: #ffd600; }

.ml-color-border--yellow-A400 {
  border-color: #ffd600; }

.ml-color--amber-50 {
  background-color: #fff8e1;
  color: #666; }

.ml-color-text--amber-50 {
  color: #fff8e1; }

.ml-color-border--amber-50 {
  border-color: #fff8e1; }

.ml-color--amber-100 {
  background-color: #ffecb3;
  color: #666; }

.ml-color-text--amber-100 {
  color: #ffecb3; }

.ml-color-border--amber-100 {
  border-color: #ffecb3; }

.ml-color--amber-200 {
  background-color: #ffe082;
  color: #ffffff; }

.ml-color-text--amber-200 {
  color: #ffe082; }

.ml-color-border--amber-200 {
  border-color: #ffe082; }

.ml-color--amber-300 {
  background-color: #ffd54f;
  color: #ffffff; }

.ml-color-text--amber-300 {
  color: #ffd54f; }

.ml-color-border--amber-300 {
  border-color: #ffd54f; }

.ml-color--amber-400 {
  background-color: #ffca28;
  color: #ffffff; }

.ml-color-text--amber-400 {
  color: #ffca28; }

.ml-color-border--amber-400 {
  border-color: #ffca28; }

.ml-color--amber {
  background-color: #ffc107;
  color: #ffffff; }

.ml-color-text--amber {
  color: #ffc107; }

.ml-color-border--amber {
  border-color: #ffc107; }

.ml-color--amber-600 {
  background-color: #ffb300;
  color: #ffffff; }

.ml-color-text--amber-600 {
  color: #ffb300; }

.ml-color-border--amber-600 {
  border-color: #ffb300; }

.ml-color--amber-700 {
  background-color: #ffa000;
  color: #ffffff; }

.ml-color-text--amber-700 {
  color: #ffa000; }

.ml-color-border--amber-700 {
  border-color: #ffa000; }

.ml-color--amber-800 {
  background-color: #ff8f00;
  color: #ffffff; }

.ml-color-text--amber-800 {
  color: #ff8f00; }

.ml-color-border--amber-800 {
  border-color: #ff8f00; }

.ml-color--amber-900 {
  background-color: #ff6f00;
  color: #ffffff; }

.ml-color-text--amber-900 {
  color: #ff6f00; }

.ml-color-border--amber-900 {
  border-color: #ff6f00; }

.ml-color--amber-A100 {
  background-color: #ffe57f;
  color: #ffffff; }

.ml-color-text--amber-A100 {
  color: #ffe57f; }

.ml-color-border--amber-A100 {
  border-color: #ffe57f; }

.ml-color--amber-A200 {
  background-color: #ffd740;
  color: #ffffff; }

.ml-color-text--amber-A200 {
  color: #ffd740; }

.ml-color-border--amber-A200 {
  border-color: #ffd740; }

.ml-color--amber-A300 {
  background-color: #ffc400;
  color: #ffffff; }

.ml-color-text--amber-A300 {
  color: #ffc400; }

.ml-color-border--amber-A300 {
  border-color: #ffc400; }

.ml-color--amber-A400 {
  background-color: #ffab00;
  color: #ffffff; }

.ml-color-text--amber-A400 {
  color: #ffab00; }

.ml-color-border--amber-A400 {
  border-color: #ffab00; }

.ml-color--orange-50 {
  background-color: #fff3e0;
  color: #666; }

.ml-color-text--orange-50 {
  color: #fff3e0; }

.ml-color-border--orange-50 {
  border-color: #fff3e0; }

.ml-color--orange-100 {
  background-color: #ffe0b2;
  color: #666; }

.ml-color-text--orange-100 {
  color: #ffe0b2; }

.ml-color-border--orange-100 {
  border-color: #ffe0b2; }

.ml-color--orange-200 {
  background-color: #ffcc80;
  color: #ffffff; }

.ml-color-text--orange-200 {
  color: #ffcc80; }

.ml-color-border--orange-200 {
  border-color: #ffcc80; }

.ml-color--orange-300 {
  background-color: #ffb74d;
  color: #ffffff; }

.ml-color-text--orange-300 {
  color: #ffb74d; }

.ml-color-border--orange-300 {
  border-color: #ffb74d; }

.ml-color--orange-400 {
  background-color: #ffa726;
  color: #ffffff; }

.ml-color-text--orange-400 {
  color: #ffa726; }

.ml-color-border--orange-400 {
  border-color: #ffa726; }

.ml-color--orange {
  background-color: #ff9800;
  color: #ffffff; }

.ml-color-text--orange {
  color: #ff9800; }

.ml-color-border--orange {
  border-color: #ff9800; }

.ml-color--orange-600 {
  background-color: #fb8c00;
  color: #ffffff; }

.ml-color-text--orange-600 {
  color: #fb8c00; }

.ml-color-border--orange-600 {
  border-color: #fb8c00; }

.ml-color--orange-700 {
  background-color: #f57c00;
  color: #ffffff; }

.ml-color-text--orange-700 {
  color: #f57c00; }

.ml-color-border--orange-700 {
  border-color: #f57c00; }

.ml-color--orange-800 {
  background-color: #ef6c00;
  color: #ffffff; }

.ml-color-text--orange-800 {
  color: #ef6c00; }

.ml-color-border--orange-800 {
  border-color: #ef6c00; }

.ml-color--orange-900 {
  background-color: #e65100;
  color: #ffffff; }

.ml-color-text--orange-900 {
  color: #e65100; }

.ml-color-border--orange-900 {
  border-color: #e65100; }

.ml-color--orange-A100 {
  background-color: #ffd180;
  color: #ffffff; }

.ml-color-text--orange-A100 {
  color: #ffd180; }

.ml-color-border--orange-A100 {
  border-color: #ffd180; }

.ml-color--orange-A200 {
  background-color: #ffab40;
  color: #ffffff; }

.ml-color-text--orange-A200 {
  color: #ffab40; }

.ml-color-border--orange-A200 {
  border-color: #ffab40; }

.ml-color--orange-A300 {
  background-color: #ff9100;
  color: #ffffff; }

.ml-color-text--orange-A300 {
  color: #ff9100; }

.ml-color-border--orange-A300 {
  border-color: #ff9100; }

.ml-color--orange-A400 {
  background-color: #ff6d00;
  color: #ffffff; }

.ml-color-text--orange-A400 {
  color: #ff6d00; }

.ml-color-border--orange-A400 {
  border-color: #ff6d00; }

.ml-color--deep-orange-50 {
  background-color: #fbe9e7;
  color: #666; }

.ml-color-text--deep-orange-50 {
  color: #fbe9e7; }

.ml-color-border--deep-orange-50 {
  border-color: #fbe9e7; }

.ml-color--deep-orange-100 {
  background-color: #ffccbc;
  color: #666; }

.ml-color-text--deep-orange-100 {
  color: #ffccbc; }

.ml-color-border--deep-orange-100 {
  border-color: #ffccbc; }

.ml-color--deep-orange-200 {
  background-color: #ffab91;
  color: #666; }

.ml-color-text--deep-orange-200 {
  color: #ffab91; }

.ml-color-border--deep-orange-200 {
  border-color: #ffab91; }

.ml-color--deep-orange-300 {
  background-color: #ff8a65;
  color: #ffffff; }

.ml-color-text--deep-orange-300 {
  color: #ff8a65; }

.ml-color-border--deep-orange-300 {
  border-color: #ff8a65; }

.ml-color--deep-orange-400 {
  background-color: #ff7043;
  color: #ffffff; }

.ml-color-text--deep-orange-400 {
  color: #ff7043; }

.ml-color-border--deep-orange-400 {
  border-color: #ff7043; }

.ml-color--deep-orange {
  background-color: #ff5722;
  color: #ffffff; }

.ml-color-text--deep-orange {
  color: #ff5722; }

.ml-color-border--deep-orange {
  border-color: #ff5722; }

.ml-color--deep-orange-600 {
  background-color: #f4511e;
  color: #ffffff; }

.ml-color-text--deep-orange-600 {
  color: #f4511e; }

.ml-color-border--deep-orange-600 {
  border-color: #f4511e; }

.ml-color--deep-orange-700 {
  background-color: #e64a19;
  color: #ffffff; }

.ml-color-text--deep-orange-700 {
  color: #e64a19; }

.ml-color-border--deep-orange-700 {
  border-color: #e64a19; }

.ml-color--deep-orange-800 {
  background-color: #d84315;
  color: #ffffff; }

.ml-color-text--deep-orange-800 {
  color: #d84315; }

.ml-color-border--deep-orange-800 {
  border-color: #d84315; }

.ml-color--deep-orange-900 {
  background-color: #bf360c;
  color: #ffffff; }

.ml-color-text--deep-orange-900 {
  color: #bf360c; }

.ml-color-border--deep-orange-900 {
  border-color: #bf360c; }

.ml-color--deep-orange-A100 {
  background-color: #ff9e80;
  color: #ffffff; }

.ml-color-text--deep-orange-A100 {
  color: #ff9e80; }

.ml-color-border--deep-orange-A100 {
  border-color: #ff9e80; }

.ml-color--deep-orange-A200 {
  background-color: #ff6e40;
  color: #ffffff; }

.ml-color-text--deep-orange-A200 {
  color: #ff6e40; }

.ml-color-border--deep-orange-A200 {
  border-color: #ff6e40; }

.ml-color--deep-orange-A300 {
  background-color: #ff3d00;
  color: #ffffff; }

.ml-color-text--deep-orange-A300 {
  color: #ff3d00; }

.ml-color-border--deep-orange-A300 {
  border-color: #ff3d00; }

.ml-color--deep-orange-A400 {
  background-color: #dd2c00;
  color: #ffffff; }

.ml-color-text--deep-orange-A400 {
  color: #dd2c00; }

.ml-color-border--deep-orange-A400 {
  border-color: #dd2c00; }

.ml-color--brown-50 {
  background-color: #efebe9;
  color: #666; }

.ml-color-text--brown-50 {
  color: #efebe9; }

.ml-color-border--brown-50 {
  border-color: #efebe9; }

.ml-color--brown-100 {
  background-color: #d7ccc8;
  color: #666; }

.ml-color-text--brown-100 {
  color: #d7ccc8; }

.ml-color-border--brown-100 {
  border-color: #d7ccc8; }

.ml-color--brown-200 {
  background-color: #bcaaa4;
  color: #ffffff; }

.ml-color-text--brown-200 {
  color: #bcaaa4; }

.ml-color-border--brown-200 {
  border-color: #bcaaa4; }

.ml-color--brown-300 {
  background-color: #a1887f;
  color: #ffffff; }

.ml-color-text--brown-300 {
  color: #a1887f; }

.ml-color-border--brown-300 {
  border-color: #a1887f; }

.ml-color--brown-400 {
  background-color: #8d6e63;
  color: #ffffff; }

.ml-color-text--brown-400 {
  color: #8d6e63; }

.ml-color-border--brown-400 {
  border-color: #8d6e63; }

.ml-color--brown {
  background-color: #795548;
  color: #ffffff; }

.ml-color-text--brown {
  color: #795548; }

.ml-color-border--brown {
  border-color: #795548; }

.ml-color--brown-600 {
  background-color: #6d4c41;
  color: #ffffff; }

.ml-color-text--brown-600 {
  color: #6d4c41; }

.ml-color-border--brown-600 {
  border-color: #6d4c41; }

.ml-color--brown-700 {
  background-color: #5d4037;
  color: #ffffff; }

.ml-color-text--brown-700 {
  color: #5d4037; }

.ml-color-border--brown-700 {
  border-color: #5d4037; }

.ml-color--brown-800 {
  background-color: #4e342e;
  color: #ffffff; }

.ml-color-text--brown-800 {
  color: #4e342e; }

.ml-color-border--brown-800 {
  border-color: #4e342e; }

.ml-color--brown-900 {
  background-color: #3e2723;
  color: #ffffff; }

.ml-color-text--brown-900 {
  color: #3e2723; }

.ml-color-border--brown-900 {
  border-color: #3e2723; }

.ml-color--blue-grey-50 {
  background-color: #eceff1;
  color: #666; }

.ml-color-text--blue-grey-50 {
  color: #eceff1; }

.ml-color-border--blue-grey-50 {
  border-color: #eceff1; }

.ml-color--blue-grey-100 {
  background-color: #cfd8dc;
  color: #666; }

.ml-color-text--blue-grey-100 {
  color: #cfd8dc; }

.ml-color-border--blue-grey-100 {
  border-color: #cfd8dc; }

.ml-color--blue-grey-200 {
  background-color: #b0bec5;
  color: #ffffff; }

.ml-color-text--blue-grey-200 {
  color: #b0bec5; }

.ml-color-border--blue-grey-200 {
  border-color: #b0bec5; }

.ml-color--blue-grey-300 {
  background-color: #90a4ae;
  color: #ffffff; }

.ml-color-text--blue-grey-300 {
  color: #90a4ae; }

.ml-color-border--blue-grey-300 {
  border-color: #90a4ae; }

.ml-color--blue-grey-400 {
  background-color: #78909c;
  color: #ffffff; }

.ml-color-text--blue-grey-400 {
  color: #78909c; }

.ml-color-border--blue-grey-400 {
  border-color: #78909c; }

.ml-color--blue-grey {
  background-color: #607d8b;
  color: #ffffff; }

.ml-color-text--blue-grey {
  color: #607d8b; }

.ml-color-border--blue-grey {
  border-color: #607d8b; }

.ml-color--blue-grey-600 {
  background-color: #546e7a;
  color: #ffffff; }

.ml-color-text--blue-grey-600 {
  color: #546e7a; }

.ml-color-border--blue-grey-600 {
  border-color: #546e7a; }

.ml-color--blue-grey-700 {
  background-color: #455a64;
  color: #ffffff; }

.ml-color-text--blue-grey-700 {
  color: #455a64; }

.ml-color-border--blue-grey-700 {
  border-color: #455a64; }

.ml-color--blue-grey-800 {
  background-color: #37474f;
  color: #ffffff; }

.ml-color-text--blue-grey-800 {
  color: #37474f; }

.ml-color-border--blue-grey-800 {
  border-color: #37474f; }

.ml-color--blue-grey-900 {
  background-color: #263238;
  color: #ffffff; }

.ml-color-text--blue-grey-900 {
  color: #263238; }

.ml-color-border--blue-grey-900 {
  border-color: #263238; }

.ml-color--grey-50 {
  background-color: #fafafa;
  color: #666; }

.ml-color-text--grey-50 {
  color: #fafafa; }

.ml-color-border--grey-50 {
  border-color: #fafafa; }

.ml-color--grey-100 {
  background-color: #f5f5f5;
  color: #666; }

.ml-color-text--grey-100 {
  color: #f5f5f5; }

.ml-color-border--grey-100 {
  border-color: #f5f5f5; }

.ml-color--grey-200 {
  background-color: #eeeeee;
  color: #666; }

.ml-color-text--grey-200 {
  color: #eeeeee; }

.ml-color-border--grey-200 {
  border-color: #eeeeee; }

.ml-color--grey-300 {
  background-color: #e0e0e0;
  color: #666; }

.ml-color-text--grey-300 {
  color: #e0e0e0; }

.ml-color-border--grey-300 {
  border-color: #e0e0e0; }

.ml-color--grey-400 {
  background-color: #bdbdbd;
  color: #ffffff; }

.ml-color-text--grey-400 {
  color: #bdbdbd; }

.ml-color-border--grey-400 {
  border-color: #bdbdbd; }

.ml-color--grey {
  background-color: #9e9e9e;
  color: #ffffff; }

.ml-color-text--grey {
  color: #9e9e9e; }

.ml-color-border--grey {
  border-color: #9e9e9e; }

.ml-color--grey-600 {
  background-color: #757575;
  color: #ffffff; }

.ml-color-text--grey-600 {
  color: #757575; }

.ml-color-border--grey-600 {
  border-color: #757575; }

.ml-color--grey-700 {
  background-color: #616161;
  color: #ffffff; }

.ml-color-text--grey-700 {
  color: #616161; }

.ml-color-border--grey-700 {
  border-color: #616161; }

.ml-color--grey-800 {
  background-color: #424242;
  color: #ffffff; }

.ml-color-text--grey-800 {
  color: #424242; }

.ml-color-border--grey-800 {
  border-color: #424242; }

.ml-color--grey-900 {
  background-color: #212121;
  color: #ffffff; }

.ml-color-text--grey-900 {
  color: #212121; }

.ml-color-border--grey-900 {
  border-color: #212121; }

.ml-color--shades-black {
  background-color: #000000;
  color: #ffffff; }

.ml-color-text--shades-black {
  color: #000000; }

.ml-color-border--shades-black {
  border-color: #000000; }

.ml-color--shades-white {
  background-color: #FFFFFF;
  color: #666; }

.ml-color-text--shades-white {
  color: #FFFFFF; }

.ml-color-border--shades-white {
  border-color: #FFFFFF; }

.color-black {
  background-color: #000000 !important;
  color: #ffffff; }

.color-black-text {
  color: #000000; }

.color-white {
  background-color: #FFFFFF !important;
  color: #666; }

.color-white-text {
  color: #FFFFFF; }

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Material Design Lite */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* TOOLTIP */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* TOOLTIP */
/*
 * What follows is the result of much research on cross-browser styling.
 * Credit left inline and big thanks to Nicolas Gallagher, Jonathan Neal,
 * Kroc Camen, and the H5BP dev community and team.
 */
/* ==========================================================================
   Base styles: opinionated defaults
   ========================================================================== */
html {
  color: rgba(0,0,0, 0.87);
  font-size: 1em;
  line-height: 1.4; }

/*
 * Remove text-shadow in selection highlight:
 * https://twitter.com/miketaylr/status/12228805301
 *
 * These selection rule sets have to be separate.
 * Customize the background color to match your design.
 */
::selection {
  background: #b3d4fc;
  text-shadow: none; }

/*
 * A better looking default horizontal rule
 */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

/*
 * Remove the gap between audio, canvas, iframes,
 * images, videos and the bottom of their containers:
 * https://github.com/h5bp/html5-boilerplate/issues/440
 */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

/*
 * Remove default fieldset styles.
 */
fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

/*
 * Allow only vertical resizing of textareas.
 */
textarea {
  resize: vertical; }

/* ==========================================================================
   Browser Upgrade Prompt
   ========================================================================== */
.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0; }

/* ==========================================================================
   Author's custom styles
   ========================================================================== */
/* ==========================================================================
   Helper classes
   ========================================================================== */
/*
 * Hide visually and from screen readers:
 */
.hidden {
  display: none !important; }

/*
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

/*
 * Extends the .visuallyhidden class to allow the element
 * to be focusable when navigated to via the keyboard:
 * https://www.drupal.org/node/897638
 */
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto; }

/*
 * Hide visually and from screen readers, but maintain layout
 */
.invisible {
  visibility: hidden; }

/*
 * Clearfix: contain floats
 *
 * For modern browsers
 * 1. The space content is one way to avoid an Opera bug when the
 *    `contenteditable` attribute is included anywhere else in the document.
 *    Otherwise it causes space to appear at the top and bottom of elements
 *    that receive the `clearfix` class.
 * 2. The use of `table` rather than `block` is only necessary if using
 *    `:before` to contain the top-margins of child elements.
 */
.clearfix:before,
.clearfix:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */ }

.clearfix:after {
  clear: both; }

/* ==========================================================================
   EXAMPLE Media Queries for Responsive Design.
   These examples override the primary ('mobile first') styles.
   Modify as content requires.
   ========================================================================== */
/* ==========================================================================
   Print styles.
   Inlined to avoid the additional HTTP request:
   http://www.phpied.com/delay-loading-your-print-css/
   ========================================================================== */
@media print {
  *,
  *:before,
  *:after,
  *:first-letter,
  *:first-line {
    background: transparent !important;
    color: #000 !important;
    /* Black prints faster: http://www.sanbeiji.com/archives/953 */
    box-shadow: none !important;
    text-shadow: none !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  /*
     * Don't show links that are fragment identifiers,
     * or use the `javascript:` pseudo protocol
     */
  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: ""; }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  /*
     * Printing Tables:
     * http://css-discuss.incutio.com/wiki/Printing_Tables
     */
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; } }

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Remove the unwanted box around FAB buttons */
/* More info: http://goo.gl/IPwKi */
a, .mdl-accordion, .mdl-button, .mdl-card, .mdl-checkbox, .mdl-dropdown-menu,
.mdl-icon-toggle, .mdl-item, .mdl-radio, .mdl-slider, .mdl-switch, .mdl-tabs__tab {
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0); }

/*
 * Make html take up the entire screen
 * Then set touch-action to avoid touch delay on mobile IE
 */
html {
  width: 100%;
  height: 100%;
  -ms-touch-action: manipulation;
  touch-action: manipulation; }

/*
* Make body take up the entire screen
* Remove body margin so layout containers don't cause extra overflow.
*/
body {
  width: 100%;
  min-height: 100%;
  margin: 0; }

/*
 * Main display reset for IE support.
 * Source: http://weblog.west-wind.com/posts/2015/Jan/12/main-HTML5-Tag-not-working-in-Internet-Explorer-91011
 */
main {
  display: block; }

/*
* Apply no display to elements with the hidden attribute.
* IE 9 and 10 support.
*/
*[hidden] {
  display: none !important; }

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* TOOLTIP */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
html, body {
  font-family: "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px; }

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0; }

/**
  * Styles for HTML elements
  */
h1 small, h2 small, h3 small, h4 small, h5 small, h6 small {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 56px;
  font-weight: 400;
  line-height: 1.35;
  letter-spacing: -0.02em;
  opacity: 0.54;
  font-size: 0.6em; }

h1 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 56px;
  font-weight: 400;
  line-height: 1.35;
  letter-spacing: -0.02em;
  margin-top: 24px;
  margin-bottom: 24px; }

h2 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 45px;
  font-weight: 400;
  line-height: 48px;
  margin-top: 24px;
  margin-bottom: 24px; }

h3 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 34px;
  font-weight: 400;
  line-height: 40px;
  margin-top: 24px;
  margin-bottom: 24px; }

h4 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 24px;
  margin-bottom: 16px; }

h5 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.02em;
  margin-top: 24px;
  margin-bottom: 16px; }

h6 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.04em;
  margin-top: 24px;
  margin-bottom: 16px; }

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  margin-bottom: 16px; }

a {
  color: rgb(255,64,129);
  font-weight: 500; }

blockquote {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  position: relative;
  font-size: 24px;
  font-weight: 300;
  font-style: italic;
  line-height: 1.35;
  letter-spacing: 0.08em; }
  blockquote:before {
    position: absolute;
    left: -0.5em;
    content: '“'; }
  blockquote:after {
    content: '”';
    margin-left: -0.05em; }

mark {
  background-color: #f4ff81; }

dt {
  font-weight: 700; }

address {
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0;
  font-style: normal; }

ul, ol {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0; }

/**
 * Class Name Styles
 */
.mdl-typography--display-4 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 112px;
  font-weight: 300;
  line-height: 1;
  letter-spacing: -0.04em; }

.mdl-typography--display-4-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 112px;
  font-weight: 300;
  line-height: 1;
  letter-spacing: -0.04em;
  opacity: 0.54; }

.mdl-typography--display-3 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 56px;
  font-weight: 400;
  line-height: 1.35;
  letter-spacing: -0.02em; }

.mdl-typography--display-3-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 56px;
  font-weight: 400;
  line-height: 1.35;
  letter-spacing: -0.02em;
  opacity: 0.54; }

.mdl-typography--display-2 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 45px;
  font-weight: 400;
  line-height: 48px; }

.mdl-typography--display-2-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 45px;
  font-weight: 400;
  line-height: 48px;
  opacity: 0.54; }

.mdl-typography--display-1 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 34px;
  font-weight: 400;
  line-height: 40px; }

.mdl-typography--display-1-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 34px;
  font-weight: 400;
  line-height: 40px;
  opacity: 0.54; }

.mdl-typography--headline {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  -moz-osx-font-smoothing: grayscale; }

.mdl-typography--headline-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  -moz-osx-font-smoothing: grayscale;
  opacity: 0.87; }

.mdl-typography--title {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.02em; }

.mdl-typography--title-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.02em;
  opacity: 0.87; }

.mdl-typography--subhead {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.04em; }

.mdl-typography--subhead-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.04em;
  opacity: 0.87; }

.mdl-typography--body-2 {
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: 0; }

.mdl-typography--body-2-color-contrast {
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: 0;
  opacity: 0.87; }

.mdl-typography--body-1 {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0; }

.mdl-typography--body-1-color-contrast {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  opacity: 0.87; }

.mdl-typography--body-2-force-preferred-font {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0; }

.mdl-typography--body-2-force-preferred-font-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  opacity: 0.87; }

.mdl-typography--body-1-force-preferred-font {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0; }

.mdl-typography--body-1-force-preferred-font-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  opacity: 0.87; }

.mdl-typography--caption {
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0; }

.mdl-typography--caption-force-preferred-font {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0; }

.mdl-typography--caption-color-contrast {
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0;
  opacity: 0.54; }

.mdl-typography--caption-force-preferred-font-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0;
  opacity: 0.54; }

.mdl-typography--menu {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0; }

.mdl-typography--menu-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0;
  opacity: 0.87; }

.mdl-typography--button {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1;
  letter-spacing: 0; }

.mdl-typography--button-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1;
  letter-spacing: 0;
  opacity: 0.87; }

.mdl-typography--text-left {
  text-align: left; }

.mdl-typography--text-right {
  text-align: right; }

.mdl-typography--text-center {
  text-align: center; }

.mdl-typography--text-justify {
  text-align: justify; }

.mdl-typography--text-nowrap {
  white-space: nowrap; }

.mdl-typography--text-lowercase {
  text-transform: lowercase; }

.mdl-typography--text-uppercase {
  text-transform: uppercase; }

.mdl-typography--text-capitalize {
  text-transform: capitalize; }

.mdl-typography--font-thin {
  font-weight: 200 !important; }

.mdl-typography--font-light {
  font-weight: 300 !important; }

.mdl-typography--font-regular {
  font-weight: 400 !important; }

.mdl-typography--font-medium {
  font-weight: 500 !important; }

.mdl-typography--font-bold {
  font-weight: 700 !important; }

.mdl-typography--font-black {
  font-weight: 900 !important; }

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* TOOLTIP */
.mdl-color-text--red {
  color: rgb(244,67,54) !important; }

.mdl-color--red {
  background-color: rgb(244,67,54) !important; }

.mdl-color-text--red-50 {
  color: rgb(255,235,238) !important; }

.mdl-color--red-50 {
  background-color: rgb(255,235,238) !important; }

.mdl-color-text--red-100 {
  color: rgb(255,205,210) !important; }

.mdl-color--red-100 {
  background-color: rgb(255,205,210) !important; }

.mdl-color-text--red-200 {
  color: rgb(239,154,154) !important; }

.mdl-color--red-200 {
  background-color: rgb(239,154,154) !important; }

.mdl-color-text--red-300 {
  color: rgb(229,115,115) !important; }

.mdl-color--red-300 {
  background-color: rgb(229,115,115) !important; }

.mdl-color-text--red-400 {
  color: rgb(239,83,80) !important; }

.mdl-color--red-400 {
  background-color: rgb(239,83,80) !important; }

.mdl-color-text--red-500 {
  color: rgb(244,67,54) !important; }

.mdl-color--red-500 {
  background-color: rgb(244,67,54) !important; }

.mdl-color-text--red-600 {
  color: rgb(229,57,53) !important; }

.mdl-color--red-600 {
  background-color: rgb(229,57,53) !important; }

.mdl-color-text--red-700 {
  color: rgb(211,47,47) !important; }

.mdl-color--red-700 {
  background-color: rgb(211,47,47) !important; }

.mdl-color-text--red-800 {
  color: rgb(198,40,40) !important; }

.mdl-color--red-800 {
  background-color: rgb(198,40,40) !important; }

.mdl-color-text--red-900 {
  color: rgb(183,28,28) !important; }

.mdl-color--red-900 {
  background-color: rgb(183,28,28) !important; }

.mdl-color-text--red-A100 {
  color: rgb(255,138,128) !important; }

.mdl-color--red-A100 {
  background-color: rgb(255,138,128) !important; }

.mdl-color-text--red-A200 {
  color: rgb(255,82,82) !important; }

.mdl-color--red-A200 {
  background-color: rgb(255,82,82) !important; }

.mdl-color-text--red-A400 {
  color: rgb(255,23,68) !important; }

.mdl-color--red-A400 {
  background-color: rgb(255,23,68) !important; }

.mdl-color-text--red-A700 {
  color: rgb(213,0,0) !important; }

.mdl-color--red-A700 {
  background-color: rgb(213,0,0) !important; }

.mdl-color-text--pink {
  color: rgb(233,30,99) !important; }

.mdl-color--pink {
  background-color: rgb(233,30,99) !important; }

.mdl-color-text--pink-50 {
  color: rgb(252,228,236) !important; }

.mdl-color--pink-50 {
  background-color: rgb(252,228,236) !important; }

.mdl-color-text--pink-100 {
  color: rgb(248,187,208) !important; }

.mdl-color--pink-100 {
  background-color: rgb(248,187,208) !important; }

.mdl-color-text--pink-200 {
  color: rgb(244,143,177) !important; }

.mdl-color--pink-200 {
  background-color: rgb(244,143,177) !important; }

.mdl-color-text--pink-300 {
  color: rgb(240,98,146) !important; }

.mdl-color--pink-300 {
  background-color: rgb(240,98,146) !important; }

.mdl-color-text--pink-400 {
  color: rgb(236,64,122) !important; }

.mdl-color--pink-400 {
  background-color: rgb(236,64,122) !important; }

.mdl-color-text--pink-500 {
  color: rgb(233,30,99) !important; }

.mdl-color--pink-500 {
  background-color: rgb(233,30,99) !important; }

.mdl-color-text--pink-600 {
  color: rgb(216,27,96) !important; }

.mdl-color--pink-600 {
  background-color: rgb(216,27,96) !important; }

.mdl-color-text--pink-700 {
  color: rgb(194,24,91) !important; }

.mdl-color--pink-700 {
  background-color: rgb(194,24,91) !important; }

.mdl-color-text--pink-800 {
  color: rgb(173,20,87) !important; }

.mdl-color--pink-800 {
  background-color: rgb(173,20,87) !important; }

.mdl-color-text--pink-900 {
  color: rgb(136,14,79) !important; }

.mdl-color--pink-900 {
  background-color: rgb(136,14,79) !important; }

.mdl-color-text--pink-A100 {
  color: rgb(255,128,171) !important; }

.mdl-color--pink-A100 {
  background-color: rgb(255,128,171) !important; }

.mdl-color-text--pink-A200 {
  color: rgb(255,64,129) !important; }

.mdl-color--pink-A200 {
  background-color: rgb(255,64,129) !important; }

.mdl-color-text--pink-A400 {
  color: rgb(245,0,87) !important; }

.mdl-color--pink-A400 {
  background-color: rgb(245,0,87) !important; }

.mdl-color-text--pink-A700 {
  color: rgb(197,17,98) !important; }

.mdl-color--pink-A700 {
  background-color: rgb(197,17,98) !important; }

.mdl-color-text--purple {
  color: rgb(156,39,176) !important; }

.mdl-color--purple {
  background-color: rgb(156,39,176) !important; }

.mdl-color-text--purple-50 {
  color: rgb(243,229,245) !important; }

.mdl-color--purple-50 {
  background-color: rgb(243,229,245) !important; }

.mdl-color-text--purple-100 {
  color: rgb(225,190,231) !important; }

.mdl-color--purple-100 {
  background-color: rgb(225,190,231) !important; }

.mdl-color-text--purple-200 {
  color: rgb(206,147,216) !important; }

.mdl-color--purple-200 {
  background-color: rgb(206,147,216) !important; }

.mdl-color-text--purple-300 {
  color: rgb(186,104,200) !important; }

.mdl-color--purple-300 {
  background-color: rgb(186,104,200) !important; }

.mdl-color-text--purple-400 {
  color: rgb(171,71,188) !important; }

.mdl-color--purple-400 {
  background-color: rgb(171,71,188) !important; }

.mdl-color-text--purple-500 {
  color: rgb(156,39,176) !important; }

.mdl-color--purple-500 {
  background-color: rgb(156,39,176) !important; }

.mdl-color-text--purple-600 {
  color: rgb(142,36,170) !important; }

.mdl-color--purple-600 {
  background-color: rgb(142,36,170) !important; }

.mdl-color-text--purple-700 {
  color: rgb(123,31,162) !important; }

.mdl-color--purple-700 {
  background-color: rgb(123,31,162) !important; }

.mdl-color-text--purple-800 {
  color: rgb(106,27,154) !important; }

.mdl-color--purple-800 {
  background-color: rgb(106,27,154) !important; }

.mdl-color-text--purple-900 {
  color: rgb(74,20,140) !important; }

.mdl-color--purple-900 {
  background-color: rgb(74,20,140) !important; }

.mdl-color-text--purple-A100 {
  color: rgb(234,128,252) !important; }

.mdl-color--purple-A100 {
  background-color: rgb(234,128,252) !important; }

.mdl-color-text--purple-A200 {
  color: rgb(224,64,251) !important; }

.mdl-color--purple-A200 {
  background-color: rgb(224,64,251) !important; }

.mdl-color-text--purple-A400 {
  color: rgb(213,0,249) !important; }

.mdl-color--purple-A400 {
  background-color: rgb(213,0,249) !important; }

.mdl-color-text--purple-A700 {
  color: rgb(170,0,255) !important; }

.mdl-color--purple-A700 {
  background-color: rgb(170,0,255) !important; }

.mdl-color-text--deep-purple {
  color: rgb(103,58,183) !important; }

.mdl-color--deep-purple {
  background-color: rgb(103,58,183) !important; }

.mdl-color-text--deep-purple-50 {
  color: rgb(237,231,246) !important; }

.mdl-color--deep-purple-50 {
  background-color: rgb(237,231,246) !important; }

.mdl-color-text--deep-purple-100 {
  color: rgb(209,196,233) !important; }

.mdl-color--deep-purple-100 {
  background-color: rgb(209,196,233) !important; }

.mdl-color-text--deep-purple-200 {
  color: rgb(179,157,219) !important; }

.mdl-color--deep-purple-200 {
  background-color: rgb(179,157,219) !important; }

.mdl-color-text--deep-purple-300 {
  color: rgb(149,117,205) !important; }

.mdl-color--deep-purple-300 {
  background-color: rgb(149,117,205) !important; }

.mdl-color-text--deep-purple-400 {
  color: rgb(126,87,194) !important; }

.mdl-color--deep-purple-400 {
  background-color: rgb(126,87,194) !important; }

.mdl-color-text--deep-purple-500 {
  color: rgb(103,58,183) !important; }

.mdl-color--deep-purple-500 {
  background-color: rgb(103,58,183) !important; }

.mdl-color-text--deep-purple-600 {
  color: rgb(94,53,177) !important; }

.mdl-color--deep-purple-600 {
  background-color: rgb(94,53,177) !important; }

.mdl-color-text--deep-purple-700 {
  color: rgb(81,45,168) !important; }

.mdl-color--deep-purple-700 {
  background-color: rgb(81,45,168) !important; }

.mdl-color-text--deep-purple-800 {
  color: rgb(69,39,160) !important; }

.mdl-color--deep-purple-800 {
  background-color: rgb(69,39,160) !important; }

.mdl-color-text--deep-purple-900 {
  color: rgb(49,27,146) !important; }

.mdl-color--deep-purple-900 {
  background-color: rgb(49,27,146) !important; }

.mdl-color-text--deep-purple-A100 {
  color: rgb(179,136,255) !important; }

.mdl-color--deep-purple-A100 {
  background-color: rgb(179,136,255) !important; }

.mdl-color-text--deep-purple-A200 {
  color: rgb(124,77,255) !important; }

.mdl-color--deep-purple-A200 {
  background-color: rgb(124,77,255) !important; }

.mdl-color-text--deep-purple-A400 {
  color: rgb(101,31,255) !important; }

.mdl-color--deep-purple-A400 {
  background-color: rgb(101,31,255) !important; }

.mdl-color-text--deep-purple-A700 {
  color: rgb(98,0,234) !important; }

.mdl-color--deep-purple-A700 {
  background-color: rgb(98,0,234) !important; }

.mdl-color-text--indigo {
  color: rgb(63,81,181) !important; }

.mdl-color--indigo {
  background-color: rgb(63,81,181) !important; }

.mdl-color-text--indigo-50 {
  color: rgb(232,234,246) !important; }

.mdl-color--indigo-50 {
  background-color: rgb(232,234,246) !important; }

.mdl-color-text--indigo-100 {
  color: rgb(197,202,233) !important; }

.mdl-color--indigo-100 {
  background-color: rgb(197,202,233) !important; }

.mdl-color-text--indigo-200 {
  color: rgb(159,168,218) !important; }

.mdl-color--indigo-200 {
  background-color: rgb(159,168,218) !important; }

.mdl-color-text--indigo-300 {
  color: rgb(121,134,203) !important; }

.mdl-color--indigo-300 {
  background-color: rgb(121,134,203) !important; }

.mdl-color-text--indigo-400 {
  color: rgb(92,107,192) !important; }

.mdl-color--indigo-400 {
  background-color: rgb(92,107,192) !important; }

.mdl-color-text--indigo-500 {
  color: rgb(63,81,181) !important; }

.mdl-color--indigo-500 {
  background-color: rgb(63,81,181) !important; }

.mdl-color-text--indigo-600 {
  color: rgb(57,73,171) !important; }

.mdl-color--indigo-600 {
  background-color: rgb(57,73,171) !important; }

.mdl-color-text--indigo-700 {
  color: rgb(48,63,159) !important; }

.mdl-color--indigo-700 {
  background-color: rgb(48,63,159) !important; }

.mdl-color-text--indigo-800 {
  color: rgb(40,53,147) !important; }

.mdl-color--indigo-800 {
  background-color: rgb(40,53,147) !important; }

.mdl-color-text--indigo-900 {
  color: rgb(26,35,126) !important; }

.mdl-color--indigo-900 {
  background-color: rgb(26,35,126) !important; }

.mdl-color-text--indigo-A100 {
  color: rgb(140,158,255) !important; }

.mdl-color--indigo-A100 {
  background-color: rgb(140,158,255) !important; }

.mdl-color-text--indigo-A200 {
  color: rgb(83,109,254) !important; }

.mdl-color--indigo-A200 {
  background-color: rgb(83,109,254) !important; }

.mdl-color-text--indigo-A400 {
  color: rgb(61,90,254) !important; }

.mdl-color--indigo-A400 {
  background-color: rgb(61,90,254) !important; }

.mdl-color-text--indigo-A700 {
  color: rgb(48,79,254) !important; }

.mdl-color--indigo-A700 {
  background-color: rgb(48,79,254) !important; }

.mdl-color-text--blue {
  color: rgb(33,150,243) !important; }

.mdl-color--blue {
  background-color: rgb(33,150,243) !important; }

.mdl-color-text--blue-50 {
  color: rgb(227,242,253) !important; }

.mdl-color--blue-50 {
  background-color: rgb(227,242,253) !important; }

.mdl-color-text--blue-100 {
  color: rgb(187,222,251) !important; }

.mdl-color--blue-100 {
  background-color: rgb(187,222,251) !important; }

.mdl-color-text--blue-200 {
  color: rgb(144,202,249) !important; }

.mdl-color--blue-200 {
  background-color: rgb(144,202,249) !important; }

.mdl-color-text--blue-300 {
  color: rgb(100,181,246) !important; }

.mdl-color--blue-300 {
  background-color: rgb(100,181,246) !important; }

.mdl-color-text--blue-400 {
  color: rgb(66,165,245) !important; }

.mdl-color--blue-400 {
  background-color: rgb(66,165,245) !important; }

.mdl-color-text--blue-500 {
  color: rgb(33,150,243) !important; }

.mdl-color--blue-500 {
  background-color: rgb(33,150,243) !important; }

.mdl-color-text--blue-600 {
  color: rgb(30,136,229) !important; }

.mdl-color--blue-600 {
  background-color: rgb(30,136,229) !important; }

.mdl-color-text--blue-700 {
  color: rgb(25,118,210) !important; }

.mdl-color--blue-700 {
  background-color: rgb(25,118,210) !important; }

.mdl-color-text--blue-800 {
  color: rgb(21,101,192) !important; }

.mdl-color--blue-800 {
  background-color: rgb(21,101,192) !important; }

.mdl-color-text--blue-900 {
  color: rgb(13,71,161) !important; }

.mdl-color--blue-900 {
  background-color: rgb(13,71,161) !important; }

.mdl-color-text--blue-A100 {
  color: rgb(130,177,255) !important; }

.mdl-color--blue-A100 {
  background-color: rgb(130,177,255) !important; }

.mdl-color-text--blue-A200 {
  color: rgb(68,138,255) !important; }

.mdl-color--blue-A200 {
  background-color: rgb(68,138,255) !important; }

.mdl-color-text--blue-A400 {
  color: rgb(41,121,255) !important; }

.mdl-color--blue-A400 {
  background-color: rgb(41,121,255) !important; }

.mdl-color-text--blue-A700 {
  color: rgb(41,98,255) !important; }

.mdl-color--blue-A700 {
  background-color: rgb(41,98,255) !important; }

.mdl-color-text--light-blue {
  color: rgb(3,169,244) !important; }

.mdl-color--light-blue {
  background-color: rgb(3,169,244) !important; }

.mdl-color-text--light-blue-50 {
  color: rgb(225,245,254) !important; }

.mdl-color--light-blue-50 {
  background-color: rgb(225,245,254) !important; }

.mdl-color-text--light-blue-100 {
  color: rgb(179,229,252) !important; }

.mdl-color--light-blue-100 {
  background-color: rgb(179,229,252) !important; }

.mdl-color-text--light-blue-200 {
  color: rgb(129,212,250) !important; }

.mdl-color--light-blue-200 {
  background-color: rgb(129,212,250) !important; }

.mdl-color-text--light-blue-300 {
  color: rgb(79,195,247) !important; }

.mdl-color--light-blue-300 {
  background-color: rgb(79,195,247) !important; }

.mdl-color-text--light-blue-400 {
  color: rgb(41,182,246) !important; }

.mdl-color--light-blue-400 {
  background-color: rgb(41,182,246) !important; }

.mdl-color-text--light-blue-500 {
  color: rgb(3,169,244) !important; }

.mdl-color--light-blue-500 {
  background-color: rgb(3,169,244) !important; }

.mdl-color-text--light-blue-600 {
  color: rgb(3,155,229) !important; }

.mdl-color--light-blue-600 {
  background-color: rgb(3,155,229) !important; }

.mdl-color-text--light-blue-700 {
  color: rgb(2,136,209) !important; }

.mdl-color--light-blue-700 {
  background-color: rgb(2,136,209) !important; }

.mdl-color-text--light-blue-800 {
  color: rgb(2,119,189) !important; }

.mdl-color--light-blue-800 {
  background-color: rgb(2,119,189) !important; }

.mdl-color-text--light-blue-900 {
  color: rgb(1,87,155) !important; }

.mdl-color--light-blue-900 {
  background-color: rgb(1,87,155) !important; }

.mdl-color-text--light-blue-A100 {
  color: rgb(128,216,255) !important; }

.mdl-color--light-blue-A100 {
  background-color: rgb(128,216,255) !important; }

.mdl-color-text--light-blue-A200 {
  color: rgb(64,196,255) !important; }

.mdl-color--light-blue-A200 {
  background-color: rgb(64,196,255) !important; }

.mdl-color-text--light-blue-A400 {
  color: rgb(0,176,255) !important; }

.mdl-color--light-blue-A400 {
  background-color: rgb(0,176,255) !important; }

.mdl-color-text--light-blue-A700 {
  color: rgb(0,145,234) !important; }

.mdl-color--light-blue-A700 {
  background-color: rgb(0,145,234) !important; }

.mdl-color-text--cyan {
  color: rgb(0,188,212) !important; }

.mdl-color--cyan {
  background-color: rgb(0,188,212) !important; }

.mdl-color-text--cyan-50 {
  color: rgb(224,247,250) !important; }

.mdl-color--cyan-50 {
  background-color: rgb(224,247,250) !important; }

.mdl-color-text--cyan-100 {
  color: rgb(178,235,242) !important; }

.mdl-color--cyan-100 {
  background-color: rgb(178,235,242) !important; }

.mdl-color-text--cyan-200 {
  color: rgb(128,222,234) !important; }

.mdl-color--cyan-200 {
  background-color: rgb(128,222,234) !important; }

.mdl-color-text--cyan-300 {
  color: rgb(77,208,225) !important; }

.mdl-color--cyan-300 {
  background-color: rgb(77,208,225) !important; }

.mdl-color-text--cyan-400 {
  color: rgb(38,198,218) !important; }

.mdl-color--cyan-400 {
  background-color: rgb(38,198,218) !important; }

.mdl-color-text--cyan-500 {
  color: rgb(0,188,212) !important; }

.mdl-color--cyan-500 {
  background-color: rgb(0,188,212) !important; }

.mdl-color-text--cyan-600 {
  color: rgb(0,172,193) !important; }

.mdl-color--cyan-600 {
  background-color: rgb(0,172,193) !important; }

.mdl-color-text--cyan-700 {
  color: rgb(0,151,167) !important; }

.mdl-color--cyan-700 {
  background-color: rgb(0,151,167) !important; }

.mdl-color-text--cyan-800 {
  color: rgb(0,131,143) !important; }

.mdl-color--cyan-800 {
  background-color: rgb(0,131,143) !important; }

.mdl-color-text--cyan-900 {
  color: rgb(0,96,100) !important; }

.mdl-color--cyan-900 {
  background-color: rgb(0,96,100) !important; }

.mdl-color-text--cyan-A100 {
  color: rgb(132,255,255) !important; }

.mdl-color--cyan-A100 {
  background-color: rgb(132,255,255) !important; }

.mdl-color-text--cyan-A200 {
  color: rgb(24,255,255) !important; }

.mdl-color--cyan-A200 {
  background-color: rgb(24,255,255) !important; }

.mdl-color-text--cyan-A400 {
  color: rgb(0,229,255) !important; }

.mdl-color--cyan-A400 {
  background-color: rgb(0,229,255) !important; }

.mdl-color-text--cyan-A700 {
  color: rgb(0,184,212) !important; }

.mdl-color--cyan-A700 {
  background-color: rgb(0,184,212) !important; }

.mdl-color-text--teal {
  color: rgb(0,150,136) !important; }

.mdl-color--teal {
  background-color: rgb(0,150,136) !important; }

.mdl-color-text--teal-50 {
  color: rgb(224,242,241) !important; }

.mdl-color--teal-50 {
  background-color: rgb(224,242,241) !important; }

.mdl-color-text--teal-100 {
  color: rgb(178,223,219) !important; }

.mdl-color--teal-100 {
  background-color: rgb(178,223,219) !important; }

.mdl-color-text--teal-200 {
  color: rgb(128,203,196) !important; }

.mdl-color--teal-200 {
  background-color: rgb(128,203,196) !important; }

.mdl-color-text--teal-300 {
  color: rgb(77,182,172) !important; }

.mdl-color--teal-300 {
  background-color: rgb(77,182,172) !important; }

.mdl-color-text--teal-400 {
  color: rgb(38,166,154) !important; }

.mdl-color--teal-400 {
  background-color: rgb(38,166,154) !important; }

.mdl-color-text--teal-500 {
  color: rgb(0,150,136) !important; }

.mdl-color--teal-500 {
  background-color: rgb(0,150,136) !important; }

.mdl-color-text--teal-600 {
  color: rgb(0,137,123) !important; }

.mdl-color--teal-600 {
  background-color: rgb(0,137,123) !important; }

.mdl-color-text--teal-700 {
  color: rgb(0,121,107) !important; }

.mdl-color--teal-700 {
  background-color: rgb(0,121,107) !important; }

.mdl-color-text--teal-800 {
  color: rgb(0,105,92) !important; }

.mdl-color--teal-800 {
  background-color: rgb(0,105,92) !important; }

.mdl-color-text--teal-900 {
  color: rgb(0,77,64) !important; }

.mdl-color--teal-900 {
  background-color: rgb(0,77,64) !important; }

.mdl-color-text--teal-A100 {
  color: rgb(167,255,235) !important; }

.mdl-color--teal-A100 {
  background-color: rgb(167,255,235) !important; }

.mdl-color-text--teal-A200 {
  color: rgb(100,255,218) !important; }

.mdl-color--teal-A200 {
  background-color: rgb(100,255,218) !important; }

.mdl-color-text--teal-A400 {
  color: rgb(29,233,182) !important; }

.mdl-color--teal-A400 {
  background-color: rgb(29,233,182) !important; }

.mdl-color-text--teal-A700 {
  color: rgb(0,191,165) !important; }

.mdl-color--teal-A700 {
  background-color: rgb(0,191,165) !important; }

.mdl-color-text--green {
  color: rgb(76,175,80) !important; }

.mdl-color--green {
  background-color: rgb(76,175,80) !important; }

.mdl-color-text--green-50 {
  color: rgb(232,245,233) !important; }

.mdl-color--green-50 {
  background-color: rgb(232,245,233) !important; }

.mdl-color-text--green-100 {
  color: rgb(200,230,201) !important; }

.mdl-color--green-100 {
  background-color: rgb(200,230,201) !important; }

.mdl-color-text--green-200 {
  color: rgb(165,214,167) !important; }

.mdl-color--green-200 {
  background-color: rgb(165,214,167) !important; }

.mdl-color-text--green-300 {
  color: rgb(129,199,132) !important; }

.mdl-color--green-300 {
  background-color: rgb(129,199,132) !important; }

.mdl-color-text--green-400 {
  color: rgb(102,187,106) !important; }

.mdl-color--green-400 {
  background-color: rgb(102,187,106) !important; }

.mdl-color-text--green-500 {
  color: rgb(76,175,80) !important; }

.mdl-color--green-500 {
  background-color: rgb(76,175,80) !important; }

.mdl-color-text--green-600 {
  color: rgb(67,160,71) !important; }

.mdl-color--green-600 {
  background-color: rgb(67,160,71) !important; }

.mdl-color-text--green-700 {
  color: rgb(56,142,60) !important; }

.mdl-color--green-700 {
  background-color: rgb(56,142,60) !important; }

.mdl-color-text--green-800 {
  color: rgb(46,125,50) !important; }

.mdl-color--green-800 {
  background-color: rgb(46,125,50) !important; }

.mdl-color-text--green-900 {
  color: rgb(27,94,32) !important; }

.mdl-color--green-900 {
  background-color: rgb(27,94,32) !important; }

.mdl-color-text--green-A100 {
  color: rgb(185,246,202) !important; }

.mdl-color--green-A100 {
  background-color: rgb(185,246,202) !important; }

.mdl-color-text--green-A200 {
  color: rgb(105,240,174) !important; }

.mdl-color--green-A200 {
  background-color: rgb(105,240,174) !important; }

.mdl-color-text--green-A400 {
  color: rgb(0,230,118) !important; }

.mdl-color--green-A400 {
  background-color: rgb(0,230,118) !important; }

.mdl-color-text--green-A700 {
  color: rgb(0,200,83) !important; }

.mdl-color--green-A700 {
  background-color: rgb(0,200,83) !important; }

.mdl-color-text--light-green {
  color: rgb(139,195,74) !important; }

.mdl-color--light-green {
  background-color: rgb(139,195,74) !important; }

.mdl-color-text--light-green-50 {
  color: rgb(241,248,233) !important; }

.mdl-color--light-green-50 {
  background-color: rgb(241,248,233) !important; }

.mdl-color-text--light-green-100 {
  color: rgb(220,237,200) !important; }

.mdl-color--light-green-100 {
  background-color: rgb(220,237,200) !important; }

.mdl-color-text--light-green-200 {
  color: rgb(197,225,165) !important; }

.mdl-color--light-green-200 {
  background-color: rgb(197,225,165) !important; }

.mdl-color-text--light-green-300 {
  color: rgb(174,213,129) !important; }

.mdl-color--light-green-300 {
  background-color: rgb(174,213,129) !important; }

.mdl-color-text--light-green-400 {
  color: rgb(156,204,101) !important; }

.mdl-color--light-green-400 {
  background-color: rgb(156,204,101) !important; }

.mdl-color-text--light-green-500 {
  color: rgb(139,195,74) !important; }

.mdl-color--light-green-500 {
  background-color: rgb(139,195,74) !important; }

.mdl-color-text--light-green-600 {
  color: rgb(124,179,66) !important; }

.mdl-color--light-green-600 {
  background-color: rgb(124,179,66) !important; }

.mdl-color-text--light-green-700 {
  color: rgb(104,159,56) !important; }

.mdl-color--light-green-700 {
  background-color: rgb(104,159,56) !important; }

.mdl-color-text--light-green-800 {
  color: rgb(85,139,47) !important; }

.mdl-color--light-green-800 {
  background-color: rgb(85,139,47) !important; }

.mdl-color-text--light-green-900 {
  color: rgb(51,105,30) !important; }

.mdl-color--light-green-900 {
  background-color: rgb(51,105,30) !important; }

.mdl-color-text--light-green-A100 {
  color: rgb(204,255,144) !important; }

.mdl-color--light-green-A100 {
  background-color: rgb(204,255,144) !important; }

.mdl-color-text--light-green-A200 {
  color: rgb(178,255,89) !important; }

.mdl-color--light-green-A200 {
  background-color: rgb(178,255,89) !important; }

.mdl-color-text--light-green-A400 {
  color: rgb(118,255,3) !important; }

.mdl-color--light-green-A400 {
  background-color: rgb(118,255,3) !important; }

.mdl-color-text--light-green-A700 {
  color: rgb(100,221,23) !important; }

.mdl-color--light-green-A700 {
  background-color: rgb(100,221,23) !important; }

.mdl-color-text--lime {
  color: rgb(205,220,57) !important; }

.mdl-color--lime {
  background-color: rgb(205,220,57) !important; }

.mdl-color-text--lime-50 {
  color: rgb(249,251,231) !important; }

.mdl-color--lime-50 {
  background-color: rgb(249,251,231) !important; }

.mdl-color-text--lime-100 {
  color: rgb(240,244,195) !important; }

.mdl-color--lime-100 {
  background-color: rgb(240,244,195) !important; }

.mdl-color-text--lime-200 {
  color: rgb(230,238,156) !important; }

.mdl-color--lime-200 {
  background-color: rgb(230,238,156) !important; }

.mdl-color-text--lime-300 {
  color: rgb(220,231,117) !important; }

.mdl-color--lime-300 {
  background-color: rgb(220,231,117) !important; }

.mdl-color-text--lime-400 {
  color: rgb(212,225,87) !important; }

.mdl-color--lime-400 {
  background-color: rgb(212,225,87) !important; }

.mdl-color-text--lime-500 {
  color: rgb(205,220,57) !important; }

.mdl-color--lime-500 {
  background-color: rgb(205,220,57) !important; }

.mdl-color-text--lime-600 {
  color: rgb(192,202,51) !important; }

.mdl-color--lime-600 {
  background-color: rgb(192,202,51) !important; }

.mdl-color-text--lime-700 {
  color: rgb(175,180,43) !important; }

.mdl-color--lime-700 {
  background-color: rgb(175,180,43) !important; }

.mdl-color-text--lime-800 {
  color: rgb(158,157,36) !important; }

.mdl-color--lime-800 {
  background-color: rgb(158,157,36) !important; }

.mdl-color-text--lime-900 {
  color: rgb(130,119,23) !important; }

.mdl-color--lime-900 {
  background-color: rgb(130,119,23) !important; }

.mdl-color-text--lime-A100 {
  color: rgb(244,255,129) !important; }

.mdl-color--lime-A100 {
  background-color: rgb(244,255,129) !important; }

.mdl-color-text--lime-A200 {
  color: rgb(238,255,65) !important; }

.mdl-color--lime-A200 {
  background-color: rgb(238,255,65) !important; }

.mdl-color-text--lime-A400 {
  color: rgb(198,255,0) !important; }

.mdl-color--lime-A400 {
  background-color: rgb(198,255,0) !important; }

.mdl-color-text--lime-A700 {
  color: rgb(174,234,0) !important; }

.mdl-color--lime-A700 {
  background-color: rgb(174,234,0) !important; }

.mdl-color-text--yellow {
  color: rgb(255,235,59) !important; }

.mdl-color--yellow {
  background-color: rgb(255,235,59) !important; }

.mdl-color-text--yellow-50 {
  color: rgb(255,253,231) !important; }

.mdl-color--yellow-50 {
  background-color: rgb(255,253,231) !important; }

.mdl-color-text--yellow-100 {
  color: rgb(255,249,196) !important; }

.mdl-color--yellow-100 {
  background-color: rgb(255,249,196) !important; }

.mdl-color-text--yellow-200 {
  color: rgb(255,245,157) !important; }

.mdl-color--yellow-200 {
  background-color: rgb(255,245,157) !important; }

.mdl-color-text--yellow-300 {
  color: rgb(255,241,118) !important; }

.mdl-color--yellow-300 {
  background-color: rgb(255,241,118) !important; }

.mdl-color-text--yellow-400 {
  color: rgb(255,238,88) !important; }

.mdl-color--yellow-400 {
  background-color: rgb(255,238,88) !important; }

.mdl-color-text--yellow-500 {
  color: rgb(255,235,59) !important; }

.mdl-color--yellow-500 {
  background-color: rgb(255,235,59) !important; }

.mdl-color-text--yellow-600 {
  color: rgb(253,216,53) !important; }

.mdl-color--yellow-600 {
  background-color: rgb(253,216,53) !important; }

.mdl-color-text--yellow-700 {
  color: rgb(251,192,45) !important; }

.mdl-color--yellow-700 {
  background-color: rgb(251,192,45) !important; }

.mdl-color-text--yellow-800 {
  color: rgb(249,168,37) !important; }

.mdl-color--yellow-800 {
  background-color: rgb(249,168,37) !important; }

.mdl-color-text--yellow-900 {
  color: rgb(245,127,23) !important; }

.mdl-color--yellow-900 {
  background-color: rgb(245,127,23) !important; }

.mdl-color-text--yellow-A100 {
  color: rgb(255,255,141) !important; }

.mdl-color--yellow-A100 {
  background-color: rgb(255,255,141) !important; }

.mdl-color-text--yellow-A200 {
  color: rgb(255,255,0) !important; }

.mdl-color--yellow-A200 {
  background-color: rgb(255,255,0) !important; }

.mdl-color-text--yellow-A400 {
  color: rgb(255,234,0) !important; }

.mdl-color--yellow-A400 {
  background-color: rgb(255,234,0) !important; }

.mdl-color-text--yellow-A700 {
  color: rgb(255,214,0) !important; }

.mdl-color--yellow-A700 {
  background-color: rgb(255,214,0) !important; }

.mdl-color-text--amber {
  color: rgb(255,193,7) !important; }

.mdl-color--amber {
  background-color: rgb(255,193,7) !important; }

.mdl-color-text--amber-50 {
  color: rgb(255,248,225) !important; }

.mdl-color--amber-50 {
  background-color: rgb(255,248,225) !important; }

.mdl-color-text--amber-100 {
  color: rgb(255,236,179) !important; }

.mdl-color--amber-100 {
  background-color: rgb(255,236,179) !important; }

.mdl-color-text--amber-200 {
  color: rgb(255,224,130) !important; }

.mdl-color--amber-200 {
  background-color: rgb(255,224,130) !important; }

.mdl-color-text--amber-300 {
  color: rgb(255,213,79) !important; }

.mdl-color--amber-300 {
  background-color: rgb(255,213,79) !important; }

.mdl-color-text--amber-400 {
  color: rgb(255,202,40) !important; }

.mdl-color--amber-400 {
  background-color: rgb(255,202,40) !important; }

.mdl-color-text--amber-500 {
  color: rgb(255,193,7) !important; }

.mdl-color--amber-500 {
  background-color: rgb(255,193,7) !important; }

.mdl-color-text--amber-600 {
  color: rgb(255,179,0) !important; }

.mdl-color--amber-600 {
  background-color: rgb(255,179,0) !important; }

.mdl-color-text--amber-700 {
  color: rgb(255,160,0) !important; }

.mdl-color--amber-700 {
  background-color: rgb(255,160,0) !important; }

.mdl-color-text--amber-800 {
  color: rgb(255,143,0) !important; }

.mdl-color--amber-800 {
  background-color: rgb(255,143,0) !important; }

.mdl-color-text--amber-900 {
  color: rgb(255,111,0) !important; }

.mdl-color--amber-900 {
  background-color: rgb(255,111,0) !important; }

.mdl-color-text--amber-A100 {
  color: rgb(255,229,127) !important; }

.mdl-color--amber-A100 {
  background-color: rgb(255,229,127) !important; }

.mdl-color-text--amber-A200 {
  color: rgb(255,215,64) !important; }

.mdl-color--amber-A200 {
  background-color: rgb(255,215,64) !important; }

.mdl-color-text--amber-A400 {
  color: rgb(255,196,0) !important; }

.mdl-color--amber-A400 {
  background-color: rgb(255,196,0) !important; }

.mdl-color-text--amber-A700 {
  color: rgb(255,171,0) !important; }

.mdl-color--amber-A700 {
  background-color: rgb(255,171,0) !important; }

.mdl-color-text--orange {
  color: rgb(255,152,0) !important; }

.mdl-color--orange {
  background-color: rgb(255,152,0) !important; }

.mdl-color-text--orange-50 {
  color: rgb(255,243,224) !important; }

.mdl-color--orange-50 {
  background-color: rgb(255,243,224) !important; }

.mdl-color-text--orange-100 {
  color: rgb(255,224,178) !important; }

.mdl-color--orange-100 {
  background-color: rgb(255,224,178) !important; }

.mdl-color-text--orange-200 {
  color: rgb(255,204,128) !important; }

.mdl-color--orange-200 {
  background-color: rgb(255,204,128) !important; }

.mdl-color-text--orange-300 {
  color: rgb(255,183,77) !important; }

.mdl-color--orange-300 {
  background-color: rgb(255,183,77) !important; }

.mdl-color-text--orange-400 {
  color: rgb(255,167,38) !important; }

.mdl-color--orange-400 {
  background-color: rgb(255,167,38) !important; }

.mdl-color-text--orange-500 {
  color: rgb(255,152,0) !important; }

.mdl-color--orange-500 {
  background-color: rgb(255,152,0) !important; }

.mdl-color-text--orange-600 {
  color: rgb(251,140,0) !important; }

.mdl-color--orange-600 {
  background-color: rgb(251,140,0) !important; }

.mdl-color-text--orange-700 {
  color: rgb(245,124,0) !important; }

.mdl-color--orange-700 {
  background-color: rgb(245,124,0) !important; }

.mdl-color-text--orange-800 {
  color: rgb(239,108,0) !important; }

.mdl-color--orange-800 {
  background-color: rgb(239,108,0) !important; }

.mdl-color-text--orange-900 {
  color: rgb(230,81,0) !important; }

.mdl-color--orange-900 {
  background-color: rgb(230,81,0) !important; }

.mdl-color-text--orange-A100 {
  color: rgb(255,209,128) !important; }

.mdl-color--orange-A100 {
  background-color: rgb(255,209,128) !important; }

.mdl-color-text--orange-A200 {
  color: rgb(255,171,64) !important; }

.mdl-color--orange-A200 {
  background-color: rgb(255,171,64) !important; }

.mdl-color-text--orange-A400 {
  color: rgb(255,145,0) !important; }

.mdl-color--orange-A400 {
  background-color: rgb(255,145,0) !important; }

.mdl-color-text--orange-A700 {
  color: rgb(255,109,0) !important; }

.mdl-color--orange-A700 {
  background-color: rgb(255,109,0) !important; }

.mdl-color-text--deep-orange {
  color: rgb(255,87,34) !important; }

.mdl-color--deep-orange {
  background-color: rgb(255,87,34) !important; }

.mdl-color-text--deep-orange-50 {
  color: rgb(251,233,231) !important; }

.mdl-color--deep-orange-50 {
  background-color: rgb(251,233,231) !important; }

.mdl-color-text--deep-orange-100 {
  color: rgb(255,204,188) !important; }

.mdl-color--deep-orange-100 {
  background-color: rgb(255,204,188) !important; }

.mdl-color-text--deep-orange-200 {
  color: rgb(255,171,145) !important; }

.mdl-color--deep-orange-200 {
  background-color: rgb(255,171,145) !important; }

.mdl-color-text--deep-orange-300 {
  color: rgb(255,138,101) !important; }

.mdl-color--deep-orange-300 {
  background-color: rgb(255,138,101) !important; }

.mdl-color-text--deep-orange-400 {
  color: rgb(255,112,67) !important; }

.mdl-color--deep-orange-400 {
  background-color: rgb(255,112,67) !important; }

.mdl-color-text--deep-orange-500 {
  color: rgb(255,87,34) !important; }

.mdl-color--deep-orange-500 {
  background-color: rgb(255,87,34) !important; }

.mdl-color-text--deep-orange-600 {
  color: rgb(244,81,30) !important; }

.mdl-color--deep-orange-600 {
  background-color: rgb(244,81,30) !important; }

.mdl-color-text--deep-orange-700 {
  color: rgb(230,74,25) !important; }

.mdl-color--deep-orange-700 {
  background-color: rgb(230,74,25) !important; }

.mdl-color-text--deep-orange-800 {
  color: rgb(216,67,21) !important; }

.mdl-color--deep-orange-800 {
  background-color: rgb(216,67,21) !important; }

.mdl-color-text--deep-orange-900 {
  color: rgb(191,54,12) !important; }

.mdl-color--deep-orange-900 {
  background-color: rgb(191,54,12) !important; }

.mdl-color-text--deep-orange-A100 {
  color: rgb(255,158,128) !important; }

.mdl-color--deep-orange-A100 {
  background-color: rgb(255,158,128) !important; }

.mdl-color-text--deep-orange-A200 {
  color: rgb(255,110,64) !important; }

.mdl-color--deep-orange-A200 {
  background-color: rgb(255,110,64) !important; }

.mdl-color-text--deep-orange-A400 {
  color: rgb(255,61,0) !important; }

.mdl-color--deep-orange-A400 {
  background-color: rgb(255,61,0) !important; }

.mdl-color-text--deep-orange-A700 {
  color: rgb(221,44,0) !important; }

.mdl-color--deep-orange-A700 {
  background-color: rgb(221,44,0) !important; }

.mdl-color-text--brown {
  color: rgb(121,85,72) !important; }

.mdl-color--brown {
  background-color: rgb(121,85,72) !important; }

.mdl-color-text--brown-50 {
  color: rgb(239,235,233) !important; }

.mdl-color--brown-50 {
  background-color: rgb(239,235,233) !important; }

.mdl-color-text--brown-100 {
  color: rgb(215,204,200) !important; }

.mdl-color--brown-100 {
  background-color: rgb(215,204,200) !important; }

.mdl-color-text--brown-200 {
  color: rgb(188,170,164) !important; }

.mdl-color--brown-200 {
  background-color: rgb(188,170,164) !important; }

.mdl-color-text--brown-300 {
  color: rgb(161,136,127) !important; }

.mdl-color--brown-300 {
  background-color: rgb(161,136,127) !important; }

.mdl-color-text--brown-400 {
  color: rgb(141,110,99) !important; }

.mdl-color--brown-400 {
  background-color: rgb(141,110,99) !important; }

.mdl-color-text--brown-500 {
  color: rgb(121,85,72) !important; }

.mdl-color--brown-500 {
  background-color: rgb(121,85,72) !important; }

.mdl-color-text--brown-600 {
  color: rgb(109,76,65) !important; }

.mdl-color--brown-600 {
  background-color: rgb(109,76,65) !important; }

.mdl-color-text--brown-700 {
  color: rgb(93,64,55) !important; }

.mdl-color--brown-700 {
  background-color: rgb(93,64,55) !important; }

.mdl-color-text--brown-800 {
  color: rgb(78,52,46) !important; }

.mdl-color--brown-800 {
  background-color: rgb(78,52,46) !important; }

.mdl-color-text--brown-900 {
  color: rgb(62,39,35) !important; }

.mdl-color--brown-900 {
  background-color: rgb(62,39,35) !important; }

.mdl-color-text--grey {
  color: rgb(158,158,158) !important; }

.mdl-color--grey {
  background-color: rgb(158,158,158) !important; }

.mdl-color-text--grey-50 {
  color: rgb(250,250,250) !important; }

.mdl-color--grey-50 {
  background-color: rgb(250,250,250) !important; }

.mdl-color-text--grey-100 {
  color: rgb(245,245,245) !important; }

.mdl-color--grey-100 {
  background-color: rgb(245,245,245) !important; }

.mdl-color-text--grey-200 {
  color: rgb(238,238,238) !important; }

.mdl-color--grey-200 {
  background-color: rgb(238,238,238) !important; }

.mdl-color-text--grey-300 {
  color: rgb(224,224,224) !important; }

.mdl-color--grey-300 {
  background-color: rgb(224,224,224) !important; }

.mdl-color-text--grey-400 {
  color: rgb(189,189,189) !important; }

.mdl-color--grey-400 {
  background-color: rgb(189,189,189) !important; }

.mdl-color-text--grey-500 {
  color: rgb(158,158,158) !important; }

.mdl-color--grey-500 {
  background-color: rgb(158,158,158) !important; }

.mdl-color-text--grey-600 {
  color: rgb(117,117,117) !important; }

.mdl-color--grey-600 {
  background-color: rgb(117,117,117) !important; }

.mdl-color-text--grey-700 {
  color: rgb(97,97,97) !important; }

.mdl-color--grey-700 {
  background-color: rgb(97,97,97) !important; }

.mdl-color-text--grey-800 {
  color: rgb(66,66,66) !important; }

.mdl-color--grey-800 {
  background-color: rgb(66,66,66) !important; }

.mdl-color-text--grey-900 {
  color: rgb(33,33,33) !important; }

.mdl-color--grey-900 {
  background-color: rgb(33,33,33) !important; }

.mdl-color-text--blue-grey {
  color: rgb(96,125,139) !important; }

.mdl-color--blue-grey {
  background-color: rgb(96,125,139) !important; }

.mdl-color-text--blue-grey-50 {
  color: rgb(236,239,241) !important; }

.mdl-color--blue-grey-50 {
  background-color: rgb(236,239,241) !important; }

.mdl-color-text--blue-grey-100 {
  color: rgb(207,216,220) !important; }

.mdl-color--blue-grey-100 {
  background-color: rgb(207,216,220) !important; }

.mdl-color-text--blue-grey-200 {
  color: rgb(176,190,197) !important; }

.mdl-color--blue-grey-200 {
  background-color: rgb(176,190,197) !important; }

.mdl-color-text--blue-grey-300 {
  color: rgb(144,164,174) !important; }

.mdl-color--blue-grey-300 {
  background-color: rgb(144,164,174) !important; }

.mdl-color-text--blue-grey-400 {
  color: rgb(120,144,156) !important; }

.mdl-color--blue-grey-400 {
  background-color: rgb(120,144,156) !important; }

.mdl-color-text--blue-grey-500 {
  color: rgb(96,125,139) !important; }

.mdl-color--blue-grey-500 {
  background-color: rgb(96,125,139) !important; }

.mdl-color-text--blue-grey-600 {
  color: rgb(84,110,122) !important; }

.mdl-color--blue-grey-600 {
  background-color: rgb(84,110,122) !important; }

.mdl-color-text--blue-grey-700 {
  color: rgb(69,90,100) !important; }

.mdl-color--blue-grey-700 {
  background-color: rgb(69,90,100) !important; }

.mdl-color-text--blue-grey-800 {
  color: rgb(55,71,79) !important; }

.mdl-color--blue-grey-800 {
  background-color: rgb(55,71,79) !important; }

.mdl-color-text--blue-grey-900 {
  color: rgb(38,50,56) !important; }

.mdl-color--blue-grey-900 {
  background-color: rgb(38,50,56) !important; }

.mdl-color--black {
  background-color: rgb(0,0,0) !important; }

.mdl-color-text--black {
  color: rgb(0,0,0) !important; }

.mdl-color--white {
  background-color: rgb(255,255,255) !important; }

.mdl-color-text--white {
  color: rgb(255,255,255) !important; }

.mdl-color--primary {
  background-color: rgb(63,81,181) !important; }

.mdl-color--primary-contrast {
  background-color: rgb(255,255,255) !important; }

.mdl-color--primary-dark {
  background-color: rgb(48,63,159) !important; }

.mdl-color--accent {
  background-color: rgb(255,64,129) !important; }

.mdl-color--accent-contrast {
  background-color: rgb(255,255,255) !important; }

.mdl-color-text--primary {
  color: rgb(63,81,181) !important; }

.mdl-color-text--primary-contrast {
  color: rgb(255,255,255) !important; }

.mdl-color-text--primary-dark {
  color: rgb(48,63,159) !important; }

.mdl-color-text--accent {
  color: rgb(255,64,129) !important; }

.mdl-color-text--accent-contrast {
  color: rgb(255,255,255) !important; }

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* TOOLTIP */
.mdl-ripple {
  background: rgb(0,0,0);
  border-radius: 50%;
  height: 50px;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  transform: translate(-50%, -50%);
  width: 50px;
  overflow: hidden; }
  .mdl-ripple.is-animating {
    transition: transform 0.3s cubic-bezier(0, 0, 0.2, 1), width 0.3s cubic-bezier(0, 0, 0.2, 1), height 0.3s cubic-bezier(0, 0, 0.2, 1), opacity 0.6s cubic-bezier(0, 0, 0.2, 1); }
  .mdl-ripple.is-visible {
    opacity: 0.3; }

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* TOOLTIP */
.mdl-animation--default {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1); }

.mdl-animation--fast-out-slow-in {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1); }

.mdl-animation--linear-out-slow-in {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1); }

.mdl-animation--fast-out-linear-in {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1); }

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* TOOLTIP */
.mdl-badge {
  position: relative;
  white-space: nowrap;
  margin-right: 24px; }
  .mdl-badge:not([data-badge]) {
    margin-right: auto; }
  .mdl-badge[data-badge]:after {
    content: attr(data-badge);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    position: absolute;
    top: -11px;
    right: -24px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 600;
    font-size: 12px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background: rgb(255,64,129);
    color: rgb(255,255,255); }
    .mdl-button .mdl-badge[data-badge]:after {
      top: -10px;
      right: -5px; }
  .mdl-badge.mdl-badge--no-background[data-badge]:after {
    color: rgb(255,64,129);
    background: rgb(255,255,255);
    box-shadow: 0 0 1px gray; }

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* TOOLTIP */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
.mdl-button {
  background: transparent;
  border: none;
  border-radius: 2px;
  color: rgb(0,0,0);
  position: relative;
  height: 36px;
  min-width: 64px;
  padding: 0 16px;
  display: inline-block;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1;
  letter-spacing: 0;
  overflow: hidden;
  will-change: box-shadow, transform;
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1), color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  outline: none;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  line-height: 36px;
  vertical-align: middle; }
  .mdl-button::-moz-focus-inner {
    border: 0; }
  .mdl-button:hover {
    background-color: rgba(158,158,158, 0.20); }
  .mdl-button:focus:not(:active) {
    background-color: rgba(0,0,0, 0.12); }
  .mdl-button:active {
    background-color: rgba(158,158,158, 0.40); }
  .mdl-button.mdl-button--colored {
    color: rgb(63,81,181); }
    .mdl-button.mdl-button--colored:focus:not(:active) {
      background-color: rgba(0,0,0, 0.12); }

input.mdl-button[type="submit"] {
  -webkit-appearance: none; }

.mdl-button--raised {
  background: rgba(158,158,158, 0.20);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12); }
  .mdl-button--raised:active {
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
    background-color: rgba(158,158,158, 0.40); }
  .mdl-button--raised:focus:not(:active) {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.18), 0 8px 16px rgba(0, 0, 0, 0.36);
    background-color: rgba(158,158,158, 0.40); }
  .mdl-button--raised.mdl-button--colored {
    background: rgb(63,81,181);
    color: rgb(255,255,255); }
    .mdl-button--raised.mdl-button--colored:hover {
      background-color: rgb(63,81,181); }
    .mdl-button--raised.mdl-button--colored:active {
      background-color: rgb(63,81,181); }
    .mdl-button--raised.mdl-button--colored:focus:not(:active) {
      background-color: rgb(63,81,181); }
    .mdl-button--raised.mdl-button--colored .mdl-ripple {
      background: rgb(255,255,255); }

.mdl-button--fab {
  border-radius: 50%;
  font-size: 24px;
  height: 56px;
  margin: auto;
  min-width: 56px;
  width: 56px;
  padding: 0;
  overflow: hidden;
  background: rgba(158,158,158, 0.20);
  box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.24);
  position: relative;
  line-height: normal; }
  .mdl-button--fab .material-icons {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-12px, -12px);
    line-height: 24px;
    width: 24px; }
  .mdl-button--fab.mdl-button--mini-fab {
    height: 40px;
    min-width: 40px;
    width: 40px; }
  .mdl-button--fab .mdl-button__ripple-container {
    border-radius: 50%;
    -webkit-mask-image: -webkit-radial-gradient(circle, white, black); }
  .mdl-button--fab:active {
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
    background-color: rgba(158,158,158, 0.40); }
  .mdl-button--fab:focus:not(:active) {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.18), 0 8px 16px rgba(0, 0, 0, 0.36);
    background-color: rgba(158,158,158, 0.40); }
  .mdl-button--fab.mdl-button--colored {
    background: rgb(255,64,129);
    color: rgb(255,255,255); }
    .mdl-button--fab.mdl-button--colored:hover {
      background-color: rgb(255,64,129); }
    .mdl-button--fab.mdl-button--colored:focus:not(:active) {
      background-color: rgb(255,64,129); }
    .mdl-button--fab.mdl-button--colored:active {
      background-color: rgb(255,64,129); }
    .mdl-button--fab.mdl-button--colored .mdl-ripple {
      background: rgb(255,255,255); }

.mdl-button--icon {
  border-radius: 50%;
  font-size: 24px;
  height: 32px;
  margin-left: 0;
  margin-right: 0;
  min-width: 32px;
  width: 32px;
  padding: 0;
  overflow: hidden;
  color: inherit;
  line-height: normal; }
  .mdl-button--icon .material-icons {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-12px, -12px);
    line-height: 24px;
    width: 24px; }
  .mdl-button--icon.mdl-button--mini-icon {
    height: 24px;
    min-width: 24px;
    width: 24px; }
    .mdl-button--icon.mdl-button--mini-icon .material-icons {
      top: 0px;
      left: 0px; }
  .mdl-button--icon .mdl-button__ripple-container {
    border-radius: 50%;
    -webkit-mask-image: -webkit-radial-gradient(circle, white, black); }

.mdl-button__ripple-container {
  display: block;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: 0;
  overflow: hidden; }
  .mdl-button[disabled] .mdl-button__ripple-container .mdl-ripple,
  .mdl-button.mdl-button--disabled .mdl-button__ripple-container .mdl-ripple {
    background-color: transparent; }

.mdl-button--primary.mdl-button--primary {
  color: rgb(63,81,181); }
  .mdl-button--primary.mdl-button--primary .mdl-ripple {
    background: rgb(255,255,255); }
  .mdl-button--primary.mdl-button--primary.mdl-button--raised, .mdl-button--primary.mdl-button--primary.mdl-button--fab {
    color: rgb(255,255,255);
    background-color: rgb(63,81,181); }

.mdl-button--accent.mdl-button--accent {
  color: rgb(255,64,129); }
  .mdl-button--accent.mdl-button--accent .mdl-ripple {
    background: rgb(255,255,255); }
  .mdl-button--accent.mdl-button--accent.mdl-button--raised, .mdl-button--accent.mdl-button--accent.mdl-button--fab {
    color: rgb(255,255,255);
    background-color: rgb(255,64,129); }

.mdl-button[disabled][disabled], .mdl-button.mdl-button--disabled.mdl-button--disabled {
  color: rgba(0,0,0, 0.26);
  cursor: default;
  background-color: transparent; }

.mdl-button--fab[disabled][disabled], .mdl-button--fab.mdl-button--disabled.mdl-button--disabled {
  background-color: rgba(0,0,0, 0.12);
  color: rgba(0,0,0, 0.26);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12); }

.mdl-button--raised[disabled][disabled], .mdl-button--raised.mdl-button--disabled.mdl-button--disabled {
  background-color: rgba(0,0,0, 0.12);
  color: rgba(0,0,0, 0.26);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12); }

.mdl-button--colored[disabled][disabled], .mdl-button--colored.mdl-button--disabled.mdl-button--disabled {
  color: rgba(0,0,0, 0.26); }

.mdl-button .material-icons {
  vertical-align: middle; }

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* TOOLTIP */
.mdl-card {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 400;
  min-height: 200px;
  overflow: hidden;
  width: 330px;
  z-index: 1;
  position: relative;
  background: rgb(255,255,255);
  border-radius: 2px;
  box-sizing: border-box; }

.mdl-card__media {
  background-color: rgb(255,64,129);
  background-repeat: repeat;
  background-position: 50% 50%;
  background-size: cover;
  background-origin: padding-box;
  background-attachment: scroll;
  box-sizing: border-box; }

.mdl-card__title {
  align-items: center;
  color: rgb(0,0,0);
  display: block;
  display: flex;
  justify-content: stretch;
  line-height: normal;
  padding: 16px 16px;
  perspective-origin: 165px 56px;
  transform-origin: 165px 56px;
  box-sizing: border-box; }
  .mdl-card__title.mdl-card--border {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); }

.mdl-card__title-text {
  align-self: flex-end;
  color: inherit;
  display: block;
  display: flex;
  font-size: 24px;
  font-weight: 300;
  line-height: normal;
  overflow: hidden;
  transform-origin: 149px 48px;
  margin: 0; }

.mdl-card__subtitle-text {
  font-size: 14px;
  color: rgba(0,0,0, 0.54);
  margin: 0; }

.mdl-card__supporting-text {
  color: rgba(0,0,0, 0.54);
  font-size: 13px;
  line-height: 18px;
  overflow: hidden;
  padding: 16px 16px;
  width: 90%; }

.mdl-card__actions {
  font-size: 16px;
  line-height: normal;
  width: 100%;
  background-color: transparent;
  padding: 8px;
  box-sizing: border-box; }
  .mdl-card__actions.mdl-card--border {
    border-top: 1px solid rgba(0, 0, 0, 0.1); }

.mdl-card--expand {
  flex-grow: 1; }

.mdl-card__menu {
  position: absolute;
  right: 16px;
  top: 16px; }

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* TOOLTIP */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
.mdl-checkbox {
  position: relative;
  z-index: 1;
  vertical-align: middle;
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  height: 24px;
  margin: 0;
  padding: 0; }
  .mdl-checkbox.is-upgraded {
    padding-left: 24px; }

.mdl-checkbox__input {
  line-height: 24px; }
  .mdl-checkbox.is-upgraded .mdl-checkbox__input {
    position: absolute;
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
    -ms-appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border: none; }

.mdl-checkbox__box-outline {
  position: absolute;
  top: 3px;
  left: 0;
  display: inline-block;
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  margin: 0;
  cursor: pointer;
  overflow: hidden;
  border: 2px solid rgba(0,0,0, 0.54);
  border-radius: 2px;
  z-index: 2; }
  .mdl-checkbox.is-checked .mdl-checkbox__box-outline {
    border: 2px solid rgb(63,81,181); }
  .mdl-checkbox.is-disabled .mdl-checkbox__box-outline {
    border: 2px solid rgba(0,0,0, 0.26);
    cursor: auto; }

.mdl-checkbox__focus-helper {
  position: absolute;
  top: 3px;
  left: 0;
  display: inline-block;
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: transparent; }
  .mdl-checkbox.is-focused .mdl-checkbox__focus-helper {
    box-shadow: 0 0 0px 8px rgba(0, 0, 0, 0.1);
    background-color: rgba(0, 0, 0, 0.1); }
  .mdl-checkbox.is-focused.is-checked .mdl-checkbox__focus-helper {
    box-shadow: 0 0 0px 8px rgba(63,81,181, 0.26);
    background-color: rgba(63,81,181, 0.26); }

.mdl-checkbox__tick-outline {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  mask: url("../img/mdl/tick-mask.svg?embed");
  background: transparent;
  transition-duration: 0.28s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: background; }
  .mdl-checkbox.is-checked .mdl-checkbox__tick-outline {
    background: rgb(63,81,181) url("../img/mdl/tick.svg?embed"); }
  .mdl-checkbox.is-checked.is-disabled .mdl-checkbox__tick-outline {
    background: rgba(0,0,0, 0.26) url("../img/mdl/tick.svg?embed"); }

.mdl-checkbox__label {
  position: relative;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  margin: 0; }
  .mdl-checkbox.is-disabled .mdl-checkbox__label {
    color: rgba(0,0,0, 0.26);
    cursor: auto; }

.mdl-checkbox__ripple-container {
  position: absolute;
  z-index: 2;
  top: -6px;
  left: -10px;
  box-sizing: border-box;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  cursor: pointer;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(circle, white, black); }
  .mdl-checkbox__ripple-container .mdl-ripple {
    background: rgb(63,81,181); }
  .mdl-checkbox.is-disabled .mdl-checkbox__ripple-container {
    cursor: auto; }
  .mdl-checkbox.is-disabled .mdl-checkbox__ripple-container .mdl-ripple {
    background: transparent; }

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* TOOLTIP */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
.mdl-data-table {
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-collapse: collapse;
  white-space: nowrap;
  font-size: 13px;
  background-color: rgb(255,255,255); }
  .mdl-data-table thead {
    padding-bottom: 3px; }
    .mdl-data-table thead .mdl-data-table__select {
      margin-top: 0; }
  .mdl-data-table tbody tr {
    position: relative;
    height: 48px;
    transition-duration: 0.28s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-property: background-color; }
    .mdl-data-table tbody tr.is-selected {
      background-color: #e0e0e0; }
    .mdl-data-table tbody tr:hover {
      background-color: #eeeeee; }
  .mdl-data-table td, .mdl-data-table th {
    padding: 0 18px 0 18px;
    text-align: right; }
    .mdl-data-table td:first-of-type, .mdl-data-table th:first-of-type {
      padding-left: 24px; }
    .mdl-data-table td:last-of-type, .mdl-data-table th:last-of-type {
      padding-right: 24px; }
  .mdl-data-table td {
    position: relative;
    vertical-align: top;
    height: 48px;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding-top: 12px;
    box-sizing: border-box; }
    .mdl-data-table td .mdl-data-table__select {
      vertical-align: top;
      position: absolute;
      left: 24px; }
  .mdl-data-table th {
    position: relative;
    vertical-align: bottom;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: bold;
    line-height: 24px;
    letter-spacing: 0;
    height: 48px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.54);
    padding-bottom: 8px;
    box-sizing: border-box; }
    .mdl-data-table th .mdl-data-table__select {
      position: absolute;
      bottom: 8px;
      left: 24px; }

.mdl-data-table__select {
  width: 16px; }

.mdl-data-table__cell--non-numeric.mdl-data-table__cell--non-numeric {
  text-align: left; }

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* TOOLTIP */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
.mdl-mega-footer {
  padding: 16px 40px;
  color: rgb(158,158,158);
  background-color: rgb(66,66,66); }

.mdl-mega-footer--top-section:after,
.mdl-mega-footer--middle-section:after,
.mdl-mega-footer--bottom-section:after,
.mdl-mega-footer__top-section:after,
.mdl-mega-footer__middle-section:after,
.mdl-mega-footer__bottom-section:after {
  content: '';
  display: block;
  clear: both; }

.mdl-mega-footer--left-section,
.mdl-mega-footer__left-section {
  margin-bottom: 16px; }

.mdl-mega-footer--right-section,
.mdl-mega-footer__right-section {
  margin-bottom: 16px; }

.mdl-mega-footer--right-section a,
.mdl-mega-footer__right-section a {
  display: block;
  margin-bottom: 16px;
  color: inherit;
  text-decoration: none; }

@media screen and (min-width: 760px) {
  .mdl-mega-footer--left-section,
  .mdl-mega-footer__left-section {
    float: left; }
  .mdl-mega-footer--right-section,
  .mdl-mega-footer__right-section {
    float: right; }
  .mdl-mega-footer--right-section a,
  .mdl-mega-footer__right-section a {
    display: inline-block;
    margin-left: 16px;
    line-height: 36px;
    vertical-align: middle; } }

.mdl-mega-footer--social-btn,
.mdl-mega-footer__social-btn {
  width: 36px;
  height: 36px;
  padding: 0;
  margin: 0;
  background-color: rgb(158,158,158);
  border: none; }

.mdl-mega-footer--drop-down-section,
.mdl-mega-footer__drop-down-section {
  display: block;
  position: relative; }

@media screen and (min-width: 760px) {
  .mdl-mega-footer--drop-down-section,
  .mdl-mega-footer__drop-down-section {
    width: 33%; }
  .mdl-mega-footer--drop-down-section:nth-child(1),
  .mdl-mega-footer--drop-down-section:nth-child(2),
  .mdl-mega-footer__drop-down-section:nth-child(1),
  .mdl-mega-footer__drop-down-section:nth-child(2) {
    float: left; }
  .mdl-mega-footer--drop-down-section:nth-child(3),
  .mdl-mega-footer__drop-down-section:nth-child(3) {
    float: right; }
    .mdl-mega-footer--drop-down-section:nth-child(3):after,
    .mdl-mega-footer__drop-down-section:nth-child(3):after {
      clear: right; }
  .mdl-mega-footer--drop-down-section:nth-child(4),
  .mdl-mega-footer__drop-down-section:nth-child(4) {
    clear: right;
    float: right; }
  .mdl-mega-footer--middle-section:after,
  .mdl-mega-footer__middle-section:after {
    content: '';
    display: block;
    clear: both; }
  .mdl-mega-footer--bottom-section,
  .mdl-mega-footer__bottom-section {
    padding-top: 0; } }

@media screen and (min-width: 1024px) {
  .mdl-mega-footer--drop-down-section,
  .mdl-mega-footer--drop-down-section:nth-child(3),
  .mdl-mega-footer--drop-down-section:nth-child(4),
  .mdl-mega-footer__drop-down-section,
  .mdl-mega-footer__drop-down-section:nth-child(3),
  .mdl-mega-footer__drop-down-section:nth-child(4) {
    width: 24%;
    float: left; } }

.mdl-mega-footer--heading-checkbox,
.mdl-mega-footer__heading-checkbox {
  position: absolute;
  width: 100%;
  height: 55.8px;
  padding: 32px;
  margin: 0;
  margin-top: -16px;
  cursor: pointer;
  z-index: 1;
  opacity: 0; }
  .mdl-mega-footer--heading-checkbox + .mdl-mega-footer--heading:after,
  .mdl-mega-footer--heading-checkbox + .mdl-mega-footer__heading:after,
  .mdl-mega-footer__heading-checkbox + .mdl-mega-footer--heading:after,
  .mdl-mega-footer__heading-checkbox + .mdl-mega-footer__heading:after {
    font-family: 'Material Icons';
    content: '\E5CE'; }

.mdl-mega-footer--heading-checkbox:checked ~ .mdl-mega-footer--link-list,
.mdl-mega-footer--heading-checkbox:checked ~ .mdl-mega-footer__link-list,
.mdl-mega-footer--heading-checkbox:checked + .mdl-mega-footer--heading + .mdl-mega-footer--link-list,
.mdl-mega-footer--heading-checkbox:checked + .mdl-mega-footer__heading + .mdl-mega-footer__link-list,
.mdl-mega-footer__heading-checkbox:checked ~ .mdl-mega-footer--link-list,
.mdl-mega-footer__heading-checkbox:checked ~ .mdl-mega-footer__link-list,
.mdl-mega-footer__heading-checkbox:checked + .mdl-mega-footer--heading + .mdl-mega-footer--link-list,
.mdl-mega-footer__heading-checkbox:checked + .mdl-mega-footer__heading + .mdl-mega-footer__link-list {
  display: none; }

.mdl-mega-footer--heading-checkbox:checked + .mdl-mega-footer--heading:after,
.mdl-mega-footer--heading-checkbox:checked + .mdl-mega-footer__heading:after,
.mdl-mega-footer__heading-checkbox:checked + .mdl-mega-footer--heading:after,
.mdl-mega-footer__heading-checkbox:checked + .mdl-mega-footer__heading:after {
  font-family: 'Material Icons';
  content: '\E5CF'; }

.mdl-mega-footer--heading,
.mdl-mega-footer__heading {
  position: relative;
  width: 100%;
  padding-right: 39.8px;
  margin-bottom: 16px;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 23.8px;
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: rgb(224,224,224); }

.mdl-mega-footer--heading:after,
.mdl-mega-footer__heading:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 23.8px;
  height: 23.8px;
  background-size: cover; }

.mdl-mega-footer--link-list,
.mdl-mega-footer__link-list {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 32px; }
  .mdl-mega-footer--link-list:after,
  .mdl-mega-footer__link-list:after {
    clear: both;
    display: block;
    content: ''; }

.mdl-mega-footer--link-list li,
.mdl-mega-footer__link-list li {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  line-height: 20px; }

.mdl-mega-footer--link-list a,
.mdl-mega-footer__link-list a {
  color: inherit;
  text-decoration: none;
  white-space: nowrap; }

@media screen and (min-width: 760px) {
  .mdl-mega-footer--heading-checkbox,
  .mdl-mega-footer__heading-checkbox {
    display: none; }
    .mdl-mega-footer--heading-checkbox + .mdl-mega-footer--heading:after,
    .mdl-mega-footer--heading-checkbox + .mdl-mega-footer__heading:after,
    .mdl-mega-footer__heading-checkbox + .mdl-mega-footer--heading:after,
    .mdl-mega-footer__heading-checkbox + .mdl-mega-footer__heading:after {
      background-image: none; }
  .mdl-mega-footer--heading-checkbox:checked ~ .mdl-mega-footer--link-list,
  .mdl-mega-footer--heading-checkbox:checked ~ .mdl-mega-footer__link-list,
  .mdl-mega-footer--heading-checkbox:checked + .mdl-mega-footer__heading + .mdl-mega-footer__link-list,
  .mdl-mega-footer--heading-checkbox:checked + .mdl-mega-footer--heading + .mdl-mega-footer--link-list,
  .mdl-mega-footer__heading-checkbox:checked ~ .mdl-mega-footer--link-list,
  .mdl-mega-footer__heading-checkbox:checked ~ .mdl-mega-footer__link-list,
  .mdl-mega-footer__heading-checkbox:checked + .mdl-mega-footer__heading + .mdl-mega-footer__link-list,
  .mdl-mega-footer__heading-checkbox:checked + .mdl-mega-footer--heading + .mdl-mega-footer--link-list {
    display: block; }
  .mdl-mega-footer--heading-checkbox:checked + .mdl-mega-footer--heading:after,
  .mdl-mega-footer--heading-checkbox:checked + .mdl-mega-footer__heading:after,
  .mdl-mega-footer__heading-checkbox:checked + .mdl-mega-footer--heading:after,
  .mdl-mega-footer__heading-checkbox:checked + .mdl-mega-footer__heading:after {
    content: ''; } }

.mdl-mega-footer--bottom-section,
.mdl-mega-footer__bottom-section {
  padding-top: 16px;
  margin-bottom: 16px; }

.mdl-logo {
  margin-bottom: 16px;
  color: white; }

.mdl-mega-footer--bottom-section .mdl-mega-footer--link-list li,
.mdl-mega-footer__bottom-section .mdl-mega-footer__link-list li {
  float: left;
  margin-bottom: 0;
  margin-right: 16px; }

@media screen and (min-width: 760px) {
  .mdl-logo {
    float: left;
    margin-bottom: 0;
    margin-right: 16px; } }

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* TOOLTIP */
.mdl-mini-footer {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 32px 16px;
  color: rgb(158,158,158);
  background-color: rgb(66,66,66); }
  .mdl-mini-footer:after {
    content: '';
    display: block; }
  .mdl-mini-footer .mdl-logo {
    line-height: 36px; }

.mdl-mini-footer--link-list,
.mdl-mini-footer__link-list {
  display: flex;
  flex-flow: row nowrap;
  list-style: none;
  margin: 0;
  padding: 0; }
  .mdl-mini-footer--link-list li,
  .mdl-mini-footer__link-list li {
    margin-bottom: 0;
    margin-right: 16px; }
    @media screen and (min-width: 760px) {
      .mdl-mini-footer--link-list li,
      .mdl-mini-footer__link-list li {
        line-height: 36px; } }
  .mdl-mini-footer--link-list a,
  .mdl-mini-footer__link-list a {
    color: inherit;
    text-decoration: none;
    white-space: nowrap; }

.mdl-mini-footer--left-section,
.mdl-mini-footer__left-section {
  display: inline-block;
  order: 0; }

.mdl-mini-footer--right-section,
.mdl-mini-footer__right-section {
  display: inline-block;
  order: 1; }

.mdl-mini-footer--social-btn,
.mdl-mini-footer__social-btn {
  width: 36px;
  height: 36px;
  padding: 0;
  margin: 0;
  background-color: rgb(158,158,158);
  border: none; }

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* TOOLTIP */
.mdl-icon-toggle {
  position: relative;
  z-index: 1;
  vertical-align: middle;
  display: inline-block;
  height: 32px;
  margin: 0;
  padding: 0; }

.mdl-icon-toggle__input {
  line-height: 32px; }
  .mdl-icon-toggle.is-upgraded .mdl-icon-toggle__input {
    position: absolute;
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
    -ms-appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border: none; }

.mdl-icon-toggle__label {
  display: inline-block;
  position: relative;
  cursor: pointer;
  height: 32px;
  width: 32px;
  min-width: 32px;
  color: rgb(97,97,97);
  border-radius: 50%;
  padding: 0;
  margin-left: 0;
  margin-right: 0;
  text-align: center;
  background-color: transparent;
  will-change: background-color;
  transition: background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1), color 0.2s cubic-bezier(0.4, 0, 0.2, 1); }
  .mdl-icon-toggle__label.material-icons {
    line-height: 32px;
    font-size: 24px; }
  .mdl-icon-toggle.is-checked .mdl-icon-toggle__label {
    color: rgb(63,81,181); }
  .mdl-icon-toggle.is-disabled .mdl-icon-toggle__label {
    color: rgba(0,0,0, 0.26);
    cursor: auto;
    transition: none; }
  .mdl-icon-toggle.is-focused .mdl-icon-toggle__label {
    background-color: rgba(0,0,0, 0.12); }
  .mdl-icon-toggle.is-focused.is-checked .mdl-icon-toggle__label {
    background-color: rgba(63,81,181, 0.26); }

.mdl-icon-toggle__ripple-container {
  position: absolute;
  z-index: 2;
  top: -2px;
  left: -2px;
  box-sizing: border-box;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  cursor: pointer;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(circle, white, black); }
  .mdl-icon-toggle__ripple-container .mdl-ripple {
    background: rgb(97,97,97); }
  .mdl-icon-toggle.is-disabled .mdl-icon-toggle__ripple-container {
    cursor: auto; }
  .mdl-icon-toggle.is-disabled .mdl-icon-toggle__ripple-container .mdl-ripple {
    background: transparent; }

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* TOOLTIP */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
.mdl-menu__container {
  display: block;
  margin: 0;
  padding: 0;
  border: none;
  position: absolute;
  overflow: visible;
  height: 0;
  width: 0;
  visibility: hidden;
  z-index: -1; }
  .mdl-menu__container.is-visible, .mdl-menu__container.is-animating {
    z-index: 999;
    visibility: visible; }

.mdl-menu__outline {
  display: block;
  background: rgb(255,255,255);
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 2px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  opacity: 0;
  transform: scale(0);
  transform-origin: 0 0;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  will-change: transform;
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: -1; }
  .mdl-menu__container.is-visible .mdl-menu__outline {
    opacity: 1;
    transform: scale(1);
    z-index: 999; }
  .mdl-menu__outline.mdl-menu--bottom-right {
    transform-origin: 100% 0; }
  .mdl-menu__outline.mdl-menu--top-left {
    transform-origin: 0 100%; }
  .mdl-menu__outline.mdl-menu--top-right {
    transform-origin: 100% 100%; }

.mdl-menu {
  position: absolute;
  list-style: none;
  top: 0;
  left: 0;
  height: auto;
  width: auto;
  min-width: 124px;
  padding: 8px 0;
  margin: 0;
  opacity: 0;
  clip: rect(0 0 0 0);
  z-index: -1; }
  .mdl-menu__container.is-visible .mdl-menu {
    opacity: 1;
    z-index: 999; }
  .mdl-menu.is-animating {
    transition: opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1), clip 0.3s cubic-bezier(0.4, 0, 0.2, 1); }
  .mdl-menu.mdl-menu--bottom-right {
    left: auto;
    right: 0; }
  .mdl-menu.mdl-menu--top-left {
    top: auto;
    bottom: 0; }
  .mdl-menu.mdl-menu--top-right {
    top: auto;
    left: auto;
    bottom: 0;
    right: 0; }
  .mdl-menu.mdl-menu--unaligned {
    top: auto;
    left: auto; }

.mdl-menu__item {
  display: block;
  border: none;
  color: rgba(0,0,0, 0.87);
  background-color: transparent;
  text-align: left;
  margin: 0;
  padding: 0 16px;
  outline-color: rgb(189,189,189);
  position: relative;
  overflow: hidden;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-decoration: none;
  cursor: pointer;
  height: 48px;
  line-height: 48px;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  user-select: none; }
  .mdl-menu__container.is-visible .mdl-menu__item {
    opacity: 1; }
  .mdl-menu__item::-moz-focus-inner {
    border: 0; }
  .mdl-menu__item[disabled] {
    color: rgb(189,189,189);
    background-color: transparent;
    cursor: auto; }
    .mdl-menu__item[disabled]:hover {
      background-color: transparent; }
    .mdl-menu__item[disabled]:focus {
      background-color: transparent; }
    .mdl-menu__item[disabled] .mdl-ripple {
      background: transparent; }
  .mdl-menu__item:hover {
    background-color: rgb(238,238,238); }
  .mdl-menu__item:focus {
    outline: none;
    background-color: rgb(238,238,238); }
  .mdl-menu__item:active {
    background-color: rgb(224,224,224); }

.mdl-menu__item--ripple-container {
  display: block;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: 0;
  overflow: hidden; }

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* TOOLTIP */
.mdl-progress {
  display: block;
  position: relative;
  height: 4px;
  width: 500px; }

.mdl-progress > .bar {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 0%;
  transition: width 0.2s cubic-bezier(0.4, 0, 0.2, 1); }

.mdl-progress > .progressbar {
  background-color: rgb(63,81,181);
  z-index: 1;
  left: 0; }

.mdl-progress > .bufferbar {
  background-image: linear-gradient(to right, rgba(255,255,255, 0.7), rgba(255,255,255, 0.7)), linear-gradient(to right, rgb(63,81,181), rgb(63,81,181));
  z-index: 0;
  left: 0; }

.mdl-progress > .auxbar {
  right: 0; }

@supports (-webkit-appearance: none) {
  .mdl-progress:not(.mdl-progress__indeterminate):not(.mdl-progress__indeterminate) > .auxbar {
    background-image: linear-gradient(to right, rgba(255,255,255, 0.7), rgba(255,255,255, 0.7)), linear-gradient(to right, rgb(63,81,181), rgb(63,81,181));
    mask: url("../img/mdl/buffer.svg?embed"); } }

.mdl-progress:not(.mdl-progress__indeterminate) > .auxbar {
  background-image: linear-gradient(to right, rgba(255,255,255, 0.9), rgba(255,255,255, 0.9)), linear-gradient(to right, rgb(63,81,181), rgb(63,81,181)); }

.mdl-progress.mdl-progress__indeterminate > .bar1 {
  background-color: rgb(63,81,181);
  animation-name: indeterminate1;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear; }

.mdl-progress.mdl-progress__indeterminate > .bar3 {
  background-image: none;
  background-color: rgb(63,81,181);
  animation-name: indeterminate2;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear; }

@keyframes indeterminate1 {
  0% {
    left: 0%;
    width: 0%; }
  50% {
    left: 25%;
    width: 75%; }
  75% {
    left: 100%;
    width: 0%; } }

@keyframes indeterminate2 {
  0% {
    left: 0%;
    width: 0%; }
  50% {
    left: 0%;
    width: 0%; }
  75% {
    left: 0%;
    width: 25%; }
  100% {
    left: 100%;
    width: 0%; } }

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* TOOLTIP */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
.mdl-navigation {
  display: flex;
  flex-wrap: nowrap;
  box-sizing: border-box; }

.mdl-navigation__link {
  color: rgb(66,66,66);
  text-decoration: none;
  font-weight: 500;
  font-size: 13px;
  margin: 0; }

.mdl-layout {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  -webkit-overflow-scrolling: touch; }

.mdl-layout.is-small-screen .mdl-layout--large-screen-only {
  display: none; }

.mdl-layout:not(.is-small-screen) .mdl-layout--small-screen-only {
  display: none; }

.mdl-layout__container {
  position: absolute;
  width: 100%;
  height: 100%; }

.mdl-layout__title,
.mdl-layout-title {
  display: block;
  position: relative;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.02em;
  font-weight: 400;
  box-sizing: border-box; }

.mdl-layout-spacer {
  flex-grow: 1; }

.mdl-layout__drawer {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 240px;
  height: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  border-right: 1px solid rgb(224,224,224);
  background: rgb(250,250,250);
  transform: translateX(-250px);
  transform-style: preserve-3d;
  will-change: transform;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: transform;
  color: rgb(66,66,66);
  overflow: visible;
  overflow-y: auto;
  z-index: 5; }
  .mdl-layout__drawer.is-visible {
    transform: translateX(0); }
    .mdl-layout__drawer.is-visible ~ .mdl-layout__content.mdl-layout__content {
      overflow: hidden; }
  .mdl-layout__drawer > * {
    flex-shrink: 0; }
  .mdl-layout__drawer > .mdl-layout__title,
  .mdl-layout__drawer > .mdl-layout-title {
    line-height: 64px;
    padding-left: 40px; }
    @media screen and (max-width: 1024px) {
      .mdl-layout__drawer > .mdl-layout__title,
      .mdl-layout__drawer > .mdl-layout-title {
        line-height: 56px;
        padding-left: 16px; } }
  .mdl-layout__drawer .mdl-navigation {
    flex-direction: column;
    align-items: stretch;
    padding-top: 16px; }
    .mdl-layout__drawer .mdl-navigation .mdl-navigation__link {
      display: block;
      flex-shrink: 0;
      padding: 16px 40px;
      margin: 0;
      color: #757575; }
      @media screen and (max-width: 1024px) {
        .mdl-layout__drawer .mdl-navigation .mdl-navigation__link {
          padding: 16px 16px; } }
      .mdl-layout__drawer .mdl-navigation .mdl-navigation__link:hover {
        background-color: rgb(224,224,224); }
      .mdl-layout__drawer .mdl-navigation .mdl-navigation__link--current {
        background-color: rgb(0,0,0);
        color: rgb(224,224,224); }
  @media screen and (min-width: 1025px) {
    .mdl-layout--fixed-drawer > .mdl-layout__drawer {
      transform: translateX(0); } }

.mdl-layout__drawer-button {
  display: block;
  position: absolute;
  height: 48px;
  width: 48px;
  border: 0;
  flex-shrink: 0;
  overflow: hidden;
  text-align: center;
  cursor: pointer;
  font-size: 26px;
  line-height: 50px;
  font-family: Helvetica, Arial, sans-serif;
  margin: 10px 12px;
  top: 0;
  left: 0;
  color: rgb(255,255,255);
  z-index: 4; }
  .mdl-layout__header .mdl-layout__drawer-button {
    position: absolute;
    color: rgb(255,255,255);
    background-color: inherit; }
    @media screen and (max-width: 1024px) {
      .mdl-layout__header .mdl-layout__drawer-button {
        margin: 4px; } }
  @media screen and (max-width: 1024px) {
    .mdl-layout__drawer-button {
      margin: 4px;
      color: rgba(0, 0, 0, 0.5); } }
  @media screen and (min-width: 1025px) {
    .mdl-layout--fixed-drawer > .mdl-layout__drawer-button {
      display: none; } }

.mdl-layout__header {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  box-sizing: border-box;
  flex-shrink: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  border: none;
  min-height: 64px;
  max-height: 1000px;
  z-index: 3;
  background-color: rgb(63,81,181);
  color: rgb(255,255,255);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: max-height, box-shadow; }
  @media screen and (max-width: 1024px) {
    .mdl-layout__header {
      min-height: 56px; } }
  .mdl-layout--fixed-drawer.is-upgraded:not(.is-small-screen) > .mdl-layout__header {
    margin-left: 240px;
    width: calc(100% - 240px); }
  @media screen and (min-width: 1025px) {
    .mdl-layout--fixed-drawer > .mdl-layout__header .mdl-layout__header-row {
      padding-left: 40px; } }
  .mdl-layout__header > .mdl-layout-icon {
    position: absolute;
    left: 40px;
    top: 16px;
    height: 32px;
    width: 32px;
    overflow: hidden;
    z-index: 3;
    display: block; }
    @media screen and (max-width: 1024px) {
      .mdl-layout__header > .mdl-layout-icon {
        left: 16px;
        top: 12px; } }
  .mdl-layout.has-drawer .mdl-layout__header > .mdl-layout-icon {
    display: none; }
  .mdl-layout__header.is-compact {
    max-height: 64px; }
    @media screen and (max-width: 1024px) {
      .mdl-layout__header.is-compact {
        max-height: 56px; } }
  .mdl-layout__header.is-compact.has-tabs {
    height: 112px; }
    @media screen and (max-width: 1024px) {
      .mdl-layout__header.is-compact.has-tabs {
        min-height: 104px; } }
  @media screen and (max-width: 1024px) {
    .mdl-layout__header {
      display: none; }
    .mdl-layout--fixed-header > .mdl-layout__header {
      display: flex; } }

.mdl-layout__header--transparent.mdl-layout__header--transparent {
  background-color: transparent;
  box-shadow: none; }

.mdl-layout__header--seamed {
  box-shadow: none; }

.mdl-layout__header--scroll {
  box-shadow: none; }

.mdl-layout__header--waterfall {
  box-shadow: none;
  overflow: hidden; }
  .mdl-layout__header--waterfall.is-casting-shadow {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12); }

.mdl-layout__header-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex-shrink: 0;
  box-sizing: border-box;
  align-self: stretch;
  align-items: center;
  height: 64px;
  margin: 0;
  padding: 0 40px 0 80px; }
  @media screen and (max-width: 1024px) {
    .mdl-layout__header-row {
      height: 56px;
      padding: 0 16px 0 72px; } }
  .mdl-layout__header-row > * {
    flex-shrink: 0; }
  .mdl-layout__header--scroll .mdl-layout__header-row {
    width: 100%; }
  .mdl-layout__header-row .mdl-navigation {
    margin: 0;
    padding: 0;
    height: 64px;
    flex-direction: row;
    align-items: center; }
    @media screen and (max-width: 1024px) {
      .mdl-layout__header-row .mdl-navigation {
        height: 56px; } }
  .mdl-layout__header-row .mdl-navigation__link {
    display: block;
    color: rgb(255,255,255);
    line-height: 64px;
    padding: 0 24px; }
    @media screen and (max-width: 1024px) {
      .mdl-layout__header-row .mdl-navigation__link {
        line-height: 56px;
        padding: 0 16px; } }

.mdl-layout__obfuscator {
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 4;
  visibility: hidden;
  transition-property: background-color;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1); }
  .mdl-layout__obfuscator.is-visible {
    background-color: rgba(0, 0, 0, 0.5);
    visibility: visible; }

.mdl-layout__content {
  -ms-flex: 0 1 auto;
  display: inline-block;
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 1;
  z-index: 1;
  -webkit-overflow-scrolling: touch; }
  .mdl-layout--fixed-drawer > .mdl-layout__content {
    margin-left: 240px; }
  .mdl-layout__container.has-scrolling-header .mdl-layout__content {
    overflow: visible; }
  @media screen and (max-width: 1024px) {
    .mdl-layout--fixed-drawer > .mdl-layout__content {
      margin-left: 0; }
    .mdl-layout__container.has-scrolling-header .mdl-layout__content {
      overflow-y: auto;
      overflow-x: hidden; } }

.mdl-layout__tab-bar {
  height: 96px;
  margin: 0;
  width: calc(100% - 112px);
  padding: 0 0 0 56px;
  display: flex;
  background-color: rgb(63,81,181);
  overflow-y: hidden;
  overflow-x: scroll; }
  .mdl-layout__tab-bar::-webkit-scrollbar {
    display: none; }
  @media screen and (max-width: 1024px) {
    .mdl-layout__tab-bar {
      width: calc(100% - 60px);
      padding: 0 0 0 60px; } }
  .mdl-layout--fixed-tabs .mdl-layout__tab-bar {
    padding: 0;
    overflow: hidden;
    width: 100%; }

.mdl-layout__tab-bar-container {
  position: relative;
  height: 48px;
  width: 100%;
  border: none;
  margin: 0;
  z-index: 2;
  flex-grow: 0;
  flex-shrink: 0;
  overflow: hidden; }
  .mdl-layout__container > .mdl-layout__tab-bar-container {
    position: absolute;
    top: 0;
    left: 0; }

.mdl-layout__tab-bar-button {
  display: inline-block;
  position: absolute;
  top: 0;
  height: 48px;
  width: 56px;
  z-index: 4;
  text-align: center;
  background-color: rgb(63,81,181);
  color: transparent;
  cursor: pointer;
  user-select: none; }
  @media screen and (max-width: 1024px) {
    .mdl-layout__tab-bar-button {
      display: none;
      width: 60px; } }
  .mdl-layout--fixed-tabs .mdl-layout__tab-bar-button {
    display: none; }
  .mdl-layout__tab-bar-button .material-icons {
    line-height: 48px; }
  .mdl-layout__tab-bar-button.is-active {
    color: rgb(255,255,255); }

.mdl-layout__tab-bar-left-button {
  left: 0; }

.mdl-layout__tab-bar-right-button {
  right: 0; }

.mdl-layout__tab {
  margin: 0;
  border: none;
  padding: 0 24px 0 24px;
  float: left;
  position: relative;
  display: block;
  flex-grow: 0;
  flex-shrink: 0;
  text-decoration: none;
  height: 48px;
  line-height: 48px;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  color: rgba(255,255,255, 0.6);
  overflow: hidden; }
  @media screen and (max-width: 1024px) {
    .mdl-layout__tab {
      padding: 0 12px 0 12px; } }
  .mdl-layout--fixed-tabs .mdl-layout__tab {
    float: none;
    flex-grow: 1;
    padding: 0; }
  .mdl-layout.is-upgraded .mdl-layout__tab.is-active {
    color: rgb(255,255,255); }
  .mdl-layout.is-upgraded .mdl-layout__tab.is-active::after {
    height: 2px;
    width: 100%;
    display: block;
    content: " ";
    bottom: 0;
    left: 0;
    position: absolute;
    background: rgb(255,64,129);
    animation: border-expand 0.2s cubic-bezier(0.4, 0, 0.4, 1) 0.01s alternate forwards;
    transition: all 1s cubic-bezier(0.4, 0, 1, 1); }
  .mdl-layout__tab .mdl-layout__tab-ripple-container {
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    overflow: hidden; }
    .mdl-layout__tab .mdl-layout__tab-ripple-container .mdl-ripple {
      background-color: rgb(255,255,255); }

.mdl-layout__tab-panel {
  display: block; }
  .mdl-layout.is-upgraded .mdl-layout__tab-panel {
    display: none; }
  .mdl-layout.is-upgraded .mdl-layout__tab-panel.is-active {
    display: block; }

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* TOOLTIP */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
.mdl-radio {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  display: inline-block;
  box-sizing: border-box;
  margin: 0;
  padding-left: 0; }
  .mdl-radio.is-upgraded {
    padding-left: 24px; }

.mdl-radio__button {
  line-height: 24px; }
  .mdl-radio.is-upgraded .mdl-radio__button {
    position: absolute;
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
    -ms-appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border: none; }

.mdl-radio__outer-circle {
  position: absolute;
  top: 4px;
  left: 0;
  display: inline-block;
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  margin: 0;
  cursor: pointer;
  border: 2px solid rgba(0,0,0, 0.54);
  border-radius: 50%;
  z-index: 2; }
  .mdl-radio.is-checked .mdl-radio__outer-circle {
    border: 2px solid rgb(63,81,181); }
  .mdl-radio.is-disabled .mdl-radio__outer-circle {
    border: 2px solid rgba(0,0,0, 0.26);
    cursor: auto; }

.mdl-radio__inner-circle {
  position: absolute;
  z-index: 1;
  margin: 0;
  top: 8px;
  left: 4px;
  box-sizing: border-box;
  width: 8px;
  height: 8px;
  cursor: pointer;
  transition-duration: 0.28s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: transform;
  transform: scale3d(0, 0, 0);
  border-radius: 50%;
  background: rgb(63,81,181); }
  .mdl-radio.is-checked .mdl-radio__inner-circle {
    transform: scale3d(1, 1, 1); }
  .mdl-radio.is-disabled .mdl-radio__inner-circle {
    background: rgba(0,0,0, 0.26);
    cursor: auto; }
  .mdl-radio.is-focused .mdl-radio__inner-circle {
    box-shadow: 0 0 0px 10px rgba(0, 0, 0, 0.1); }

.mdl-radio__label {
  cursor: pointer; }
  .mdl-radio.is-disabled .mdl-radio__label {
    color: rgba(0,0,0, 0.26);
    cursor: auto; }

.mdl-radio__ripple-container {
  position: absolute;
  z-index: 2;
  top: -9px;
  left: -13px;
  box-sizing: border-box;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  cursor: pointer;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(circle, white, black); }
  .mdl-radio__ripple-container .mdl-ripple {
    background: rgb(63,81,181); }
  .mdl-radio.is-disabled .mdl-radio__ripple-container {
    cursor: auto; }
  .mdl-radio.is-disabled .mdl-radio__ripple-container .mdl-ripple {
    background: transparent; }

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* TOOLTIP */
_:-ms-input-placeholder, :root .mdl-slider.mdl-slider.is-upgraded {
  -ms-appearance: none;
  height: 32px;
  margin: 0; }

.mdl-slider {
  width: calc(100% - 40px);
  margin: 0 20px; }
  .mdl-slider.is-upgraded {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height: 2px;
    background: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    outline: 0;
    padding: 0;
    color: rgb(63,81,181);
    align-self: center;
    z-index: 1;
    cursor: pointer;
    /**************************** Tracks ****************************/
    /**************************** Thumbs ****************************/
    /**************************** 0-value ****************************/
    /**************************** Disabled ****************************/ }
    .mdl-slider.is-upgraded::-moz-focus-outer {
      border: 0; }
    .mdl-slider.is-upgraded::-ms-tooltip {
      display: none; }
    .mdl-slider.is-upgraded::-webkit-slider-runnable-track {
      background: transparent; }
    .mdl-slider.is-upgraded::-moz-range-track {
      background: transparent;
      border: none; }
    .mdl-slider.is-upgraded::-ms-track {
      background: none;
      color: transparent;
      height: 2px;
      width: 100%;
      border: none; }
    .mdl-slider.is-upgraded::-ms-fill-lower {
      padding: 0;
      background: linear-gradient(to right, transparent, transparent 16px, rgb(63,81,181) 16px, rgb(63,81,181) 0); }
    .mdl-slider.is-upgraded::-ms-fill-upper {
      padding: 0;
      background: linear-gradient(to left, transparent, transparent 16px, rgba(0,0,0, 0.26) 16px, rgba(0,0,0, 0.26) 0); }
    .mdl-slider.is-upgraded::-webkit-slider-thumb {
      -webkit-appearance: none;
      width: 12px;
      height: 12px;
      box-sizing: border-box;
      border-radius: 50%;
      background: rgb(63,81,181);
      border: none;
      transition: transform 0.18s cubic-bezier(0.4, 0, 0.2, 1), border 0.18s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.18s cubic-bezier(0.4, 0, 0.2, 1), background 0.28s cubic-bezier(0.4, 0, 0.2, 1); }
    .mdl-slider.is-upgraded::-moz-range-thumb {
      -moz-appearance: none;
      width: 12px;
      height: 12px;
      box-sizing: border-box;
      border-radius: 50%;
      background-image: none;
      background: rgb(63,81,181);
      border: none; }
    .mdl-slider.is-upgraded:focus:not(:active)::-webkit-slider-thumb {
      box-shadow: 0 0 0 10px rgba(63,81,181, 0.26); }
    .mdl-slider.is-upgraded:focus:not(:active)::-moz-range-thumb {
      box-shadow: 0 0 0 10px rgba(63,81,181, 0.26); }
    .mdl-slider.is-upgraded:active::-webkit-slider-thumb {
      background-image: none;
      background: rgb(63,81,181);
      transform: scale(1.5); }
    .mdl-slider.is-upgraded:active::-moz-range-thumb {
      background-image: none;
      background: rgb(63,81,181);
      transform: scale(1.5); }
    .mdl-slider.is-upgraded::-ms-thumb {
      width: 32px;
      height: 32px;
      border: none;
      border-radius: 50%;
      background: rgb(63,81,181);
      transform: scale(0.375);
      transition: transform 0.18s cubic-bezier(0.4, 0, 0.2, 1), background 0.28s cubic-bezier(0.4, 0, 0.2, 1); }
    .mdl-slider.is-upgraded:focus:not(:active)::-ms-thumb {
      background: radial-gradient(circle closest-side, rgb(63,81,181) 0%, rgb(63,81,181) 37.5%, rgba(63,81,181, 0.26) 37.5%, rgba(63,81,181, 0.26) 100%);
      transform: scale(1); }
    .mdl-slider.is-upgraded:active::-ms-thumb {
      background: rgb(63,81,181);
      transform: scale(0.5625); }
    .mdl-slider.is-upgraded.is-lowest-value::-webkit-slider-thumb {
      border: 2px solid rgba(0,0,0, 0.26);
      background: transparent; }
    .mdl-slider.is-upgraded.is-lowest-value::-moz-range-thumb {
      border: 2px solid rgba(0,0,0, 0.26);
      background: transparent; }
    .mdl-slider.is-upgraded.is-lowest-value +
.mdl-slider__background-flex > .mdl-slider__background-upper {
      left: 6px; }
    .mdl-slider.is-upgraded.is-lowest-value:focus:not(:active)::-webkit-slider-thumb {
      box-shadow: 0 0 0 10px rgba(0,0,0, 0.12);
      background: rgba(0,0,0, 0.12); }
    .mdl-slider.is-upgraded.is-lowest-value:focus:not(:active)::-moz-range-thumb {
      box-shadow: 0 0 0 10px rgba(0,0,0, 0.12);
      background: rgba(0,0,0, 0.12); }
    .mdl-slider.is-upgraded.is-lowest-value:active::-webkit-slider-thumb {
      border: 1.6px solid rgba(0,0,0, 0.26);
      transform: scale(1.5); }
    .mdl-slider.is-upgraded.is-lowest-value:active +
.mdl-slider__background-flex > .mdl-slider__background-upper {
      left: 9px; }
    .mdl-slider.is-upgraded.is-lowest-value:active::-moz-range-thumb {
      border: 1.5px solid rgba(0,0,0, 0.26);
      transform: scale(1.5); }
    .mdl-slider.is-upgraded.is-lowest-value::-ms-thumb {
      background: radial-gradient(circle closest-side, transparent 0%, transparent 66.67%, rgba(0,0,0, 0.26) 66.67%, rgba(0,0,0, 0.26) 100%); }
    .mdl-slider.is-upgraded.is-lowest-value:focus:not(:active)::-ms-thumb {
      background: radial-gradient(circle closest-side, rgba(0,0,0, 0.12) 0%, rgba(0,0,0, 0.12) 25%, rgba(0,0,0, 0.26) 25%, rgba(0,0,0, 0.26) 37.5%, rgba(0,0,0, 0.12) 37.5%, rgba(0,0,0, 0.12) 100%);
      transform: scale(1); }
    .mdl-slider.is-upgraded.is-lowest-value:active::-ms-thumb {
      transform: scale(0.5625);
      background: radial-gradient(circle closest-side, transparent 0%, transparent 77.78%, rgba(0,0,0, 0.26) 77.78%, rgba(0,0,0, 0.26) 100%); }
    .mdl-slider.is-upgraded.is-lowest-value::-ms-fill-lower {
      background: transparent; }
    .mdl-slider.is-upgraded.is-lowest-value::-ms-fill-upper {
      margin-left: 6px; }
    .mdl-slider.is-upgraded.is-lowest-value:active::-ms-fill-upper {
      margin-left: 9px; }
    .mdl-slider.is-upgraded:disabled:focus::-webkit-slider-thumb, .mdl-slider.is-upgraded:disabled:active::-webkit-slider-thumb, .mdl-slider.is-upgraded:disabled::-webkit-slider-thumb {
      transform: scale(0.667);
      background: rgba(0,0,0, 0.26); }
    .mdl-slider.is-upgraded:disabled:focus::-moz-range-thumb, .mdl-slider.is-upgraded:disabled:active::-moz-range-thumb, .mdl-slider.is-upgraded:disabled::-moz-range-thumb {
      transform: scale(0.667);
      background: rgba(0,0,0, 0.26); }
    .mdl-slider.is-upgraded:disabled +
.mdl-slider__background-flex > .mdl-slider__background-lower {
      background-color: rgba(0,0,0, 0.26);
      left: -6px; }
    .mdl-slider.is-upgraded:disabled +
.mdl-slider__background-flex > .mdl-slider__background-upper {
      left: 6px; }
    .mdl-slider.is-upgraded.is-lowest-value:disabled:focus::-webkit-slider-thumb, .mdl-slider.is-upgraded.is-lowest-value:disabled:active::-webkit-slider-thumb, .mdl-slider.is-upgraded.is-lowest-value:disabled::-webkit-slider-thumb {
      border: 3px solid rgba(0,0,0, 0.26);
      background: transparent;
      transform: scale(0.667); }
    .mdl-slider.is-upgraded.is-lowest-value:disabled:focus::-moz-range-thumb, .mdl-slider.is-upgraded.is-lowest-value:disabled:active::-moz-range-thumb, .mdl-slider.is-upgraded.is-lowest-value:disabled::-moz-range-thumb {
      border: 3px solid rgba(0,0,0, 0.26);
      background: transparent;
      transform: scale(0.667); }
    .mdl-slider.is-upgraded.is-lowest-value:disabled:active +
.mdl-slider__background-flex > .mdl-slider__background-upper {
      left: 6px; }
    .mdl-slider.is-upgraded:disabled:focus::-ms-thumb, .mdl-slider.is-upgraded:disabled:active::-ms-thumb, .mdl-slider.is-upgraded:disabled::-ms-thumb {
      transform: scale(0.25);
      background: rgba(0,0,0, 0.26); }
    .mdl-slider.is-upgraded.is-lowest-value:disabled:focus::-ms-thumb, .mdl-slider.is-upgraded.is-lowest-value:disabled:active::-ms-thumb, .mdl-slider.is-upgraded.is-lowest-value:disabled::-ms-thumb {
      transform: scale(0.25);
      background: radial-gradient(circle closest-side, transparent 0%, transparent 50%, rgba(0,0,0, 0.26) 50%, rgba(0,0,0, 0.26) 100%); }
    .mdl-slider.is-upgraded:disabled::-ms-fill-lower {
      margin-right: 6px;
      background: linear-gradient(to right, transparent, transparent 25px, rgba(0,0,0, 0.26) 25px, rgba(0,0,0, 0.26) 0); }
    .mdl-slider.is-upgraded:disabled::-ms-fill-upper {
      margin-left: 6px; }
    .mdl-slider.is-upgraded.is-lowest-value:disabled:active::-ms-fill-upper {
      margin-left: 6px; }

.mdl-slider__ie-container {
  height: 18px;
  overflow: visible;
  border: none;
  margin: none;
  padding: none; }

.mdl-slider__container {
  height: 18px;
  position: relative;
  background: none;
  display: flex;
  flex-direction: row; }

.mdl-slider__background-flex {
  background: transparent;
  position: absolute;
  height: 2px;
  width: calc(100% - 52px);
  top: 50%;
  left: 0;
  margin: 0 26px;
  display: flex;
  overflow: hidden;
  border: 0;
  padding: 0;
  transform: translate(0, -1px); }

.mdl-slider__background-lower {
  background: rgb(63,81,181);
  flex: 0;
  position: relative;
  border: 0;
  padding: 0; }

.mdl-slider__background-upper {
  background: rgba(0,0,0, 0.26);
  flex: 0;
  position: relative;
  border: 0;
  padding: 0;
  transition: left 0.18s cubic-bezier(0.4, 0, 0.2, 1); }

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* TOOLTIP */
.mdl-spinner {
  display: inline-block;
  position: relative;
  width: 28px;
  height: 28px; }
  .mdl-spinner:not(.is-upgraded).is-active:after {
    content: "Loading..."; }
  .mdl-spinner.is-upgraded.is-active {
    animation: mdl-spinner__container-rotate 1568.23529412ms linear infinite; }

@keyframes mdl-spinner__container-rotate {
  to {
    transform: rotate(360deg); } }

.mdl-spinner__layer {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0; }

.mdl-spinner__layer-1 {
  border-color: rgb(66,165,245); }
  .mdl-spinner--single-color .mdl-spinner__layer-1 {
    border-color: rgb(63,81,181); }
  .mdl-spinner.is-active .mdl-spinner__layer-1 {
    animation: mdl-spinner__fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, mdl-spinner__layer-1-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both; }

.mdl-spinner__layer-2 {
  border-color: rgb(244,67,54); }
  .mdl-spinner--single-color .mdl-spinner__layer-2 {
    border-color: rgb(63,81,181); }
  .mdl-spinner.is-active .mdl-spinner__layer-2 {
    animation: mdl-spinner__fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, mdl-spinner__layer-2-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both; }

.mdl-spinner__layer-3 {
  border-color: rgb(253,216,53); }
  .mdl-spinner--single-color .mdl-spinner__layer-3 {
    border-color: rgb(63,81,181); }
  .mdl-spinner.is-active .mdl-spinner__layer-3 {
    animation: mdl-spinner__fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, mdl-spinner__layer-3-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both; }

.mdl-spinner__layer-4 {
  border-color: rgb(76,175,80); }
  .mdl-spinner--single-color .mdl-spinner__layer-4 {
    border-color: rgb(63,81,181); }
  .mdl-spinner.is-active .mdl-spinner__layer-4 {
    animation: mdl-spinner__fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, mdl-spinner__layer-4-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both; }

@keyframes mdl-spinner__fill-unfill-rotate {
  12.5% {
    transform: rotate(135deg); }
  25% {
    transform: rotate(270deg); }
  37.5% {
    transform: rotate(405deg); }
  50% {
    transform: rotate(540deg); }
  62.5% {
    transform: rotate(675deg); }
  75% {
    transform: rotate(810deg); }
  87.5% {
    transform: rotate(945deg); }
  to {
    transform: rotate(1080deg); } }

/**
* HACK: Even though the intention is to have the current .mdl-spinner__layer-N
* at `opacity: 1`, we set it to `opacity: 0.99` instead since this forces Chrome
* to do proper subpixel rendering for the elements being animated. This is
* especially visible in Chrome 39 on Ubuntu 14.04. See:
*
* - https://github.com/Polymer/paper-spinner/issues/9
* - https://code.google.com/p/chromium/issues/detail?id=436255
*/
@keyframes mdl-spinner__layer-1-fade-in-out {
  from {
    opacity: 0.99; }
  25% {
    opacity: 0.99; }
  26% {
    opacity: 0; }
  89% {
    opacity: 0; }
  90% {
    opacity: 0.99; }
  100% {
    opacity: 0.99; } }

@keyframes mdl-spinner__layer-2-fade-in-out {
  from {
    opacity: 0; }
  15% {
    opacity: 0; }
  25% {
    opacity: 0.99; }
  50% {
    opacity: 0.99; }
  51% {
    opacity: 0; } }

@keyframes mdl-spinner__layer-3-fade-in-out {
  from {
    opacity: 0; }
  40% {
    opacity: 0; }
  50% {
    opacity: 0.99; }
  75% {
    opacity: 0.99; }
  76% {
    opacity: 0; } }

@keyframes mdl-spinner__layer-4-fade-in-out {
  from {
    opacity: 0; }
  65% {
    opacity: 0; }
  75% {
    opacity: 0.99; }
  90% {
    opacity: 0.99; }
  100% {
    opacity: 0; } }

/**
* Patch the gap that appear between the two adjacent
* div.mdl-spinner__circle-clipper while the spinner is rotating
* (appears on Chrome 38, Safari 7.1, and IE 11).
*
* Update: the gap no longer appears on Chrome when .mdl-spinner__layer-N's
* opacity is 0.99, but still does on Safari and IE.
*/
.mdl-spinner__gap-patch {
  position: absolute;
  box-sizing: border-box;
  top: 0;
  left: 45%;
  width: 10%;
  height: 100%;
  overflow: hidden;
  border-color: inherit; }
  .mdl-spinner__gap-patch .mdl-spinner__circle {
    width: 1000%;
    left: -450%; }

.mdl-spinner__circle-clipper {
  display: inline-block;
  position: relative;
  width: 50%;
  height: 100%;
  overflow: hidden;
  border-color: inherit; }
  .mdl-spinner__circle-clipper .mdl-spinner__circle {
    width: 200%; }

.mdl-spinner__circle {
  box-sizing: border-box;
  height: 100%;
  border-width: 3px;
  border-style: solid;
  border-color: inherit;
  border-bottom-color: transparent !important;
  border-radius: 50%;
  animation: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }
  .mdl-spinner__left .mdl-spinner__circle {
    border-right-color: transparent !important;
    transform: rotate(129deg); }
    .mdl-spinner.is-active .mdl-spinner__left .mdl-spinner__circle {
      animation: mdl-spinner__left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both; }
  .mdl-spinner__right .mdl-spinner__circle {
    left: -100%;
    border-left-color: transparent !important;
    transform: rotate(-129deg); }
    .mdl-spinner.is-active .mdl-spinner__right .mdl-spinner__circle {
      animation: mdl-spinner__right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both; }

@keyframes mdl-spinner__left-spin {
  from {
    transform: rotate(130deg); }
  50% {
    transform: rotate(-5deg); }
  to {
    transform: rotate(130deg); } }

@keyframes mdl-spinner__right-spin {
  from {
    transform: rotate(-130deg); }
  50% {
    transform: rotate(5deg); }
  to {
    transform: rotate(-130deg); } }

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* TOOLTIP */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
.mdl-switch {
  position: relative;
  z-index: 1;
  vertical-align: middle;
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  height: 24px;
  margin: 0;
  padding: 0;
  overflow: visible;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  .mdl-switch.is-upgraded {
    padding-left: 28px; }

.mdl-switch__input {
  line-height: 24px; }
  .mdl-switch.is-upgraded .mdl-switch__input {
    position: absolute;
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
    -ms-appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border: none; }

.mdl-switch__track {
  background: rgba(0,0,0, 0.26);
  position: absolute;
  left: 0;
  top: 5px;
  height: 14px;
  width: 36px;
  border-radius: 14px;
  cursor: pointer; }
  .mdl-switch.is-checked .mdl-switch__track {
    background: rgba(63,81,181, 0.5); }
  .mdl-switch.is-disabled .mdl-switch__track {
    background: rgba(0,0,0, 0.12);
    cursor: auto; }

.mdl-switch__thumb {
  background: rgb(250,250,250);
  position: absolute;
  left: 0;
  top: 2px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  transition-duration: 0.28s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: left; }
  .mdl-switch.is-checked .mdl-switch__thumb {
    background: rgb(63,81,181);
    left: 16px;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 1px 8px 0 rgba(0, 0, 0, 0.12); }
  .mdl-switch.is-disabled .mdl-switch__thumb {
    background: rgb(189,189,189);
    cursor: auto; }

.mdl-switch__focus-helper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-4px, -4px);
  display: inline-block;
  box-sizing: border-box;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: transparent; }
  .mdl-switch.is-focused .mdl-switch__focus-helper {
    box-shadow: 0 0 0px 20px rgba(0, 0, 0, 0.1);
    background-color: rgba(0, 0, 0, 0.1); }
  .mdl-switch.is-focused.is-checked .mdl-switch__focus-helper {
    box-shadow: 0 0 0px 20px rgba(63,81,181, 0.26);
    background-color: rgba(63,81,181, 0.26); }

.mdl-switch__label {
  position: relative;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  left: 24px; }
  .mdl-switch.is-disabled .mdl-switch__label {
    color: rgb(189,189,189);
    cursor: auto; }

.mdl-switch__ripple-container {
  position: absolute;
  z-index: 2;
  top: -12px;
  left: -14px;
  box-sizing: border-box;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  cursor: pointer;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(circle, white, black);
  transition-duration: 0.40s;
  transition-timing-function: step-end;
  transition-property: left; }
  .mdl-switch__ripple-container .mdl-ripple {
    background: rgb(63,81,181); }
  .mdl-switch.is-disabled .mdl-switch__ripple-container {
    cursor: auto; }
  .mdl-switch.is-disabled .mdl-switch__ripple-container .mdl-ripple {
    background: transparent; }
  .mdl-switch.is-checked .mdl-switch__ripple-container {
    cursor: auto;
    left: 2px; }

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* TOOLTIP */
.mdl-tabs {
  display: block;
  width: 100%; }

.mdl-tabs__tab-bar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: space-between;
  align-items: flex-start;
  height: 48px;
  padding: 0 0 0 0;
  margin: 0;
  border-bottom: 1px solid rgb(224,224,224); }

.mdl-tabs__tab {
  margin: 0;
  border: none;
  padding: 0 24px 0 24px;
  float: left;
  position: relative;
  display: block;
  color: red;
  text-decoration: none;
  height: 48px;
  line-height: 48px;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  color: rgba(0,0,0, 0.54);
  overflow: hidden; }
  .mdl-tabs.is-upgraded .mdl-tabs__tab.is-active {
    color: rgba(0,0,0, 0.87); }
  .mdl-tabs.is-upgraded .mdl-tabs__tab.is-active:after {
    height: 2px;
    width: 100%;
    display: block;
    content: " ";
    bottom: 0px;
    left: 0px;
    position: absolute;
    background: rgb(63,81,181);
    animation: border-expand 0.2s cubic-bezier(0.4, 0, 0.4, 1) 0.01s alternate forwards;
    transition: all 1s cubic-bezier(0.4, 0, 1, 1); }
  .mdl-tabs__tab .mdl-tabs__ripple-container {
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0px;
    top: 0px;
    z-index: 1;
    overflow: hidden; }
    .mdl-tabs__tab .mdl-tabs__ripple-container .mdl-ripple {
      background: rgb(63,81,181); }

.mdl-tabs__panel {
  display: block; }
  .mdl-tabs.is-upgraded .mdl-tabs__panel {
    display: none; }
  .mdl-tabs.is-upgraded .mdl-tabs__panel.is-active {
    display: block; }

@keyframes border-expand {
  0% {
    opacity: 0;
    width: 0; }
  100% {
    opacity: 1;
    width: 100%; } }

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* TOOLTIP */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
.mdl-textfield {
  position: relative;
  font-size: 16px;
  display: inline-block;
  box-sizing: border-box;
  width: 300px;
  max-width: 100%;
  margin: 0;
  padding: 20px 0; }
  .mdl-textfield .mdl-button {
    position: absolute;
    bottom: 20px; }

.mdl-textfield--align-right {
  text-align: right; }

.mdl-textfield--full-width {
  width: 100%; }

.mdl-textfield--expandable {
  min-width: 32px;
  width: auto;
  min-height: 32px; }

.mdl-textfield__input {
  border: none;
  border-bottom: 1px solid rgba(0,0,0, 0.12);
  display: block;
  font-size: 16px;
  margin: 0;
  padding: 4px 0;
  width: 100%;
  background: none;
  text-align: left;
  color: inherit; }
  .mdl-textfield.is-focused .mdl-textfield__input {
    outline: none; }
  .mdl-textfield.is-invalid .mdl-textfield__input {
    border-color: rgb(222, 50, 38);
    box-shadow: none; }
  .mdl-textfield.is-disabled .mdl-textfield__input {
    background-color: transparent;
    border-bottom: 1px dotted rgba(0,0,0, 0.12);
    color: rgba(0,0,0, 0.26); }

.mdl-textfield textarea.mdl-textfield__input {
  display: block; }

.mdl-textfield__label {
  bottom: 0;
  color: rgba(0,0,0, 0.26);
  font-size: 16px;
  left: 0;
  right: 0;
  pointer-events: none;
  position: absolute;
  display: block;
  top: 24px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-align: left; }
  .mdl-textfield.is-dirty .mdl-textfield__label {
    visibility: hidden; }
  .mdl-textfield--floating-label .mdl-textfield__label {
    transition-duration: 0.2s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1); }
  .mdl-textfield.is-disabled.is-disabled .mdl-textfield__label {
    color: rgba(0,0,0, 0.26); }
  .mdl-textfield--floating-label.is-focused .mdl-textfield__label,
  .mdl-textfield--floating-label.is-dirty .mdl-textfield__label {
    color: rgb(63,81,181);
    font-size: 12px;
    top: 4px;
    visibility: visible; }
  .mdl-textfield--floating-label.is-focused .mdl-textfield__expandable-holder .mdl-textfield__label,
  .mdl-textfield--floating-label.is-dirty .mdl-textfield__expandable-holder .mdl-textfield__label {
    top: -16px; }
  .mdl-textfield--floating-label.is-invalid .mdl-textfield__label {
    color: rgb(222, 50, 38);
    font-size: 12px; }
  .mdl-textfield__label:after {
    background-color: rgb(63,81,181);
    bottom: 20px;
    content: '';
    height: 2px;
    left: 45%;
    position: absolute;
    transition-duration: 0.2s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    visibility: hidden;
    width: 10px; }
  .mdl-textfield.is-focused .mdl-textfield__label:after {
    left: 0;
    visibility: visible;
    width: 100%; }
  .mdl-textfield.is-invalid .mdl-textfield__label:after {
    background-color: rgb(222, 50, 38); }

.mdl-textfield__error {
  color: rgb(222, 50, 38);
  position: absolute;
  font-size: 12px;
  margin-top: 3px;
  visibility: hidden;
  display: block; }
  .mdl-textfield.is-invalid .mdl-textfield__error {
    visibility: visible; }

.mdl-textfield__expandable-holder {
  display: inline-block;
  position: relative;
  margin-left: 32px;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  display: inline-block;
  max-width: 0.1px; }
  .mdl-textfield.is-focused .mdl-textfield__expandable-holder, .mdl-textfield.is-dirty .mdl-textfield__expandable-holder {
    max-width: 600px; }
  .mdl-textfield__expandable-holder .mdl-textfield__label:after {
    bottom: 0; }

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* TOOLTIP */
.mdl-tooltip {
  transform: scale(0);
  transform-origin: top center;
  will-change: transform;
  z-index: 999;
  background: rgba(97,97,97, 0.9);
  border-radius: 2px;
  color: rgb(255,255,255);
  display: inline-block;
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  max-width: 170px;
  position: fixed;
  top: -500px;
  left: -500px;
  padding: 8px;
  text-align: center; }

.mdl-tooltip.is-active {
  animation: pulse 200ms cubic-bezier(0, 0, 0.2, 1) forwards; }

.mdl-tooltip--large {
  line-height: 14px;
  font-size: 14px;
  padding: 16px; }

@keyframes pulse {
  0% {
    transform: scale(0);
    opacity: 0; }
  50% {
    transform: scale(0.99); }
  100% {
    transform: scale(1);
    opacity: 1;
    visibility: visible; } }

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* TOOLTIP */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
.mdl-shadow--2dp {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12); }

.mdl-shadow--3dp {
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 1px 8px 0 rgba(0, 0, 0, 0.12); }

.mdl-shadow--4dp {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2); }

.mdl-shadow--6dp {
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2); }

.mdl-shadow--8dp {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2); }

.mdl-shadow--16dp {
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2); }

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*
* NOTE: Some rules here are applied using duplicate selectors.
* This is on purpose to increase their specificity when applied.
* For example: `.mdl-cell--1-col-phone.mdl-cell--1-col-phone`
*/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* TOOLTIP */
.mdl-grid {
  display: flex;
  flex-flow: row wrap;
  margin: 0 auto 0 auto;
  align-items: stretch; }
  .mdl-grid.mdl-grid--no-spacing {
    padding: 0; }

.mdl-cell {
  box-sizing: border-box; }

.mdl-cell--top {
  align-self: flex-start; }

.mdl-cell--middle {
  align-self: center; }

.mdl-cell--bottom {
  align-self: flex-end; }

.mdl-cell--stretch {
  align-self: stretch; }

.mdl-grid.mdl-grid--no-spacing > .mdl-cell {
  margin: 0; }

@media (max-width: 479px) {
  .mdl-grid {
    padding: 8px; }
  .mdl-cell {
    margin: 8px;
    width: calc(100% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell {
      width: 100%; }
  .mdl-cell--hide-phone {
    display: none !important; }
  .mdl-cell--1-col,
  .mdl-cell--1-col-phone.mdl-cell--1-col-phone {
    width: calc(25% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--1-col, .mdl-grid--no-spacing >
    .mdl-cell--1-col-phone.mdl-cell--1-col-phone {
      width: 25%; }
  .mdl-cell--2-col,
  .mdl-cell--2-col-phone.mdl-cell--2-col-phone {
    width: calc(50% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--2-col, .mdl-grid--no-spacing >
    .mdl-cell--2-col-phone.mdl-cell--2-col-phone {
      width: 50%; }
  .mdl-cell--3-col,
  .mdl-cell--3-col-phone.mdl-cell--3-col-phone {
    width: calc(75% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--3-col, .mdl-grid--no-spacing >
    .mdl-cell--3-col-phone.mdl-cell--3-col-phone {
      width: 75%; }
  .mdl-cell--4-col,
  .mdl-cell--4-col-phone.mdl-cell--4-col-phone {
    width: calc(100% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--4-col, .mdl-grid--no-spacing >
    .mdl-cell--4-col-phone.mdl-cell--4-col-phone {
      width: 100%; }
  .mdl-cell--5-col,
  .mdl-cell--5-col-phone.mdl-cell--5-col-phone {
    width: calc(100% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--5-col, .mdl-grid--no-spacing >
    .mdl-cell--5-col-phone.mdl-cell--5-col-phone {
      width: 100%; }
  .mdl-cell--6-col,
  .mdl-cell--6-col-phone.mdl-cell--6-col-phone {
    width: calc(100% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--6-col, .mdl-grid--no-spacing >
    .mdl-cell--6-col-phone.mdl-cell--6-col-phone {
      width: 100%; }
  .mdl-cell--7-col,
  .mdl-cell--7-col-phone.mdl-cell--7-col-phone {
    width: calc(100% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--7-col, .mdl-grid--no-spacing >
    .mdl-cell--7-col-phone.mdl-cell--7-col-phone {
      width: 100%; }
  .mdl-cell--8-col,
  .mdl-cell--8-col-phone.mdl-cell--8-col-phone {
    width: calc(100% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--8-col, .mdl-grid--no-spacing >
    .mdl-cell--8-col-phone.mdl-cell--8-col-phone {
      width: 100%; }
  .mdl-cell--9-col,
  .mdl-cell--9-col-phone.mdl-cell--9-col-phone {
    width: calc(100% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--9-col, .mdl-grid--no-spacing >
    .mdl-cell--9-col-phone.mdl-cell--9-col-phone {
      width: 100%; }
  .mdl-cell--10-col,
  .mdl-cell--10-col-phone.mdl-cell--10-col-phone {
    width: calc(100% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--10-col, .mdl-grid--no-spacing >
    .mdl-cell--10-col-phone.mdl-cell--10-col-phone {
      width: 100%; }
  .mdl-cell--11-col,
  .mdl-cell--11-col-phone.mdl-cell--11-col-phone {
    width: calc(100% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--11-col, .mdl-grid--no-spacing >
    .mdl-cell--11-col-phone.mdl-cell--11-col-phone {
      width: 100%; }
  .mdl-cell--12-col,
  .mdl-cell--12-col-phone.mdl-cell--12-col-phone {
    width: calc(100% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--12-col, .mdl-grid--no-spacing >
    .mdl-cell--12-col-phone.mdl-cell--12-col-phone {
      width: 100%; } }

@media (min-width: 480px) and (max-width: 839px) {
  .mdl-grid {
    padding: 8px; }
  .mdl-cell {
    margin: 8px;
    width: calc(50% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell {
      width: 50%; }
  .mdl-cell--hide-tablet {
    display: none !important; }
  .mdl-cell--1-col,
  .mdl-cell--1-col-tablet.mdl-cell--1-col-tablet {
    width: calc(12.5% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--1-col, .mdl-grid--no-spacing >
    .mdl-cell--1-col-tablet.mdl-cell--1-col-tablet {
      width: 12.5%; }
  .mdl-cell--2-col,
  .mdl-cell--2-col-tablet.mdl-cell--2-col-tablet {
    width: calc(25% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--2-col, .mdl-grid--no-spacing >
    .mdl-cell--2-col-tablet.mdl-cell--2-col-tablet {
      width: 25%; }
  .mdl-cell--3-col,
  .mdl-cell--3-col-tablet.mdl-cell--3-col-tablet {
    width: calc(37.5% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--3-col, .mdl-grid--no-spacing >
    .mdl-cell--3-col-tablet.mdl-cell--3-col-tablet {
      width: 37.5%; }
  .mdl-cell--4-col,
  .mdl-cell--4-col-tablet.mdl-cell--4-col-tablet {
    width: calc(50% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--4-col, .mdl-grid--no-spacing >
    .mdl-cell--4-col-tablet.mdl-cell--4-col-tablet {
      width: 50%; }
  .mdl-cell--5-col,
  .mdl-cell--5-col-tablet.mdl-cell--5-col-tablet {
    width: calc(62.5% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--5-col, .mdl-grid--no-spacing >
    .mdl-cell--5-col-tablet.mdl-cell--5-col-tablet {
      width: 62.5%; }
  .mdl-cell--6-col,
  .mdl-cell--6-col-tablet.mdl-cell--6-col-tablet {
    width: calc(75% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--6-col, .mdl-grid--no-spacing >
    .mdl-cell--6-col-tablet.mdl-cell--6-col-tablet {
      width: 75%; }
  .mdl-cell--7-col,
  .mdl-cell--7-col-tablet.mdl-cell--7-col-tablet {
    width: calc(87.5% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--7-col, .mdl-grid--no-spacing >
    .mdl-cell--7-col-tablet.mdl-cell--7-col-tablet {
      width: 87.5%; }
  .mdl-cell--8-col,
  .mdl-cell--8-col-tablet.mdl-cell--8-col-tablet {
    width: calc(100% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--8-col, .mdl-grid--no-spacing >
    .mdl-cell--8-col-tablet.mdl-cell--8-col-tablet {
      width: 100%; }
  .mdl-cell--9-col,
  .mdl-cell--9-col-tablet.mdl-cell--9-col-tablet {
    width: calc(100% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--9-col, .mdl-grid--no-spacing >
    .mdl-cell--9-col-tablet.mdl-cell--9-col-tablet {
      width: 100%; }
  .mdl-cell--10-col,
  .mdl-cell--10-col-tablet.mdl-cell--10-col-tablet {
    width: calc(100% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--10-col, .mdl-grid--no-spacing >
    .mdl-cell--10-col-tablet.mdl-cell--10-col-tablet {
      width: 100%; }
  .mdl-cell--11-col,
  .mdl-cell--11-col-tablet.mdl-cell--11-col-tablet {
    width: calc(100% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--11-col, .mdl-grid--no-spacing >
    .mdl-cell--11-col-tablet.mdl-cell--11-col-tablet {
      width: 100%; }
  .mdl-cell--12-col,
  .mdl-cell--12-col-tablet.mdl-cell--12-col-tablet {
    width: calc(100% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--12-col, .mdl-grid--no-spacing >
    .mdl-cell--12-col-tablet.mdl-cell--12-col-tablet {
      width: 100%; } }

@media (min-width: 840px) {
  .mdl-grid {
    padding: 8px; }
  .mdl-cell {
    margin: 8px;
    width: calc(33.3333333333% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell {
      width: 33.3333333333%; }
  .mdl-cell--hide-desktop {
    display: none !important; }
  .mdl-cell--1-col,
  .mdl-cell--1-col-desktop.mdl-cell--1-col-desktop {
    width: calc(8.3333333333% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--1-col, .mdl-grid--no-spacing >
    .mdl-cell--1-col-desktop.mdl-cell--1-col-desktop {
      width: 8.3333333333%; }
  .mdl-cell--2-col,
  .mdl-cell--2-col-desktop.mdl-cell--2-col-desktop {
    width: calc(16.6666666667% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--2-col, .mdl-grid--no-spacing >
    .mdl-cell--2-col-desktop.mdl-cell--2-col-desktop {
      width: 16.6666666667%; }
  .mdl-cell--3-col,
  .mdl-cell--3-col-desktop.mdl-cell--3-col-desktop {
    width: calc(25% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--3-col, .mdl-grid--no-spacing >
    .mdl-cell--3-col-desktop.mdl-cell--3-col-desktop {
      width: 25%; }
  .mdl-cell--4-col,
  .mdl-cell--4-col-desktop.mdl-cell--4-col-desktop {
    width: calc(33.3333333333% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--4-col, .mdl-grid--no-spacing >
    .mdl-cell--4-col-desktop.mdl-cell--4-col-desktop {
      width: 33.3333333333%; }
  .mdl-cell--5-col,
  .mdl-cell--5-col-desktop.mdl-cell--5-col-desktop {
    width: calc(41.6666666667% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--5-col, .mdl-grid--no-spacing >
    .mdl-cell--5-col-desktop.mdl-cell--5-col-desktop {
      width: 41.6666666667%; }
  .mdl-cell--6-col,
  .mdl-cell--6-col-desktop.mdl-cell--6-col-desktop {
    width: calc(50% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--6-col, .mdl-grid--no-spacing >
    .mdl-cell--6-col-desktop.mdl-cell--6-col-desktop {
      width: 50%; }
  .mdl-cell--7-col,
  .mdl-cell--7-col-desktop.mdl-cell--7-col-desktop {
    width: calc(58.3333333333% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--7-col, .mdl-grid--no-spacing >
    .mdl-cell--7-col-desktop.mdl-cell--7-col-desktop {
      width: 58.3333333333%; }
  .mdl-cell--8-col,
  .mdl-cell--8-col-desktop.mdl-cell--8-col-desktop {
    width: calc(66.6666666667% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--8-col, .mdl-grid--no-spacing >
    .mdl-cell--8-col-desktop.mdl-cell--8-col-desktop {
      width: 66.6666666667%; }
  .mdl-cell--9-col,
  .mdl-cell--9-col-desktop.mdl-cell--9-col-desktop {
    width: calc(75% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--9-col, .mdl-grid--no-spacing >
    .mdl-cell--9-col-desktop.mdl-cell--9-col-desktop {
      width: 75%; }
  .mdl-cell--10-col,
  .mdl-cell--10-col-desktop.mdl-cell--10-col-desktop {
    width: calc(83.3333333333% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--10-col, .mdl-grid--no-spacing >
    .mdl-cell--10-col-desktop.mdl-cell--10-col-desktop {
      width: 83.3333333333%; }
  .mdl-cell--11-col,
  .mdl-cell--11-col-desktop.mdl-cell--11-col-desktop {
    width: calc(91.6666666667% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--11-col, .mdl-grid--no-spacing >
    .mdl-cell--11-col-desktop.mdl-cell--11-col-desktop {
      width: 91.6666666667%; }
  .mdl-cell--12-col,
  .mdl-cell--12-col-desktop.mdl-cell--12-col-desktop {
    width: calc(100% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--12-col, .mdl-grid--no-spacing >
    .mdl-cell--12-col-desktop.mdl-cell--12-col-desktop {
      width: 100%; } }

html {
  font-size: 10px;
  -webkit-tap-highlight-color: transparent; }

body {
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 13px;
  line-height: 1.846;
  color: #666;
  background-color: #fff; }

.animate-repeat {
  line-height: 40px;
  list-style: none;
  /*box-sizing:border-box;*/
  border-bottom: 1px solid white;
  -webkit-transition: 1s linear all;
  transition: 1s linear all; }

.animate-repeat.ng-move,
.animate-repeat.ng-enter,
.animate-repeat.ng-leave {
  -webkit-transition: all linear 0.2s;
  transition: all linear 0.2s; }

.animate-repeat.ng-leave.ng-leave-active,
.animate-repeat.ng-move,
.animate-repeat.ng-enter {
  opacity: 0;
  max-height: 0; }

.animate-repeat.ng-leave,
.animate-repeat.ng-move.ng-move-active,
.animate-repeat.ng-enter.ng-enter-active {
  opacity: 1;
  max-height: 40px; }

html, body {
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif; }

*, *:before, *:after {
  box-sizing: border-box; }

a {
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  text-decoration: none;
  color: #058494; }

.list-bordered {
  list-style: none;
  margin: 0px;
  padding: 0px; }
  .list-bordered li {
    border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
    -webkit-transition: all 0.1s;
    -o-transition: all 0.1s;
    transition: all 0.1s; }
    .list-bordered li a {
      display: block;
      padding: 5px; }
      .list-bordered li a:hover {
        padding-left: 10px; }

img[src*=svg] {
  width: 45px;
  height: 100%; }

.icon-circle {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  border-width: 4px;
  border-style: solid;
  display: inline-flex;
  padding: 0px !important; }

.icon-color {
  width: 35px;
  height: 35px;
  font-size: 20px;
  line-height: 35px;
  box-shadow: inset 0px -1px 3px rgba(0, 0, 0, 0.08);
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  text-align: center;
  border-radius: 50%;
  -webkit-transition: all 0.1s;
  -o-transition: all 0.1s;
  transition: all 0.1s;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1); }
  .icon-color:hover {
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
    transform: scale(1.05);
    border-radius: 10px; }

.section-content ul {
  border-left: solid 3px #C0EbF1;
  padding-left: 20px;
  line-height: 28px; }

.section-content a {
  font-weight: 400;
  color: #00BCD4; }

.brand-logo {
  min-height: 64px;
  color: #868686;
  line-height: 64px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 16px; }

.logo {
  display: inline-block;
  position: relative;
  height: 19px;
  width: 40px; }
  .logo .foot1, .logo .foot2, .logo .foot3, .logo .foot4 {
    position: absolute;
    width: 10px;
    height: 22px; }
  .logo .foot1, .logo .foot3 {
    -webkit-transform: skewX(-20deg) skewY(0deg);
    -ms-transform: skewX(-20deg) skewY(0deg);
    -o-transform: skewX(-20deg) skewY(0deg);
    transform: skewX(-20deg) skewY(0deg);
    z-index: 1;
    background-color: #CB0B4D; }
  .logo .foot3 {
    left: 16px; }
  .logo .foot2, .logo .foot4, .logo .foot5 {
    background-color: #e91e63; }
  .logo .foot2, .logo .foot4 {
    left: 8px;
    -webkit-transform: skewX(20deg) skewY(0deg);
    -ms-transform: skewX(20deg) skewY(0deg);
    -o-transform: skewX(20deg) skewY(0deg);
    transform: skewX(20deg) skewY(0deg);
    z-index: 0; }
  .logo .foot4 {
    left: 24px; }
  .logo .foot5 {
    position: absolute;
    width: 15px;
    height: 5px;
    bottom: -3px;
    right: -2px;
    transform: skewX(20deg) skewY(0deg); }

.ml-list-actions {
  list-style: none;
  margin: 0px;
  padding: 0px; }
  .ml-list-actions li i {
    float: right;
    padding: 14px 20px 0 0;
    font-size: 20px; }
  .ml-list-actions li.active {
    border-left: 2px #4dd0e1 solid;
    background-color: rgba(0, 0, 0, 0.03); }
  .ml-list-actions li a {
    padding: 12px 20px;
    display: block;
    font-size: 18px;
    font-weight: 300;
    border-bottom: 1px whitesmoke solid; }
    .ml-list-actions li a:hover {
      background-color: rgba(0, 0, 0, 0.05); }

.ml-data-table td:first-child {
  padding: 10px; }

.ml-data-table td img {
  max-width: 100%; }

.ml-list-actions-text {
  list-style: none;
  margin: 0px;
  padding: 0px; }
  .ml-list-actions-text .ml-list-icon {
    padding: 5px 10px 5px 15px; }
    .ml-list-actions-text .ml-list-icon img {
      max-height: 40px; }
  .ml-list-actions-text li i {
    float: right;
    padding: 15px 15px 0 0;
    font-size: 20px; }
  .ml-list-actions-text li.active {
    border-left: 2px #4dd0e1 solid;
    background-color: rgba(0, 0, 0, 0.03); }
  .ml-list-actions-text li a {
    padding: 10px 20px;
    display: block;
    font-size: 18px;
    font-weight: 300;
    border-bottom: 1px whitesmoke solid;
    line-height: 16px; }
    .ml-list-actions-text li a:hover {
      background-color: rgba(0, 0, 0, 0.05); }

.ml-seperator {
  margin: 0% auto;
  padding: 10px; }
  .ml-seperator::before, .ml-seperator::after {
    background-color: #D7CFD8;
    display: inline-block;
    vertical-align: middle;
    content: "";
    width: 70px;
    height: 1px; }
  .ml-seperator .ml-seperator-label {
    position: relative;
    display: inline-block;
    color: #CECECE;
    font-size: 12px; }

.ml-header {
  min-height: 180px; }

.ml-button-icon {
  font-size: 14px;
  line-height: 16px;
  vertical-align: text-bottom; }

::-webkit-scrollbar {
  width: 10px;
  background-color: transparent;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent; }

::-webkit-scrollbar:hover {
  background-color: rgba(0, 0, 0, 0.05); }

::-webkit-scrollbar-thumb:vertical {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 100px;
  background-clip: padding-box;
  border: 2px solid transparent;
  min-height: 10px; }

::-webkit-scrollbar-thumb:vertical:active {
  background: rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 100px; }

.mdl-layout__drawer::-webkit-scrollbar {
  width: 10px;
  background-color: #263238;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent; }

.mdl-layout__drawer::-webkit-scrollbar:hover {
  background: rgba(255, 255, 255, 0.5); }

.mdl-layout__drawer::-webkit-scrollbar-thumb:vertical {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 100px;
  background-clip: padding-box;
  border: 2px solid transparent;
  min-height: 10px; }

.mdl-layout__drawer::-webkit-scrollbar-thumb:vertical:active {
  background: rgba(255, 255, 255, 0.5);
  -webkit-border-radius: 100px; }

.ml-button--sm {
  font-size: 12px;
  line-height: 26px;
  height: auto;
  padding: 0 12px; }

.ml-table-striped > tbody > tr:nth-of-type(even) {
  background-color: #f9f9f9; }

.ml-table-bordered > thead > tr > td,
.ml-table-bordered > tbody > tr > td {
  border: 1px solid #F0F0F0; }

.ml-data-table-pager .mdl-button {
  min-width: auto; }

.ng-table th.sortable.sort-desc, .ng-table th.sortable.sort-asc {
  background-color: inherit; }

.ml-card-holder {
  position: relative; }
  .ml-card-holder.ml-card-holder-first {
    margin-top: -81px; }
  @media (max-width: 992px) {
    .ml-card-holder {
      margin-top: 0px; } }

.ml-list-profile .material-icons {
  padding: 10px 10px 0 0; }

.ml-card-horizontal {
  flex-flow: row wrap;
  min-height: auto;
  height: auto; }
  .ml-card-horizontal .ml-card-horizontal--image {
    flex: 1 auto;
    order: 1;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover; }
  .ml-card-horizontal .ml-card-horizontal--content {
    flex: 3 0px;
    order: 2; }

@media screen and (max-width: 850px) {
  .ml-card-holder.ml-card-holder-first {
    margin-top: 0px; } }

.fade {
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear; }
  .fade.in {
    opacity: 1; }

.collapse {
  display: none; }
  .collapse.in {
    display: block; }

tr.collapse.in {
  display: table-row; }

tbody.collapse.in {
  display: table-row-group; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition-property: height, visibility;
  transition-property: height, visibility;
  -webkit-transition-duration: 0.15s;
  transition-duration: 0.15s;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: inherit;
  font-weight: 400;
  line-height: 1.1;
  color: #444; }
  h1 small,
  h1 .small, h2 small,
  h2 .small, h3 small,
  h3 .small, h4 small,
  h4 .small, h5 small,
  h5 .small, h6 small,
  h6 .small,
  .h1 small,
  .h1 .small, .h2 small,
  .h2 .small, .h3 small,
  .h3 .small, .h4 small,
  .h4 .small, .h5 small,
  .h5 .small, .h6 small,
  .h6 .small {
    font-weight: normal;
    line-height: 1;
    color: #bbb; }

h1, .h1,
h2, .h2,
h3, .h3 {
  margin-top: 23px;
  margin-bottom: 11.5px; }
  h1 small,
  h1 .small, .h1 small,
  .h1 .small,
  h2 small,
  h2 .small, .h2 small,
  .h2 .small,
  h3 small,
  h3 .small, .h3 small,
  .h3 .small {
    font-size: 65%; }

h4, .h4,
h5, .h5,
h6, .h6 {
  margin-top: 11.5px;
  margin-bottom: 11.5px; }
  h4 small,
  h4 .small, .h4 small,
  .h4 .small,
  h5 small,
  h5 .small, .h5 small,
  .h5 .small,
  h6 small,
  h6 .small, .h6 small,
  .h6 .small {
    font-size: 75%; }

h1, .h1 {
  font-size: 33px; }

h2, .h2 {
  font-size: 27px; }

h3, .h3 {
  font-size: 23px; }

h4, .h4 {
  font-size: 17px; }

h5, .h5 {
  font-size: 13px; }

h6, .h6 {
  font-size: 12px; }

p {
  margin: 0 0 11.5px; }

.lead {
  margin-bottom: 23px;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.4; }

small,
.small {
  font-size: 92%; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-justify {
  text-align: justify; }

.text-nowrap {
  white-space: nowrap; }

.text-lowercase {
  text-transform: lowercase; }

.text-uppercase, .initialism {
  text-transform: uppercase; }

.text-capitalize {
  text-transform: capitalize; }

ul,
ol {
  margin-top: 0;
  margin-bottom: 11.5px; }
  ul ul,
  ul ol,
  ol ul,
  ol ol {
    margin-bottom: 0; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none;
  margin-left: -5px; }
  .list-inline > li {
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px; }

dl {
  margin-top: 0;
  margin-bottom: 23px; }

dt,
dd {
  line-height: 1.846; }

dt {
  font-weight: bold; }

dd {
  margin-left: 0; }

.dl-horizontal dd:before, .dl-horizontal dd:after {
  content: " ";
  display: table; }

.dl-horizontal dd:after {
  clear: both; }

abbr[title],
abbr[data-original-title] {
  cursor: help;
  border-bottom: 1px dotted #bbb; }

.initialism {
  font-size: 90%; }

blockquote {
  font-size: 16.25px; }
  blockquote p:last-child,
  blockquote ul:last-child,
  blockquote ol:last-child {
    margin-bottom: 0; }
  blockquote footer,
  blockquote small,
  blockquote .small {
    display: block;
    font-size: 80%;
    line-height: 1.846;
    color: #bbb; }
    blockquote footer:before,
    blockquote small:before,
    blockquote .small:before {
      content: '\2014 \00A0'; }

.blockquote-reverse,
blockquote.pull-right {
  padding-right: 15px;
  padding-left: 0;
  border-right: 5px solid #eeeeee;
  border-left: 0;
  text-align: right; }
  .blockquote-reverse footer:before,
  .blockquote-reverse small:before,
  .blockquote-reverse .small:before,
  blockquote.pull-right footer:before,
  blockquote.pull-right small:before,
  blockquote.pull-right .small:before {
    content: ''; }
  .blockquote-reverse footer:after,
  .blockquote-reverse small:after,
  .blockquote-reverse .small:after,
  blockquote.pull-right footer:after,
  blockquote.pull-right small:after,
  blockquote.pull-right .small:after {
    content: '\00A0 \2014'; }

address {
  margin-bottom: 23px;
  font-style: normal;
  line-height: 1.846; }

.mdl-layout__drawer .mdl-navigation li {
  position: relative;
  line-height: 0;
  flex-shrink: 0; }
  .mdl-layout__drawer .mdl-navigation li.active .mdl-navigation__link {
    padding-left: 20px; }
  .mdl-layout__drawer .mdl-navigation li li.active .mdl-navigation__link {
    padding-left: 48px; }
  .mdl-layout__drawer .mdl-navigation li.active a:after {
    content: '';
    position: absolute;
    width: 5px;
    height: 5px;
    border-width: 5px;
    border-radius: 50%;
    border-color: #8BC161;
    border-style: solid;
    top: 12px;
    left: 30px; }

.mdl-layout__drawer .mdl-navigation li li a.mdl-navigation__link {
  padding: 8px 20px 8px 48px; }

.page {
  bottom: 0;
  position: absolute;
  top: 0;
  width: calc(100% - 240px);
  margin-left: 240px;
  margin-top: 64px; }

.page.ng-leave {
  z-index: 3;
  animation: fadeOut .5s both ease-in; }
  .page.ng-leave .ml-card-holder {
    animation: fadeOutDown .5s both ease-in; }
  .page.ng-leave .ml-header h3 {
    animation: fadeOutLeft 0.4s both ease-in; }
  .page.ng-leave .ml-header h4 {
    animation: fadeOutLeft 0.4s both ease-in;
    animation-delay: 0.1s; }

.page.ng-enter {
  z-index: 4;
  opacity: 0;
  animation: fadeIn .5s both ease-in; }
  .page.ng-enter .ml-card-holder {
    animation: fadeInUp .5s both ease-in; }
  .page.ng-enter .ml-header h3 {
    animation: fadeInLeft 0.4s both ease-in; }
  .page.ng-enter .ml-header h4 {
    animation: fadeInLeft 0.4s both ease-in;
    animation-delay: 0.1s; }

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-moz-keyframes fadeOut {
  0% {
    opacity: 1; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-ms-keyframes fadeOut {
  0% {
    opacity: 1; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-webkit-keyframes fadeOutDown {
  0% { }
  50% {
    transform: translateY(10px);
    opacity: 0; }
  100% {
    opacity: 0; } }

@-moz-keyframes fadeOutDown {
  0% { }
  50% {
    transform: translateY(10px);
    opacity: 0; }
  100% {
    opacity: 0; } }

@-ms-keyframes fadeOutDown {
  50% {
    transform: translateY(10px);
    opacity: 0; }
  100% {
    opacity: 0; } }

@keyframes fadeOutDown {
  0% { }
  50% {
    transform: translateY(10px);
    opacity: 0; }
  100% {
    opacity: 0; } }

@-webkit-keyframes fadeIn {
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-moz-keyframes fadeIn {
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-ms-keyframes fadeIn {
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0; }
  50% {
    opacity: 0;
    transform: translateY(10px); }
  100% {
    opacity: 1;
    transform: translateY(0px); } }

@-moz-keyframes fadeInUp {
  0% {
    opacity: 0; }
  50% {
    opacity: 0;
    transform: translateY(10px); }
  100% {
    opacity: 1;
    transform: translateY(0px); } }

@-ms-keyframes fadeInUp {
  0% {
    opacity: 0; }
  50% {
    opacity: 0;
    transform: translateY(10px); }
  100% {
    opacity: 1;
    transform: translateY(0px); } }

@keyframes fadeInUp {
  0% {
    opacity: 0; }
  50% {
    opacity: 0;
    transform: translateY(10px); }
  100% {
    opacity: 1;
    transform: translateY(0px); } }

@-webkit-keyframes fadeInLeft {
  0% {
    transform: translateX(-30px);
    opacity: 0; }
  100% {
    transform: translateX(0px);
    opacity: 1; } }

@-moz-keyframes fadeInLeft {
  0% {
    transform: translateX(-30px);
    opacity: 0; }
  100% {
    transform: translateX(0px);
    opacity: 1; } }

@-ms-keyframes fadeInLeft {
  0% {
    transform: translateX(-30px);
    opacity: 0; }
  100% {
    transform: translateX(0px);
    opacity: 1; } }

@keyframes fadeInLeft {
  0% {
    transform: translateX(-30px);
    opacity: 0; }
  100% {
    transform: translateX(0px);
    opacity: 1; } }

@-webkit-keyframes fadeOutLeft {
  100% {
    transform: translateX(-30px);
    opacity: 0; } }

@-moz-keyframes fadeOutLeft {
  100% {
    transform: translateX(-30px);
    opacity: 0; } }

@-ms-keyframes fadeOutLeft {
  100% {
    transform: translateX(-30px);
    opacity: 0; } }

@keyframes fadeOutLeft {
  100% {
    transform: translateX(-30px);
    opacity: 0; } }

@media screen and (max-width: 850px) {
  .sticky {
    position: static !important; } }

.mdl-layout__header {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 4px 0 rgba(0, 0, 0, 0.12); }

.mdl-layout__header-row {
  padding: 0 30px; }

.mdl-layout__header-row button {
  margin-left: 10px; }

.mdl-layout-title {
  font-size: 16px; }

.mdl-shadow--0dp {
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.04), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.02); }

.mdl-shadow--1dp {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12); }

.mdl-grid {
  display: flex;
  flex-flow: row wrap;
  margin: 0 auto; }
  .mdl-grid.mdl-grid-p-15 {
    padding: 15px; }

.mdl-cell {
  padding-left: 15px; }

.mdl-grid .mdl-cell:first-child {
  padding-left: 0px; }

.mdl-button {
  padding: 0 15px; }

.mdl-button--block {
  display: block;
  width: 100%; }

.mdl-badge.material-icons:after {
  right: -10px; }

.mdl-menu {
  padding: 0px; }

.mdl-layout__drawer {
  border: none; }

.mdl-layout__drawer .mdl-navigation {
  padding: 0px; }

.mdl-layout__drawer .mdl-navigation .mdl-navigation__link {
  display: flex !important;
  flex-direction: row;
  line-height: 20px;
  padding: 13px 20px; }

.mdl-layout__drawer .mdl-navigation .mdl-navigation__link:hover {
  background-color: rgba(255, 255, 255, 0.2);
  color: white; }

.mdl-data-table {
  border: 0px;
  display: block;
  overflow: auto; }
  .mdl-data-table thead {
    background: #FAFAFA; }
  .mdl-data-table tbody tr {
    -webkit-transition: none;
    -o-transition: none;
    transition: none; }
  .mdl-data-table tbody tr:hover {
    background-color: rgba(77, 208, 225, 0.1); }
  .mdl-data-table td {
    border-top: 1px solid rgba(0, 0, 0, 0.04);
    border-bottom: 1px solid rgba(0, 0, 0, 0.04);
    text-align: left; }

.mdl-tooltip.is-active {
  transform: scale(1); }

.mdl-textfield__input {
  outline: none; }

.mdl-textfield {
  width: auto;
  display: block; }

.mdl-textfield__label {
  top: 20px; }

.mdl-data-table th .mdl-data-table__select {
  position: inherit;
  left: auto;
  bottom: auto; }

.mdl-card__title {
  padding: 16px 20px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover; }

.mdl-card {
  width: auto;
  border-radius: 3px;
  overflow: visible;
  z-index: auto; }

.mdl-card__menu.mdl-card__menu-left {
  right: auto;
  left: 16px; }

.mdl-card__supporting-text {
  width: 100%; }

.mdl-card__title-icon img {
  height: 35px;
  margin-right: 10px; }

.mdl-card__title-icon .mdl-card__title-text {
  line-height: 35px; }

.mdl-card__media-image {
  bottom: -40px;
  position: absolute;
  width: 80px;
  left: 20px; }

.mdl-card__media-button {
  position: absolute;
  right: 30px;
  bottom: -25px; }

.mdl-card__text {
  padding: 20px; }

.mdl-card__actions {
  display: flex; }
  .mdl-card__actions > i {
    padding: 6px 10px; }

.mdl-button__ripple-container {
  z-index: -1; }

.mdl-ripple {
  background: rgba(0, 0, 0, 0.37); }

.demo-drawer {
  overflow-x: hidden;
  overflow-y: auto; }

.demo-navigation .mdl-navigation__link .material-icons {
  margin-right: 10px;
  font-size: 18px;
  color: #bababa !important; }

.demo-avatar-dropdown {
  display: flex;
  flex-direction: row;
  padding: 7px 10px 7px 20px;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5); }

.demo-avatar-dropdown span {
  line-height: 30px; }

.demo-header-color {
  background: #b0bec5; }

.mdl-layout__drawer-button i {
  color: #000; }

.mdl-progress {
  width: auto; }

@media screen and (max-width: 1024px) {
  .mdl-layout__header-row {
    margin-left: 30px;
    height: 64px; }
  .mdl-layout__content {
    width: 100%; }
  .mdl-layout__header .mdl-layout__drawer-button {
    margin: 10px; } }

@media (max-width: 839px) {
  .mdl-cell {
    padding-left: 0px; } }

@media (max-width: 479px) {
  .mdl-grid {
    padding: 0px; }
  .mdl-cell {
    margin: 0px;
    margin-bottom: 15px; }
  div[class^="mdl-cell--"], div[class*=" mdl-cell--"] {
    width: 100% !important; } }

.ml-chat-widget .mdl-card__menu {
  top: 10px; }

.chat .mdl-card {
  position: relative; }

.chat .chat-container {
  padding-bottom: 70px; }

.chat .chat-input-footer, .chat .chat-input {
  background: #fafafa; }

.chat .chat-input-footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 0 20px 20px 20px; }

.chat .chat-input-container form {
  margin-right: 45px; }

.chat .chat-input-container .send {
  right: 0;
  bottom: -5px; }
  .chat .chat-input-container .send .mdl-button--icon {
    width: 40px;
    height: 40px; }
    .chat .chat-input-container .send .mdl-button--icon .material-icons {
      font-size: 25px;
      width: 29px; }

.chat-container {
  background-color: #f1f1f1;
  min-height: 390px;
  background-size: contain; }

.chat-input-container {
  height: 50px;
  position: relative; }
  .chat-input-container .send {
    position: absolute;
    right: 20px;
    bottom: 10px; }

.chat-input {
  height: 50px;
  border: none;
  width: calc(100% - 64px);
  padding: 15px;
  resize: none;
  overflow: scroll;
  font-weight: 300;
  font-size: 16px;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none; }
  .chat-input:focus {
    outline: none; }
  .chat-input::-webkit-scrollbar {
    width: 0 !important; }

.message-wrapper {
  position: relative;
  overflow: hidden;
  padding-bottom: 20px;
  padding-top: 5px; }
  .message-wrapper .circle-wrapper {
    background: white;
    border-radius: 50%;
    padding: 2px;
    height: 42px;
    width: 42px; }
  .message-wrapper .text-wrapper {
    padding: 10.6666666667px;
    min-height: 42.6666666667px;
    width: calc(100% - 65px);
    margin: 0 10.6666666667px;
    box-shadow: 0px 1px 0px 0px rgba(50, 50, 50, 0.3);
    border-radius: 2px;
    font-weight: 300;
    position: relative;
    opacity: 0; }
    .message-wrapper .text-wrapper:before {
      content: '';
      width: 0;
      height: 0;
      border-style: solid; }
  .message-wrapper.them .circle-wrapper, .message-wrapper.them .text-wrapper {
    float: left;
    color: white; }
  .message-wrapper.them .text-wrapper {
    background: #D2D2D2; }
    .message-wrapper.them .text-wrapper:before {
      border-width: 0 10px 10px 0;
      border-color: transparent #D2D2D2 transparent transparent;
      position: absolute;
      top: 0;
      left: -9px; }
  .message-wrapper.me .circle-wrapper, .message-wrapper.me .text-wrapper {
    float: right;
    color: black; }
  .message-wrapper.me .text-wrapper {
    background: white; }
    .message-wrapper.me .text-wrapper:before {
      border-width: 10px 10px 0 0;
      border-color: white transparent transparent transparent;
      position: absolute;
      top: 0;
      right: -9px; }

ml-svg-map svg path {
  stroke-width: 2;
  stroke: #fff;
  -webkit-transition: stroke 0.5s, stroke-width 0.5s;
  -o-transition: stroke 0.5s, stroke-width 0.5s;
  transition: stroke 0.5s, stroke-width 0.5s; }

ml-svg-map svg path:hover, ml-svg-map path.active {
  cursor: pointer;
  stroke-width: 4;
  stroke: #CCC; }

.badges .ml-card-holder .material-icons {
  font-size: 32px; }

.badges .ml-card-holder .demo-badge-2[data-badge]:after {
  background-color: #e91e63; }

.badges .ml-card-holder .demo-badge-3[data-badge]:after {
  background-color: #9c27b0; }

.badges .ml-card-holder .demo-badge-4[data-badge]:after {
  background-color: #f44336; }

.badges .ml-card-holder .demo-badge-5[data-badge]:after {
  background-color: #4caf50; }

.badges .ml-card-holder .demo-badge-6[data-badge]:after {
  background-color: #ff9800; }

.dynamic-color > div > div {
  padding: 5px 20px;
  cursor: pointer;
  -webkit-transition: all 0.1s;
  -o-transition: all 0.1s;
  transition: all 0.1s;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1); }
  .dynamic-color > div > div:hover {
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
    transform: scale(1.05); }

.dynamic-color > div {
  margin-bottom: 40px; }

.dynamic-color .mdl-cell {
  padding-left: 0px; }

.demo-grid-ruler .mdl-cell,
.demo-grid-1 .mdl-cell,
.demo-grid-2 .mdl-cell,
.demo-grid-3 .mdl-cell,
.demo-grid-4 .mdl-cell {
  box-sizing: border-box;
  background-color: #BDBDBD;
  height: 200px;
  padding-left: 8px !important;
  padding-top: 4px !important;
  color: white; }

.demo-grid-align > .mdl-cell {
  height: 200px; }

.demo-grid-4 {
  height: 200px; }
  .demo-grid-4 .mdl-cell {
    height: 50px; }

.demo-grid-ruler.demo-grid-ruler .mdl-cell {
  height: 50px; }

.icons .icon-holder {
  line-height: 44px;
  height: 44px;
  padding: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  -webkit-transition: all 0.1s;
  -o-transition: all 0.1s;
  transition: all 0.1s;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1); }
  .icons .icon-holder:hover {
    background: rgba(0, 0, 0, 0.05);
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
    transform: scale(1.05); }

.icons .icon-set i {
  font-size: 38px;
  color: #3A3A3A;
  line-height: 38px;
  margin-right: 5px;
  width: 50px;
  height: 45px; }

.typo-styles dt {
  display: block;
  float: left;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.24);
  width: 32px;
  height: 32px;
  border-radius: 16px;
  line-height: 32px;
  text-align: center;
  font-weight: 500;
  margin-top: 5px; }

.typo-styles dd {
  display: block;
  margin-left: 60px;
  margin-bottom: 20px; }

.typo-styles .typo-styles__demo {
  margin-bottom: 8px; }

.gallery {
  /** search and sort section css end ***/
  /*** description section css ***/ }
  .gallery .dynamic-grid {
    position: relative;
    display: none; }
  .gallery .dynamic-grid.angular-grid {
    display: block; }
  .gallery .grid {
    position: absolute;
    list-style: none;
    background: #ffffff;
    box-sizing: border-box;
    -webkit-transition: all 400ms ease;
    -moz-transition: all 400ms ease;
    -o-transition: all 400ms ease;
    transition: all 400ms ease; }
  .gallery .grid.ng-leave {
    -webkit-transition: all ease 400ms;
    -moz-transition: all ease 400ms;
    -o-transition: all ease 400ms;
    transition: all ease 400ms; }
  .gallery .grid.ng-leave.ng-leave-active {
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0; }
  .gallery .grid.ng-enter {
    -webkit-transition: all ease 400ms;
    -moz-transition: all ease 400ms;
    -o-transition: all ease 400ms;
    transition: all ease 400ms;
    -webkit-transition-delay: 500ms;
    -moz-transition-delay: 500ms;
    -o-transition-delay: 500ms;
    transition-delay: 500ms;
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0; }
  .gallery .grid.ng-enter.ng-enter-active {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: 1; }
  .gallery .grid-img {
    width: 100%;
    vertical-align: middle;
    background-color: #fff;
    opacity: 0;
    visibility: hidden; }
  .gallery .grid-img.img-loaded {
    visibility: visible;
    opacity: 1; }
  .gallery .img-desc-wrap {
    padding: 10px; }
  .gallery .img-desc-wrap .title {
    font-size: 18px;
    line-height: 22px; }
  .gallery .img-desc-wrap .uploaded-date {
    margin-top: 5px;
    font-size: 14px;
    color: #999; }
  .gallery .img-desc-wrap .likes-watch-wrap {
    margin-top: 10px; }
  .gallery .img-desc-wrap .likes,
  .gallery .img-desc-wrap .watch {
    display: inline-block;
    margin-right: 20px; }

.angular-google-map, .angular-google-map-container {
  height: 100%;
  width: 100%;
  flex: 1; }

.navigations-container > .mdl-layout__container {
  position: relative !important; }

.navigations-container .mdl-layout-title {
  padding-left: 30px; }

.drop-box {
  background-color: #F1F1F1;
  text-align: center;
  height: 100px;
  line-height: 100px;
  display: block;
  border: 1px dashed #DDD; }
  .drop-box:hover {
    background-color: #F9F9F9; }

.cards-top {
  margin-top: -120px;
  padding-bottom: 0px; }

#chart-area-1 .c3-line-Customers {
  stroke: transparent !important; }

#chart-area-1 .c3-circle {
  display: none !important; }

#chart-line-5 .tick line {
  display: none;
  fill: gray; }

#chart-line-5 .domain {
  display: none; }

.c3-tooltip-container {
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.3); }

.c3-tooltip {
  opacity: 1; }
  .c3-tooltip .name span {
    display: none; }
  .c3-tooltip td {
    border: none; }
    .c3-tooltip td.value {
      background-color: #EAEAEA; }
  .c3-tooltip tr {
    border: none; }
    .c3-tooltip tr:first-child {
      display: none; }

@-webkit-keyframes toTop {
  100% {
    opacity: 0; } }

@-moz-keyframes toTop {
  100% {
    opacity: 0; } }

@-ms-keyframes toTop {
  100% {
    opacity: 0; } }

@keyframes toTop {
  100% {
    opacity: 0; } }

.stats-2cols {
  width: 100%;
  border-top: 1px #E4E4E4 solid;
  border-bottom: 1px #E4E4E4 solid;
  padding: 11px 0 15px 0 !important;
  margin-bottom: 10px; }
  .stats-2cols .mdl-cell:first-child {
    border-right: 1px #E4E4E4 solid; }

.stats-inchart {
  background-color: #f3d1dc;
  color: white;
  min-height: inherit; }

.stats-inchart--right-border {
  border-right: 1px #F0F0F0 solid; }

.ml-card-agenda {
  border-left: 20px solid rgba(0, 0, 0, 0.2); }
  .ml-card-agenda .ml-card-agenda__reminders {
    border-bottom: 1px rgba(255, 255, 255, 0.3) solid; }

.img-round-profile {
  border-radius: 50%;
  background: white;
  padding: 2px;
  max-height: 60px; }

.select2-container {
  border-radius: 0;
  padding-left: 0;
  padding: 5px 0px;
  line-height: 1.5;
  cursor: pointer;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-box-shadow: inset 0 -1px 0 #DDD;
  box-shadow: inset 0 -1px 0 #DDD; }

.ui-select-choices-row {
  padding: 10px 15px; }

.select2-container .select2-choice {
  background: none;
  border: none;
  border-radius: 0;
  padding: 0;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false); }

.select2-container.select2-drop-above .select2-choice {
  background: none;
  border: none;
  border-radius: 0;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false); }

.select2-drop {
  border: none;
  z-index: 99999;
  margin-top: -30px;
  -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15); }

.select2-drop.select2-drop-above {
  -webkit-box-shadow: 0 -4px 5px rgba(0, 0, 0, 0.15);
  box-shadow: 0 -4px 5px rgba(0, 0, 0, 0.15); }

.select2-container .select2-choice .select2-arrow {
  background: none;
  border: none;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false); }

.select2-container .select2-choice div {
  background: none;
  border: none;
  border-radius: 0;
  position: static;
  left: auto;
  right: auto;
  height: auto;
  width: 18px;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false); }

.select2-search input {
  -webkit-box-shadow: none;
  box-shadow: none; }

.select2-container-active .select2-choice,
.select2-container-active .select2-choices {
  -webkit-box-shadow: none;
  box-shadow: none; }

.select2-dropdown-open .select2-choice {
  background: none;
  border: none;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false); }

.select2-results {
  margin: 0;
  padding: 0;
  max-height: 250px; }

.select2-results .select2-result-label {
  padding: 0; }

.select2-results .select2-highlighted {
  background: #2196F3; }

.select2-container-multi .select2-choices {
  background: none;
  border: none; }

.select2-container-multi.select2-container-active .select2-choices {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none; }

.select2-container-multi .select2-choices .select2-search-choice {
  background-color: #F9F9F9;
  background-image: none;
  border: none;
  color: inherit;
  padding: 10px 20px 10px 35px;
  margin: 0 0 5px 5px;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  -webkit-box-shadow: none;
  box-shadow: none; }

.select2-search-choice-close {
  -webkit-transition: none !important;
  transition: none !important;
  background: none;
  color: #2196f3;
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  word-wrap: normal;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased; }

.select2-search-choice-close:after {
  content: "close"; }

.select2-search-choice-close:hover {
  color: #90caf9; }

.select2-display-none {
  display: none; }

/**
 * This theme is an example to show how you can create your own.
 */
.pika-single.material-lite {
  color: #202020;
  background: #fafafa;
  border: none; }

.pika-lendar {
  margin-bottom: 0; }

.material-lite .pika-title {
  background-color: rgb(255,64,129);
  margin-left: -8px;
  margin-right: -8px;
  height: 40px; }

.material-lite .pika-label {
  color: #fff;
  background-color: rgb(255,64,129);
  line-height: 30px; }

.material-lite .pika-prev,
.material-lite .pika-next {
  height: 40px; }

.material-lite .pika-prev {
  margin-left: 8px; }

.material-lite .pika-next {
  margin-right: 8px; }

.material-lite .pika-prev,
.material-lite .is-rtl .pika-next {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAeCAQAAACGG/bgAAAAQ0lEQVR4Ae3KIQ4AIBTD0N0/IeHGI3UIRA3ut/Zl+ltXc5++htVAmIAwAWECwgSEKbgthEoIlRAqIVRCqINQB9nDgQd7ktwFo6UpWQAAAABJRU5ErkJggg=="); }

.material-lite .pika-next,
.material-lite .is-rtl .pika-prev {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAeCAQAAACGG/bgAAAAP0lEQVQ4y+3TMQoAMAgEwfwfAvvjTZ1uGzuvHhBPPGczEG+FRqqRaqQaqUaqkX6QBmmjacvQ6qEVTjsh+xizebvlaWptGXZAAAAAAElFTkSuQmCC"); }

.material-lite .pika-table th {
  color: #999; }

.material-lite .pika-table td, .material-lite .pika-table th {
  padding-bottom: 10px; }

.material-lite .pika-button {
  color: #202020;
  background: #fafafa;
  border-radius: 20px;
  text-align: center;
  width: 30px;
  height: 30px;
  -webkit-box-shadow: none;
  box-shadow: none; }
  .material-lite .pika-button:hover {
    color: #fff !important;
    background: rgb(255,64,129) !important; }

.material-lite .pika-week {
  color: #999; }

.material-lite .is-today .pika-button {
  color: #000;
  font-weight: bold; }

.material-lite .is-selected .pika-button {
  color: #fff;
  background: rgb(255,64,129); }

.material-lite .is-disabled .pika-button {
  color: #999;
  opacity: .3; }
